import {
  Bar,
  BarChart,
  CartesianGrid,
  LabelList,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

const BarChartNew = ({ data, name, colors }) => {
  const renderCustomizedLabel = (props) => {
    const { x, y, width, value } = props;
    return (
      <text
        key={x + y}
        x={x + width / 2}
        y={y + 15}
        fill='#fff'
        textAnchor='middle'
        dominantBaseline='middle'
        fontSize={10}
      >
        {value ? value : 0}%
      </text>
    );
  };
  return (
    <div className='bar__chart'>
      <div className='bar__chart__top' style={{ backgroundColor: colors[0] }}>
        <h1>{name}</h1>
      </div>
      <div>
        <BarChart
          width={300}
          height={230}
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: -15,
            bottom: 5,
          }}
          barGap={5500}
          barSize={30}
        >
          <Tooltip />

          <CartesianGrid strokeDasharray='3 3' />
          <XAxis dataKey='name' height={20} fontSize={10} />
          <YAxis
            type='number'
            domain={[0, 100]}
            ticks={[0, 20, 40, 60, 80, 100]}
          />
          {/* <Tooltip /> */}
          <Bar
            dataKey='Baseline'
            fill={colors[1]}
            minPointSize={10}
            radius={[50, 50, 0, 0]}
          >
            radius={[50, 50, 0, 0]}
            <LabelList dataKey={'Baseline'} content={renderCustomizedLabel} />
          </Bar>
          <Bar
            dataKey='End_line'
            fill={colors[2]}
            minPointSize={5}
            width={20}
            radius={[50, 50, 0, 0]}
          >
            <LabelList dataKey='End_line' content={renderCustomizedLabel} />
          </Bar>
        </BarChart>
      </div>
    </div>
  );
};

export default BarChartNew;
