/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  LabelList,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import {
  getDistList,
  getReadinessAspiringData,
  getReadinessAspiringSubjectScores,
} from '../services/api';
import Kaizaley from './../assets/Kaizaley.pdf';
import aspring_img1 from './../assets/assetNew1.png';
import aspring_img2 from './../assets/assetNew2.png';
import pdf from './../assets/pdf.jpg';
import video from './../assets/video.jpg';
import LoaderSpinner from './LoaderSpinner';
import NotFoundDialog from './NotFoundDialog';
import SchoolModal from './SchoolModal';
import DropdownButton from './Signout';
import { exportPDF } from './pdfDownloader';
import './styles/AspiringSchools.css';

const barChartColors = [
  ['#c0e3fb', '#0390f2', '#004677'],
  ['#fcdcdc', '#f47979', '#750b0b'],
  ['#ffddaf', '#ffa834', '#f38d00'],
];

const headers = [
  'SL',
  'UDISE',
  'Name of School',
  'District',
  'Aspiring student %',
];

const AspiringSchools = () => {
  const [state, setState] = useState(localStorage.getItem('state'));
  const [district, setDistrict] = useState('');
  const [distList, setDistList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [readinessAspiringSubjectScores, setReadinessAspiringSubjectScores] =
    useState([]);
  const [allAspiringSchools, setAllAspiringSchools] = useState([]);
  const [topEnglishSchools, setTopEnglishSchools] = useState([]);
  const [topMathSchools, setTopMathSchools] = useState([]);
  const [topScienceSchools, setTopScienceSchools] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [activeSchool, setActiveSchool] = useState('');

  const openModal = (data) => {
    setActiveSchool(data);
    setShowModal(true);
  };

  const closeModal = () => {
    setActiveSchool('');
    setShowModal(false);
  };
  const handleOnSelectedDistrict = (e) => {
    setDistrict(e.target.value);
    setTopEnglishSchools([]);
    setTopMathSchools([]);
    setTopScienceSchools([]);
    setReadinessAspiringSubjectScores([]);
    setAllAspiringSchools([]);
  };

  const getTopTenAspiringSchoolsEnglish = (data) => {
    const subject = 'English';
    const res = data
      // Filter out entries where the subject data is undefined
      .filter((x) => x.baseline.some((obj) => subject in obj))
      .sort((x, y) => {
        const subjectData1 = x.baseline.find((obj) => subject in obj);
        const subjectData2 = y.baseline.find((obj) => subject in obj);

        let a =
          (Number(subjectData1[subject].aspiring) /
            (Number(subjectData1[subject].aspiring) +
              Number(subjectData1[subject].master) +
              Number(subjectData1[subject].scholar))) *
          100;
        let b =
          (Number(subjectData2[subject].aspiring) /
            (Number(subjectData2[subject].aspiring) +
              Number(subjectData2[subject].master) +
              Number(subjectData2[subject].scholar))) *
          100;

        return b - a;
      });

    const arr = res.slice(0, 10);
    const newData = arr.map((a) => a.baseline.find((obj) => subject in obj));
    setTopEnglishSchools([...arr, { subjectData: newData }]);
  };

  const getTopTenAspiringSchoolsScience = (data) => {
    const subject = 'Science';
    const res = data
      // Filter out entries where the subject data is undefined
      .filter((x) => x.baseline.some((obj) => subject in obj))
      .sort((x, y) => {
        const subjectData1 = x.baseline.find((obj) => subject in obj);
        const subjectData2 = y.baseline.find((obj) => subject in obj);

        let a =
          (Number(subjectData1[subject].aspiring) /
            (Number(subjectData1[subject].aspiring) +
              Number(subjectData1[subject].master) +
              Number(subjectData1[subject].scholar))) *
          100;
        let b =
          (Number(subjectData2[subject].aspiring) /
            (Number(subjectData2[subject].aspiring) +
              Number(subjectData2[subject].master) +
              Number(subjectData2[subject].scholar))) *
          100;

        return b - a;
      });

    const arr = res.slice(0, 10);
    const newData = arr.map((a) => a.baseline.find((obj) => subject in obj));

    setTopScienceSchools([...arr, { subjectData: newData }]);
  };
  const getTopTenAspiringSchoolsAllSubject = (data) => {
    const subject = 'allSubject';
    const res = data
      // Filter out entries where the subject data is undefined
      .filter((x) => x.baseline.some((obj) => subject in obj))
      .sort((x, y) => {
        const subjectData1 = x.baseline.find((obj) => subject in obj);
        const subjectData2 = y.baseline.find((obj) => subject in obj);

        let a =
          (Number(subjectData1[subject].aspiring) /
            (Number(subjectData1[subject].aspiring) +
              Number(subjectData1[subject].master) +
              Number(subjectData1[subject].scholar))) *
          100;
        let b =
          (Number(subjectData2[subject].aspiring) /
            (Number(subjectData2[subject].aspiring) +
              Number(subjectData2[subject].master) +
              Number(subjectData2[subject].scholar))) *
          100;

        return b - a;
      });

    const arr = res.slice(0, 10);
    const newData = arr.map((a) => a.baseline.find((obj) => subject in obj));
    setAllAspiringSchools([...arr, { subjectData: newData }]);
  };

  const getTopTenAspiringSchoolsMath = (data) => {
    const subject = 'Maths';
    const res = data
      // Filter out entries where the subject data is undefined
      .filter((x) => x.baseline.some((obj) => subject in obj))
      .sort((x, y) => {
        const subjectData1 = x.baseline.find((obj) => subject in obj);
        const subjectData2 = y.baseline.find((obj) => subject in obj);

        let a =
          (Number(subjectData1[subject].aspiring) /
            (Number(subjectData1[subject].aspiring) +
              Number(subjectData1[subject].master) +
              Number(subjectData1[subject].scholar))) *
          100;
        let b =
          (Number(subjectData2[subject].aspiring) /
            (Number(subjectData2[subject].aspiring) +
              Number(subjectData2[subject].master) +
              Number(subjectData2[subject].scholar))) *
          100;

        return b - a;
      });

    const arr = res.slice(0, 10);
    const newData = arr.map((a) => a.baseline.find((obj) => subject in obj));

    setTopMathSchools([...arr, { subjectData: newData }]);
  };

  useEffect(() => {
    getDistList(state).then((res) => {
      setDistList(res.data.data);
    });
  }, [state]);

  useEffect(() => {
    const u_code = localStorage.getItem('m_auth_token');
    const payload = {
      state: `${state}`,
      district: district,
    };
    const config = {
      headers: {
        Authorization: `${u_code}`,
      },
    };
    setIsLoading(true);
    getReadinessAspiringSubjectScores(payload, config).then((res) => {
      setReadinessAspiringSubjectScores(res.data.data ? res.data.data : []);
      setIsLoading(false);
    });
    getReadinessAspiringData(payload, config).then((res) => {
      if (res.data.data.length > 0) {
        getTopTenAspiringSchoolsEnglish(res.data.data);
        getTopTenAspiringSchoolsMath(res.data.data);
        getTopTenAspiringSchoolsScience(res.data.data);
        getTopTenAspiringSchoolsAllSubject(res.data.data);
        setIsLoading(false);
      } else {
        setAllAspiringSchools([]);
      }
      setIsLoading(false);
    });
  }, [district, state]);

  return (
    <div className='container' id='aspiringSchool'>
      <div className='readinessCon'>
        <div className='readHeadingCon'>
          <h2>Readiness - Aspiring Schools</h2>
          <div className='readRight'>
            <DropdownButton />
          </div>
        </div>

        <div className='readMain'>
          <p>
            <strong> Aspiring schools</strong> are identified primarily for
            special intervention. These schools have greater proportions of
            students in Aspiring level
            <strong> (score 0-40%)</strong>, hence need help to push students up
            to higher levels. An analytical representation of the data collected
            during <strong>Baseline and End line assessments</strong> identify
            Aspiring schools.
          </p>

          <p>
            Identified Aspiring schools may be considered for special
            interventions in terms of teachers' development, specialised
            resources and supplementary learning support.
          </p>
        </div>

        <div>
          <div className='row'>
            <div className='readSearch'>
              <div id='custom-search-input'>
                <select
                  className='dropdown dropdownCus'
                  onChange={handleOnSelectedDistrict}
                  value={district}
                >
                  <option
                    className='dropdown-toggle'
                    value=''
                    role='button'
                    data-bs-toggle='dropdown'
                    aria-expanded='false'
                    key={0}
                  >
                    All Districts
                  </option>
                  {distList.length > 0
                    ? distList.map((item, index) => {
                        return (
                          <option
                            className='dropdown-item'
                            value={item.district}
                            key={index + 1}
                          >
                            {item.district}
                          </option>
                        );
                      })
                    : ''}
                </select>
              </div>
            </div>
            <button
              className='dropdownCus'
              style={{ width: '250px', height: '42px', margin: '10px' }}
              onClick={() =>
                exportPDF('aspiringSchool', 'Aspiring School Analysis')
              }
              id='downloadButton'
            >
              Download Report
            </button>
          </div>
        </div>
      </div>
      <hr />
      {!isLoading ? (
        <>
          <div className='endlineComparison'>
            <h2>Baseline - Endline Comparison</h2>
            <div className='endlineComparison'>
              <p>
                This graph shows the % age and number of schools who has got{' '}
                {'>'}
                75%, 50-75% and {'<50%'} students in Aspiring level. Schools
                that have {'>'}75% students in Aspiring level needs intensive
                intervention.
              </p>
              <div className='chartCon'>
                {readinessAspiringSubjectScores.length > 0
                  ? readinessAspiringSubjectScores.map((item, index) => {
                      return (
                        <BarChartNew
                          data={processBarChartData(item)}
                          name={item.subject}
                          colors={barChartColors[index]}
                          key={`${Math.round(Math.random() * 1000000)}`}
                        />
                      );
                    })
                  : ''}
              </div>
              <div className='chartCon'>
                <div className='img__container'>
                  <img src={aspring_img1} alt='' />
                </div>
                {readinessAspiringSubjectScores.length > 0 ? (
                  <BarChartNew
                    data={processBarAllSubjectsChartData(
                      readinessAspiringSubjectScores
                    )}
                    name='All Subject'
                    colors={['#d4ead8', '#27963c', '#062d0d']}
                    key={`${Math.round(Math.random() * 1000000)}`}
                  />
                ) : (
                  ''
                )}
                <div className='img__container'>
                  <img src={aspring_img2} alt='' />
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div className='improvment'>
            <h2>List of Schools - Needs improvement (End Line Results)</h2>
            <div className='improvmentCon'>
              <p>
                Click on the school name to see details performance of the
                school
              </p>
            </div>
            <div className='perfonceCon'>
              <h2>English</h2>
              <br />
              <div className='tableFixHead'>
                {topEnglishSchools.length > 0 ? (
                  <DynamicTable
                    openModal={openModal}
                    headers={headers}
                    data={topEnglishSchools}
                    color={'#c0e3fb'}
                    subject={'English'}
                    number={0}
                  />
                ) : (
                  ''
                )}
              </div>
              <br />
            </div>
            <div className='perfonceCon'>
              <h2>Maths</h2>
              <br />
              <div className='tableFixHead'>
                {topMathSchools.length > 0 ? (
                  <DynamicTable
                    openModal={openModal}
                    headers={headers}
                    data={topMathSchools}
                    color={'#fcdcdc'}
                    subject={'Maths'}
                    number={1}
                  />
                ) : (
                  ''
                )}
              </div>
            </div>
            <br />
            <div className='perfonceCon'>
              <h2>Science</h2>
              <br />
              <div className='tableFixHead'>
                {topScienceSchools.length > 0 ? (
                  <DynamicTable
                    openModal={openModal}
                    headers={headers}
                    data={topScienceSchools}
                    color={'#ffddaf'}
                    subject={'Science'}
                    number={2}
                  />
                ) : (
                  ''
                )}
              </div>
            </div>
            <div className='perfonceCon'>
              <br />
              <h2>All Subject</h2>
              <br />
              <div className='tableFixHead'>
                {allAspiringSchools.length > 0 ? (
                  <DynamicTable
                    openModal={openModal}
                    headers={headers}
                    data={allAspiringSchools}
                    color={'#d4ead8'}
                    subject={'allSubject'}
                    number={3}
                  />
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>

          <div className='videoPdfCon'>
            <h2>Video & PDF Documents</h2>
            <div className='docPdfWrap'>
              <div className='bottomDivs'>
                <div className=''>
                  <img className='imageAsp' src={video} alt='' />
                </div>
                <h5>Readiness sprint</h5>
                <p className='description-text'>
                  Watch a small video on how students and teachers and engaging
                  in Readiness Sprint with interest. Pachak Secondary School
                  Pakyong Class - 6 Science
                </p>
                <a
                  target='_blank'
                  href='https://youtu.be/k0-8Emon6vs'
                  rel='noreferrer'
                >
                  Watch on Youtube
                </a>
              </div>

              <div className='bottomDivs'>
                <div>
                  <img src={pdf} alt='' />
                </div>
                <h5>Readiness Resource Pack</h5>
                <p className='description-text'>
                  This is a sample Readiness Resource Pack. One week of
                  carefully designed Worksheets, Projects and a simple
                  transaction plan is included here. 6 such packs are supplied
                  for all 3 subjects and classes.
                </p>
                <a
                  target='_blank'
                  href='https://drive.google.com/file/d/1fuyn67Jz_O4dY8XVMvoL4DYCRSnGiykC/view?usp=share_link'
                  rel='noreferrer'
                >
                  Download PDF
                </a>
              </div>

              <div className='bottomDivs'>
                <div>
                  <img src={video} alt='' />
                </div>
                <h5>Trash to Treasure</h5>
                <p className='description-text'>
                  Class 7 students exploring how kitchen scrap can grow into
                  edible microgreens or even vegetables with a little care – all
                  as part of their Readiness Sprint. Ralak JHS Mangan
                </p>
                <a
                  target='_blank'
                  href='https://youtu.be/NVbDvOXvBjw'
                  rel='noreferrer'
                >
                  Watch on Youtube
                </a>
              </div>

              <div className='bottomDivs'>
                <div>
                  <img src={pdf} alt='' />
                </div>
                <h5>From the HM’s Desk</h5>
                <p className='description-text'>
                  A Headmaster’s account of how Readiness Sprint has helped
                  students overcoming their fear of studies. Kaizaley Junior
                  High School Namthang, Namchi
                </p>
                <a target='_blank' href={Kaizaley} rel='noreferrer'>
                  Download PDF
                </a>
              </div>
            </div>
          </div>
          <br />
          {showModal && (
            <div style={{ zIndex: 1000000 }}>
              <SchoolModal closeModal={closeModal} data={activeSchool} />
            </div>
          )}
        </>
      ) : isLoading ? (
        <LoaderSpinner />
      ) : (
        <NotFoundDialog />
      )}
    </div>
  );
};

export default AspiringSchools;

function DynamicTable({ headers, data, color, openModal, subject, number }) {
  return (
    <>
      <table
        className='aspring__table'
        style={{
          border: '0px solid #ccc',
          width: '100%',
          borderCollapse: 'collapse',
        }}
      >
        <thead>
          <tr>
            {headers.map((header, index) => (
              <th
                key={index}
                className='table__heading'
                style={{ backgroundColor: color, color: 'black' }}
              >
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.slice(0, 10).map((row, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{row.udiseId}</td>
              <td>
                <button onClick={() => openModal(row)}>{row.schoolName}</button>
              </td>
              <td>{row.district}</td>
              <td>
                {(
                  (Number(data[10].subjectData[index][subject].aspiring) /
                    (Number(data[10].subjectData[index][subject].aspiring) +
                      Number(data[10].subjectData[index][subject].master) +
                      Number(data[10].subjectData[index][subject].scholar))) *
                  100
                ).toFixed(2)}
                %
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
}

const processBarAllSubjectsChartData = (data) => {
  const baselineTotal_gt_75 =
    data[0].baseline.gt_75 + data[1].baseline.gt_75 + data[2].baseline.gt_75;
  const baselineTotal_bet_50_75 =
    data[0].baseline.bet_50_75 +
    data[1].baseline.bet_50_75 +
    data[2].baseline.bet_50_75;
  const baselineTotal_lt_50 =
    data[0].baseline.lt_50 + data[1].baseline.lt_50 + data[2].baseline.lt_50;

  const baselineTotal =
    baselineTotal_gt_75 + baselineTotal_bet_50_75 + baselineTotal_lt_50;

  const endlineTotal_gt_75 =
    data[0].endline.gt_75 + data[1].endline.gt_75 + data[2].endline.gt_75;
  const endlineTotal_bet_50_75 =
    data[0].endline.bet_50_75 +
    data[1].endline.bet_50_75 +
    data[2].endline.bet_50_75;
  const endlineTotal_lt_50 =
    data[0].endline.lt_50 + data[1].endline.lt_50 + data[2].endline.lt_50;

  const endlineTotal =
    endlineTotal_gt_75 + endlineTotal_bet_50_75 + endlineTotal_lt_50;

  const baselineAll = {
    gt_75: baselineTotal_gt_75,
    bet_50_75: baselineTotal_bet_50_75,
    lt_50: baselineTotal_lt_50,
  };
  const endlineAll = {
    gt_75: endlineTotal_gt_75,
    bet_50_75: endlineTotal_bet_50_75,
    lt_50: endlineTotal_lt_50,
  };

  const finalData = {
    baseline: baselineAll,
    endline: endlineAll,
  };

  const result = Object.keys(finalData.baseline).reduce((acc, key) => {
    const range = {
      gt_75: '>75%',
      bet_50_75: '50-75%',
      lt_50: '<50%',
    };

    const baselineValue = finalData.baseline[key];
    const endlineValue = finalData.endline[key];

    const obj = {
      name: range[key],
      Baseline: Math.floor((baselineValue / baselineTotal) * 100),
      End_line: endlineTotal
        ? Math.floor((endlineValue / endlineTotal) * 100)
        : 0,
    };

    acc.push(obj);
    return acc;
  }, []);
  return result;
};

const processBarChartData = (data) => {
  const baselineTotal =
    data.baseline.gt_75 + data.baseline.bet_50_75 + data.baseline.lt_50;
  const endlineTotal =
    data.endline.gt_75 + data.endline.bet_50_75 + data.endline.lt_50;

  const result = Object.keys(data.baseline).reduce((acc, key) => {
    const range = {
      gt_75: '>75%',
      bet_50_75: '50-75%',
      lt_50: '<50%',
    };

    const baselineValue = data.baseline[key];
    const endlineValue = data.endline[key];

    const obj = {
      name: range[key],
      Baseline: Math.floor((baselineValue / baselineTotal) * 100),
      End_line: endlineTotal
        ? Math.floor((endlineValue / endlineTotal) * 100)
        : 0,
    };

    acc.push(obj);
    return acc;
  }, []);
  return result;
};

const BarChartNew = ({ data, name, colors }) => {
  const renderCustomizedLabel = (props) => {
    const { x, y, width, value } = props;
    return (
      <text
        x={x + width / 2}
        y={y + 15}
        fill='#fff'
        textAnchor='middle'
        dominantBaseline='middle'
        fontSize={10}
      >
        {`${value}%`}
      </text>
    );
  };
  return (
    <div className='bar__chart'>
      <div className='bar__chart__top' style={{ backgroundColor: colors[0] }}>
        <h1>{name}</h1>
      </div>
      <div>
        <BarChart
          width={300}
          height={230}
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: -15,
            bottom: 5,
          }}
          barGap={5500}
          barSize={30}
        >
          <Tooltip />

          <CartesianGrid strokeDasharray='3 3' />
          <XAxis dataKey='name' height={20} fontSize={10} />
          <YAxis
            type='number'
            domain={[0, 100]}
            ticks={[0, 20, 40, 60, 80, 100]}
          />
          {/* <Tooltip /> */}
          <Bar
            dataKey='Baseline'
            fill={colors[1]}
            minPointSize={10}
            radius={[50, 50, 0, 0]}
          >
            radius={[50, 50, 0, 0]}
            <LabelList dataKey={'Baseline'} content={renderCustomizedLabel} />
          </Bar>
          <Bar
            dataKey='End_line'
            fill={colors[2]}
            minPointSize={5}
            width={20}
            radius={[50, 50, 0, 0]}
          >
            <LabelList dataKey='End_line' content={renderCustomizedLabel} />
          </Bar>
        </BarChart>
      </div>
    </div>
  );
};
