import axios from 'axios';
import { jsPDF } from 'jspdf';
import * as _ from 'lodash';
import * as moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import { Redirect } from 'react-router';
import calender from './../../assets/calendar.svg';
import noun_list_logo from './../../assets/noun_List_2395940.svg';
import upload_logo from './../../assets/upload.svg';
import { api_url } from './../../resources/url';
import StudentHeader from './../StudentHeader';

export const getGreadValue = (arr, key) => {
  return _.filter(arr, (a) => {
    return a.grade.toLowerCase() === key.toLowerCase();
  })[0]?.value;
};

export const DataPrepear = ({ title, data }) => (
  <tr>
    <td>
      <span style={{ textTransform: 'capitalize', marginLeft: 20 }}>
        {title}
      </span>
    </td>
    <td style={{ fontWeight: 'bold', textAlign: 'center' }}>
      {getGreadValue(data, 'ASPIRING') ?? '-'}
    </td>
    <td style={{ fontWeight: 'bold', textAlign: 'center' }}>
      {getGreadValue(data, 'SCHOLAR') ?? '-'}
    </td>
    <td style={{ fontWeight: 'bold', textAlign: 'center' }}>
      {getGreadValue(data, 'MASTER') ?? '-'}
    </td>
  </tr>
);

function MonitoringClass() {
  const [byclass, setByClass] = useState([]);
  const [classid, setClassid] = useState('');
  const [startDate, setStartDate] = useState(new Date());
  const calenderInput = useRef(null);
  const handleCalenderClick = (e) => {
    const datepickerElement = calenderInput.current;
    datepickerElement.setFocus(true);
  };

  useEffect(() => {
    let udise_id = localStorage.getItem('udise_id');
    let Params = `/user/school/performance?schoolId=` + udise_id;

    let cls = classid;
    if (!cls) {
      cls = '5';
    }
    if (cls) {
      Params = Params + `${'&class=' + cls}`;
    }
    if (startDate) {
      Params = Params + `${'&date=' + moment(startDate).format('DD-MM-YYYY')}`;
    }

    axios.get(api_url + Params).then(
      (res) => {
        if (res) {
          setByClass(res.data.data);
        }
      },
      (error) => {}
    );
  }, [classid, startDate]);

  const pdfDownload = async (e) => {
    e.preventDefault();
    // setIsPDF(true);

    console.log(document);
    let subheader = document.getElementById('divsh');
    let footer = document.getElementById('divft');
    let date = document.getElementById('test-date');
    subheader.style.display = 'none';
    footer.style.display = 'none';

    date.innerHTML =
      '<span>Date of Report - ' +
      moment(startDate).format('DD-MM-YYYY') +
      ' </span>';
    let doc = new jsPDF('landscape', 'pt', 'A4');

    let main = document.getElementById('tblStudPer');
    let hdrfFrm = document.getElementById('hdrfFrm');

    var div = document.createElement('div');
    div.setAttribute('id', 'newDivId');
    // div.setAttribute("class", "newDiv")
    div.style.width = '800px';

    div.appendChild(hdrfFrm);
    div.appendChild(main);

    doc.html(div, {
      callback: () => {
        doc.save('performance-class.pdf');
        window.location.reload(false);
      },
    });
  };

  const HeaderForm = () => {
    return (
      <div
        id='hdrfFrm'
        className='row right-menu'
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div className='col md-5'>
          <h5 style={{ marginLeft: 5 }}>
            {localStorage.getItem('school_name')}
          </h5>
        </div>
        <div
          className='col md-3 rightborderdiv vl2'
          style={{ fontWeight: 'bold' }}
        >
          <select
            className='custom-select custom-select-lg mb-3'
            style={{
              width: '100%',
              padding: 8,
              borderRadius: 5,
              marginTop: 15,
            }}
            onChange={(e) => setClassid(e.target.value)}
            value={classid}
          >
            <option value='5'>Class 5</option>
            <option value='6'>Class 6</option>
            <option value='7'>Class 7</option>
            <option value='8'>Class 8</option>
            <option value='9'>Class 9</option>
            <option value='10'>Class 10</option>
          </select>

          <br />
          <span style={{ fontSize: 13 }}>
            No. of Students - {byclass.no_student}
          </span>
          <br />
          <span style={{ fontSize: 13, display: 'flex' }} id='test-date'>
            <span style={{ width: 130 }}>Date of Report -</span>
            <span style={{ display: 'flex' }}>
              <DatePicker
                ref={calenderInput}
                dateFormat='dd-MM-yyyy'
                selected={startDate}
                minDate={new Date('01/01/2022')}
                maxDate={new Date()}
                onChange={(date) => setStartDate(date)}
              />
              <img
                onClick={handleCalenderClick}
                src={calender}
                alt='fdcd'
                style={{
                  marginLeft: 10,
                  width: 22,
                  height: 24,
                  cursor: 'pointer',
                }}
              />
            </span>
          </span>
        </div>
        <div className='col md-4 vl' style={{ width: '100%' }}>
          <span
            className='boldspan'
            style={{
              display: 'flex',
              justifyContent: 'left',
              alignItems: 'left',
              paddingLeft: 10,
            }}
          >
            Score Reference Table
          </span>
          <ul>
            <li>
              <span style={{ color: '#f1a253' }}>0-40% Aspiring</span>
            </li>
            <li>
              <span style={{ color: '#ef1b1b' }}>41-80% Scholar</span>
            </li>
            <li>
              <span style={{ color: '#82a1e0' }}>81-100% Master</span>
            </li>
          </ul>
        </div>
      </div>
    );
  };

  const SubHeaderForm = () => {
    return (
      <div
        id='divsh'
        className='row right-menu-nobg'
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div className='col md-6'>
          <span style={{ color: '#499b47' }}>List View</span>{' '}
          <img
            alt='fdf'
            src={noun_list_logo}
            style={{ width: '25px', alignItems: 'right' }}
          />
        </div>
        <div
          className='col md-6'
          style={{
            display: 'flex',
            justifyContent: 'right',
            alignItems: 'right',
          }}
        >
          <img
            alt='fdf'
            src={upload_logo}
            style={{
              width: '22px',
              alignItems: 'right',
              marginRight: 10,
              cursor: 'pointer',
            }}
            onClick={pdfDownload}
          />
          {/* <img src={printer_logo} style={{ width: "22px", alignItems: "right" }} /> */}
        </div>
      </div>
    );
  };
  const FooterForm = () => {
    return (
      <div
        id='divft'
        className='row right-menu-nobg'
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div
          className='col md-12'
          style={{
            display: 'flex',
            justifyContent: 'right',
            alignItems: 'right',
          }}
        >
          <img
            alt='dfv'
            src={upload_logo}
            style={{
              width: '22px',
              alignItems: 'right',
              marginRight: 10,
              cursor: 'pointer',
            }}
            onClick={pdfDownload}
          />
        </div>
      </div>
    );
  };

  if (!localStorage.getItem('is_done')) {
    return <Redirect to={'/login'} />;
  }

  return (
    <div>
      <div>
        <div className='container-fluid p-10'>
          <div className='row' style={{ padding: 20 }}>
            <div style={{ height: '5%' }}>
              <StudentHeader
                level={'Skill Analytics Report - Class Level'}
                desc='This report shows aggregate subject and skill wise performance at
          selected class level.'
              />
              <HeaderForm />
              <SubHeaderForm />

              <div id='tblStudPer'>
                <div
                  className='row'
                  style={{
                    backgroundColor: '#fff',
                    marginLeft: 10,
                    marginRight: 10,
                    marginTop: 10,
                  }}
                >
                  <table style={{ width: '100%', marginBottom: 5 }}>
                    <tr>
                      <th style={{ width: '40%' }}>SCIENCE</th>
                      <th style={{ width: '20%', textAlign: 'center' }}>
                        <span className='aspiring'>ASPIRING</span>
                      </th>
                      <th style={{ width: '20%', textAlign: 'center' }}>
                        <span className='scholar'>SCHOLAR</span>
                      </th>
                      <th style={{ width: '20%', textAlign: 'center' }}>
                        <span className='master'>MASTER</span>
                      </th>
                    </tr>
                    {byclass &&
                      _.map(byclass.science?.order, (key) => (
                        <>
                          <DataPrepear
                            title={key}
                            data={byclass.science[key]}
                          />
                        </>
                      ))}
                  </table>
                </div>

                <div
                  className='row'
                  style={{
                    backgroundColor: '#fff',
                    marginLeft: 10,
                    marginRight: 10,
                    marginTop: 10,
                  }}
                >
                  <table style={{ width: '100%', marginBottom: 5 }}>
                    <tr>
                      <th style={{ width: '40%' }}>MATHS</th>
                      <th style={{ width: '20%', textAlign: 'center' }}>
                        <span className='aspiring'>ASPIRING</span>
                      </th>
                      <th style={{ width: '20%', textAlign: 'center' }}>
                        <span className='scholar'>SCHOLAR</span>
                      </th>
                      <th style={{ width: '20%', textAlign: 'center' }}>
                        <span className='master'>MASTER</span>
                      </th>
                    </tr>
                    {byclass &&
                      _.map(byclass.maths?.order, (key) => (
                        <>
                          <DataPrepear title={key} data={byclass.maths[key]} />
                        </>
                      ))}
                  </table>
                </div>

                <div
                  className='row'
                  style={{
                    backgroundColor: '#fff',
                    marginLeft: 10,
                    marginRight: 10,
                    marginTop: 10,
                  }}
                >
                  <table style={{ width: '100%', marginBottom: 5 }}>
                    <tr>
                      <th style={{ width: '40%' }}>ENGLISH</th>
                      <th style={{ width: '20%', textAlign: 'center' }}>
                        <span className='aspiring'>ASPIRING</span>
                      </th>
                      <th style={{ width: '20%', textAlign: 'center' }}>
                        <span className='scholar'>SCHOLAR</span>
                      </th>
                      <th style={{ width: '20%', textAlign: 'center' }}>
                        <span className='master'>MASTER</span>
                      </th>
                    </tr>
                    {byclass &&
                      _.map(byclass.english?.order, (key) => (
                        <>
                          <DataPrepear
                            title={key}
                            data={byclass.english[key]}
                          />
                        </>
                      ))}
                  </table>
                </div>
              </div>

              <FooterForm />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MonitoringClass;
