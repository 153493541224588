import React from 'react';
import { exportPDF } from '../pdfDownloader';

const FilterAndDownload = ({
  handleOnSelectedDistrict,
  district,
  distList,
}) => {
  return (
    <div>
      <div className='row'>
        <div className='readSearch'>
          <div id='custom-search-input'>
            <select
              className='dropdown dropdownCus'
              onChange={handleOnSelectedDistrict}
              value={district}
            >
              <option
                className='dropdown-toggle'
                value=''
                role='button'
                data-bs-toggle='dropdown'
                aria-expanded='false'
                key={0}
              >
                All Districts
              </option>
              {distList?.map((item, index) => {
                return (
                  <option
                    className='dropdown-item'
                    value={item.district}
                    key={index + 1}
                  >
                    {item.district}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
        <div className='readSearch'>
          <div id='custom-search-input'>
            <select className='dropdown dropdownCus'>
              <option
                className='dropdown-toggle'
                value=''
                role='button'
                data-bs-toggle='dropdown'
                aria-expanded='false'
              >
                Class 10
              </option>
            </select>
          </div>
        </div>
        <button
          className='dropdownCus'
          style={{ width: 'auto', height: '42px', margin: '10px' }}
          onClick={() => exportPDF('main', 'Class 10 reports')}
          id='downloadButton'
        >
          Download Report
        </button>
      </div>
    </div>
  );
};

export default FilterAndDownload;
