import React from 'react';
import { Route, Switch } from 'react-router-dom';
import AspiringSchools from './AspiringSchools';
import ClassroomObservation from './ClassroomObservation';
import DistrictPerformanceNew from './DistrictPerformanceNew';
import PerformanceAnalysis from './PerformanceAnalysis';
import Sidebar from './Sidebar';
import StateDashboardMain from './StateDashboardMain';
import StateHomePage from './StateHomePage';
import TrainingDevelopment from './TrainingDevelopment';
import BoardExamPrep from './boardExamPrep';
import './styles/StateDashboardNew.css';

const StateDashboardNew = () => {
  return (
    <div className='container-fluid'>
      <div className='row'>
        <div className='col-md-3' style={{ height: '100vh' }}>
          <Sidebar />
        </div>
        <div
          className='col-md-9 main-container'
          style={{ height: '100vh', backgroundColor: '#FFFFFF' }}
        >
          <Switch>
            <Route
              exact
              path={`/statedashboardnew/exam`}
              component={BoardExamPrep}
            />
            <Route
              exact
              path={`/statedashboardnew/ClassroomObservation`}
              component={ClassroomObservation}
            />
            <Route
              exact
              path={`/statedashboardnew/TrainingDevelopment`}
              component={TrainingDevelopment}
            />
            <Route
              exact
              path={`/statedashboardnew/`}
              component={StateHomePage}
            />
            <Route
              exact
              path={`/statedashboardnew/Readiness`}
              component={PerformanceAnalysis}
            />
            <Route
              exact
              path={`/statedashboardnew/Readiness/PerformanceAnalysis`}
              component={PerformanceAnalysis}
            />
            <Route
              exact
              path={`/statedashboardnew/Readiness/AspiringSchools`}
              component={AspiringSchools}
            />
            <Route
              exact
              path={`/statedashboardnew/:state/`}
              component={StateDashboardMain}
            />
            <Route
              exact
              path={`/statedashboardnew/:state/:dist`}
              component={DistrictPerformanceNew}
            />
          </Switch>
        </div>
      </div>
    </div>
  );
};

export default StateDashboardNew;
