import React from 'react';
import { FaPowerOff } from 'react-icons/fa';
import { Redirect } from 'react-router';
import './styles/HomePage.css';

function HomDshb() {
  const user = localStorage;
  const token = localStorage.getItem('m_auth_token');
  const udise_id = localStorage.getItem('user_id');

  const query = `?udise=${udise_id}&auth_token=${token}`;

  if (localStorage.getItem('is_done')) {
    return (
      <>
        <div className='bgimage'>
          <div className='container-fluid'>
            <div className='row'>
              <div className='col-md-12 text-right '>
                <button onClick={logout} className='off '>
                  {' '}
                  <FaPowerOff className='ms-1' icon='camera' size='14' />
                </button>
              </div>
              <div className='col-md-12 texts'>
                <div className='home__school__name'>
                  <h1 className='text-center'>
                    <b>Welcome to </b>
                  </h1>
                  <h1 className='schoolName text-center schoolName1'>
                    {user.school_name}
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='container-fluid imgfooter'>
          <div
            style={{
              display: 'flex',
              height: '80px',
              justifyContent: 'space-around',
              alignItems: 'center',
              gap: '10px',
            }}
          >
            <div>
              <h6>
                <b>
                  <span>{user.school_name} </span>
                </b>
              </h6>
            </div>
            <div>
              <h6>
                <b>UDISE -</b>
                <span>{user.user_id}</span>
              </h6>
            </div>
            <div>
              <h6>
                <b>State -</b>
                <span>{user.state} </span>
              </h6>{' '}
            </div>
            <div>
              <h6>
                <b>District -</b>
                <span>{user.district}</span>
              </h6>{' '}
            </div>
          </div>
        </div>

        <section>
          <div className='justify-content-center'>
            <div className='container pt-5'>
              <h1 className='responsive-font-example'>
                This School Dashboard brings all the necessary links in one
                place. Make sure that all data is updated on 'School Profile'
              </h1>
              <h1 className='responsive-font-example'>
                and all students are using Neeve app. Contact your School
                Manager or write to support@neeve.io in case you need help.
              </h1>
            </div>
          </div>

          <div className='justify-content-center'>
            <div className='flex-container'>
              <div className=' color hello shadow p-2 mb-5 rounded'>
                <div className='home__decription'>
                  <h3 className='text-center pt-3'>Neeve Learning</h3>
                  <p>
                    Explore this section for world class Teaching & Learning
                    Resources
                  </p>
                </div>
                <div className='d-grid gap-2 leraning '>
                  <a
                    href='/resource/student-readiness'
                    className='btn btn-outline-primary  rounded-pill shadow  border-dark new greenButton'
                    role='button'
                    data-bs-toggle='button'
                  >
                    <b>Readiness Resources</b>
                  </a>
                  <a
                    href='/resource/lesson-plans'
                    className='btn btn-outline-primary rounded-pill shadow  border-dark new greenButton'
                    role='button'
                    data-bs-toggle='button'
                  >
                    <b>Lesson Plans</b>
                  </a>
                  <a
                    href='/video'
                    className='btn btn-outline-primary rounded-pill shadow  border-dark new greenButton'
                    data-bs-toggle='button'
                  >
                    <b>Videos</b>
                  </a>
                  <a
                    href='/resource/teachers'
                    className='btn btn-outline-primary rounded-pill shadow  border-dark new greenButton'
                    role='button'
                    data-bs-toggle='button'
                  >
                    <b>21st Century Projects</b>
                  </a>
                  <a
                    href='/resource/exam-prep'
                    className='btn btn-outline-primary rounded-pill shadow  border-dark new greenButton'
                    role='button'
                    data-bs-toggle='button'
                  >
                    <b>Board Exam Prep</b>
                  </a>
                  <button
                    onClick={openWorkSheet}
                    className='btn  shadow rounded-pill  btt3 new'
                    data-bs-toggle='button'
                  >
                    <b className='home__card__heading'>Worksheet Warehouse</b>
                  </button>
                </div>
              </div>
              <div className=' color2 hello shadow p-2 mb-5 rounded'>
                <div
                  className='home__decription'
                  style={{ backgroundColor: '#755AFF' }}
                >
                  <h3 className='text-center pt-3'>Neeve Monitoring</h3>
                  <p>
                    Use information from this section to take the best decision
                    on intervention
                  </p>
                </div>
                <div className='d-grid gap-2 leraning'>
                  <a
                    href='/monitoring-school'
                    className='btn btn-outline-success rounded-pill shadow new purpleButton'
                    role='button'
                    data-bs-toggle='button'
                  >
                    <b>CCE (App) Performance</b>
                  </a>
                  <a
                    href='/school-readiness'
                    className='btn btn-outline-success rounded-pill shadow new purpleButton'
                    role='button'
                    data-bs-toggle='button'
                  >
                    <b>Readiness Performance</b>
                  </a>
                  <a
                    href='/school-classroom-observation'
                    className='btn btn-outline-success rounded-pill shadow new purpleButton'
                    role='button'
                    data-bs-toggle='button'
                  >
                    <b>Classroom Observation</b>
                  </a>
                  <a
                    href={`https://monitoring.neeve.io/${query}`}
                    className='btn   shadow rounded-pill  new btt1'
                    role='button'
                    data-bs-toggle='button'
                  >
                    <b className='home__card__heading'>Take a Survey</b>
                  </a>
                </div>
              </div>
              <div className=' color3 hello shadow p-2 mb-5 rounded'>
                <div
                  className='home__decription'
                  style={{ backgroundColor: '#f1c232' }}
                >
                  <h3 className='text-center pt-3'>Neeve Training</h3>
                  <p>
                    Learn new skill , teaching techniques and more from our
                    courses
                  </p>
                </div>
                <div className='d-grid gap-2 leraning '>
                  <a
                    href='/training-home'
                    className='btn shadow rounded-pill  new yellowButton'
                    role='button'
                    data-bs-toggle='button'
                  >
                    <b>Training Dashboard</b>
                  </a>
                  <a
                    className='btn shadow rounded-pill  new btt'
                    href='https://bhoomieducationalconsultancy.graphy.com/courses/Learning-Recovery-to-Learning-Enhancement-latest-64c76893e4b04854c742ea47'
                    target='_blank'
                    role='button'
                    data-bs-toggle='button'
                    rel='noreferrer'
                  >
                    <b className='home__card__heading'>Start a Course</b>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  } else {
    return <Redirect to={'/login'} />;
  }
}
export default HomDshb;
function logout() {
  localStorage.removeItem('u_code');
  localStorage.removeItem('udise_id');
  localStorage.removeItem('school_name');
  localStorage.removeItem('role');
  localStorage.removeItem('is_done');
  localStorage.removeItem('path');
  localStorage.removeItem('navActive');
  localStorage.removeItem('m_auth_token');
  window.location.href = `/`;
}
const openWorkSheet = () => {
  const u_code = localStorage
    .getItem('u_code')
    .replace('"', '')
    .replace('"', '')
    .replace('%22', '')
    .replace('%22', '');
  const user_id = localStorage
    .getItem('user_id')
    .replace('"', '')
    .replace('"', '');
  const url = `https://worksheetwarehouse.neeve.io/?udise_code=${user_id}&auth_token=${u_code}`;
  window.open(url);
};
