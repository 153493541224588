import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import {
  Bar,
  BarChart,
  CartesianGrid,
  LabelList,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import {
  getReadinessAspiringSubjectScores,
  getStateDashboardData,
  getTeacherScoreAnalysis,
} from '../services/api';
import aspring_img1 from './../assets/assetNew1.png';
import aspring_img2 from './../assets/assetNew2.png';
import Kaizaley from './../assets/Kaizaley.pdf';
import neveBg from './../assets/neve-highlight-bg.jpg';
import pdf from './../assets/pdf.jpg';
import trending_learning_photo from './../assets/trending_learning_photo.jpg';
import trending_learning_photo2 from './../assets/trending_learning_photo2.jpg';
import video from './../assets/video.jpg';
import Heading from './Heading';
import LoaderSpinner from './LoaderSpinner';
import NotFoundDialog from './NotFoundDialog';
import { exportPDF } from './pdfDownloader';
import PieChart from './PieChart';
import SchoolModal from './SchoolModal';
import DropdownButton from './Signout';
import './styles/StateHomePage.css';

const barChartColors = [
  ['#c0e3fb', '#0390f2', '#004677'],
  ['#fcdcdc', '#f47979', '#750b0b'],
  ['#ffddaf', '#ffa834', '#f38d00'],
];

const StateHomePage = () => {
  // eslint-disable-next-line no-unused-vars
  const [state, setState] = useState(localStorage.getItem('state'));
  const [stateData, setStateData] = useState();
  // eslint-disable-next-line no-unused-vars
  const [district, setDistrict] = useState(localStorage.getItem('district'));
  const [isLoading, setIsLoading] = useState(false);
  const [readinessAspiringSubjectScores, setReadinessAspiringSubjectScores] =
    useState([]);
  const [showModal, setShowModal] = useState(false);
  const [activeSchool, setActiveSchool] = useState('');
  const [pieDataEnglish, setPieDataEnglish] = useState();
  const [pieDataMaths, setPieDataMaths] = useState();
  const [pieDataScience, setPieDataScience] = useState();
  const [teacherScoreAnalysis, setTeacherScoreAnalysis] = useState([]);

  const closeModal = () => {
    setActiveSchool('');
    setShowModal(false);
  };

  useEffect(() => {
    const u_code = localStorage.getItem('m_auth_token');
    const payload = {
      state: `${state}`,
    };
    const config = {
      headers: {
        Authorization: `${u_code}`,
      },
    };
    getStateDashboardData(state).then((res) => {
      setStateData(res.data.data);
      const opData = res.data?.data?.dashboard_state_cce_report;
      const _pieDataEnglish = opData?.filter(
        (item) => item.subject === 'English'
      );
      const _pieDataMaths = opData?.filter((item) => item.subject === 'Maths');
      const _pieDataScience = opData?.filter(
        (item) => item.subject === 'Science'
      );
      setPieDataEnglish(_pieDataEnglish);
      setPieDataMaths(_pieDataMaths);
      setPieDataScience(_pieDataScience);
    });

    getTeacherScoreAnalysis(payload, config).then((res) => {
      setTeacherScoreAnalysis(res.data.data);
    });
  }, [state]);

  useEffect(() => {
    setIsLoading(true);
    const u_code = localStorage.getItem('m_auth_token');
    const payload = {
      state: `${state}`,
      district: district ? `${district}` : localStorage.getItem('district'),
    };
    const config = {
      headers: {
        Authorization: `${u_code}`,
      },
    };

    getReadinessAspiringSubjectScores(payload, config)
      .then((res) => {
        setReadinessAspiringSubjectScores(res.data.data ? res.data.data : []);
        setIsLoading(() => false);
      })
      .catch(() => {
        setIsLoading(() => false);
      });
  }, [district, state]);

  return (
    <div id='state_home'>
      <div className='readinessCon'>
        <div className='readHeadingCon'>
          <h2>State Homepage</h2>

          <div className='readRight'>
            <DropdownButton />
          </div>
        </div>
        <div className='readMain'>
          <p>
            The programme aims to improve the learning performance of schools.
            The key focus is to enrich teaching- learning through competency
            based framework aimed towards achieving specific learning outcomes.
            The approach is to teach students at the right level. Extensive use
            of technology helps in improving digital literacy as well as each
            micro processes can be tracked and analysed in real time. This
            enables administrators to take quick decisions that is supported by
            data. World class resources, learning and development programmes,
            well defined processes - all backed by technology brings about a 360
            degree transformation of schools.
          </p>
          <button
            className='dropdownCus'
            style={{ width: '250px', height: '42px', margin: '10px' }}
            onClick={() => exportPDF('state_home', 'State Home Page Analysis')}
            id='downloadButton'
          >
            Download Report
          </button>
          <hr />
          <br />
          <div className='see__more'>
            <h2>Readiness</h2>{' '}
            <h6>
              <NavLink to={'/statedashboardnew/Readiness/PerformanceAnalysis'}>
                SEE MORE
              </NavLink>
            </h6>
          </div>

          <p>
            Readiness programme is designed to ensure students are ready for the
            new class. Readiness commences with a baseline assessment, followed
            by a 4 -6 weeks of carefully designed revision exercise of prior
            competencies and ends with an end line assessment to determine the
            efficacy of Readiness.
          </p>
        </div>
      </div>
      {!isLoading ? (
        <>
          <div className='endlineComparison decHeight'>
            <div className='endlineComparison'>
              <div className='chartCon'>
                {readinessAspiringSubjectScores.length > 0
                  ? readinessAspiringSubjectScores.map((item, index) => {
                      return (
                        <BarChartNew
                          data={processBarChartData(item)}
                          name={item.subject}
                          colors={barChartColors[index]}
                          key={index + 1}
                        />
                      );
                    })
                  : ''}
              </div>
              <div className='chartCon'>
                <div className='img__container'>
                  <img src={aspring_img1} alt='' />
                </div>
                {readinessAspiringSubjectScores.length > 0 ? (
                  <BarChartNew
                    data={processBarAllSubjectsChartData(
                      readinessAspiringSubjectScores
                    )}
                    name='All Subject'
                    colors={['#d4ead8', '#27963c', '#062d0d']}
                  />
                ) : (
                  ''
                )}
                <div className='img__container'>
                  <img src={aspring_img2} alt='' />
                </div>
              </div>
            </div>
          </div>
          <hr />
          <br />
          <div className='see__more'>
            <h2>Supplementary learning</h2>{' '}
            <h6>
              <NavLink to={`/statedashboardnew/${state}/`}>SEE MORE</NavLink>
            </h6>
          </div>
          <div className='improvment'>
            <div className='improvmentCon'>
              <p>
                Supplementary Learning offers extra help during school hours or
                at home so that students can practice lessons completed in
                class. This is particularly helpful for students who have
                developed learning gaps or are in need of extra support.
                Supplementary learning creates greater equity in the education
                system, addressing the rights of these students to get a better
                chance at education. In this programme, supplementary learning
                is delivered through app Neeve.
              </p>
            </div>
            <div className='row'>
              <div className='col-12'>
                <div className='d-flex flex-column sec-pad-top '>
                  <div className='w-100 text-center pt-5 '>
                    <Heading text='Neeve Highlights' />
                  </div>
                  <div className='blank'></div>
                  <div className='neve-highlight tree'>
                    <div className='neve-bg neve-none'>
                      <img src={neveBg} alt='' />
                    </div>
                    {stateData?.dashboard_highlights && (
                      <div className='d-flex view-responsive neve-highlight '>
                        <div className='mt-2-sm'>
                          <div className='circle cir-sky'>
                            <p className='cir-text'>
                              <span className='bigText'>
                                {stateData?.dashboard_highlights[0].text}
                              </span>{' '}
                              Schools
                            </p>
                          </div>
                        </div>

                        <div className='mt-2-sm'>
                          <div className='circle cir-indigo'>
                            <p className='cir-text'>
                              <span className='bigText'>
                                {stateData?.dashboard_highlights[1].text}
                              </span>{' '}
                              Students
                            </p>
                          </div>
                        </div>

                        <div className='mt-2-sm'>
                          <div className='circle cir-war'>
                            <p className='cir-text'>
                              <span className='bigText'>
                                {stateData?.dashboard_highlights[2].text}
                              </span>{' '}
                              Online
                            </p>
                          </div>
                        </div>

                        <div className='mt-2-sm'>
                          <div className='circle cir-pink'>
                            <p className='cir-text2'>
                              <span className='bigText'>
                                {stateData?.dashboard_highlights[3].text}
                              </span>{' '}
                              Question answered
                            </p>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <br />
            <br />
            <div className='d-block neve-highlight '>
              <PieChart
                pieDataEnglish={pieDataEnglish}
                pieDataMaths={pieDataMaths}
                pieDataScience={pieDataScience}
              />
            </div>
          </div>
          <hr />
          <br />
          <div className='see__more'>
            <h2>Classroom Observation</h2>{' '}
            <h6>
              <NavLink to={'/statedashboardnew/ClassroomObservation'}>
                SEE MORE
              </NavLink>
            </h6>
          </div>
          <div>
            <p>
              While in classroom, the teachers are supported through continuous,
              structured reflection and feedback on their practices
            </p>
            {teacherScoreAnalysis.length > 0 && (
              <div className='bar__chart__container'>
                <BarChartNew2
                  name='Preparation for Class'
                  colors={['#d0e8d7', '#27963C']}
                  data={teacherScoreAnalysis[0]['Preparation for Class']}
                />
                <BarChartNew2
                  name='Classroom management'
                  colors={['#cdd8ee', '#1249B0']}
                  data={teacherScoreAnalysis[1]['Classroom Management']}
                />
                <BarChartNew2
                  name='Teaching learning practice'
                  colors={['#e9d8ff', '#9747FF']}
                  data={teacherScoreAnalysis[2]['Teaching-Learning Practice']}
                />
                <BarChartNew2
                  name='Assessment & feedback'
                  colors={['#ffddaf', '#FFA934']}
                  data={
                    teacherScoreAnalysis[3][
                      'Assessment, Feedback and Follow-Up'
                    ]
                  }
                />
              </div>
            )}
            <br />
            <br />
          </div>
          <hr />
          <br />
          <div className='see__more'>
            <h2>Training & development</h2>{' '}
            <h6>
              <NavLink to={'/statedashboardnew/TrainingDevelopment'}>
                SEE MORE
              </NavLink>
              E
            </h6>
          </div>
          <div className='improvment'>
            <div className='improvmentCon'>
              <p>
                New information, techniques and ideas are packaged into
                cutomised training courses for teachers. These courses are
                delivered through high performance online training platforms.
                Teachers are awarded certificates on completion of courses.
              </p>
            </div>
            <div className='trending__learning__container'>
              <h4>Trending – Learning Recovery to Learning Enhancement</h4>
              <div className='trending__learning__sub__container'>
                <div className='trending__learning__sub__container1'>
                  <img
                    className='trending__learning__img'
                    src={trending_learning_photo}
                    alt=''
                  />
                </div>
                <div className='trending__learning__sub__container2'>
                  <p>4 Modules</p>
                  <p>4 hrs 30 min</p>
                  <p>online, interactive</p>
                  <p>certificates</p>
                </div>
                <div className='trending__learning__sub__container3'>
                  <div className='trending__learning__circle'>
                    <div>
                      <h1>23</h1>
                      <p>completed</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='trending__learning__container'>
              <h4>Upcoming – Effective Classroom Management Techniques</h4>
              <div className='trending__learning__sub__container'>
                <div className='trending__learning__sub__container1'>
                  <img
                    className='trending__learning__img'
                    src={trending_learning_photo2}
                    alt=''
                  />
                </div>
                <div className='trending__learning__sub__container2'>
                  <p>4 Modules</p>
                  <p>4 hrs 30 min</p>
                  <p>online, interactive</p>
                  <p>certificates</p>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div className='videoPdfCon'>
            <h2>Highlights from state</h2>
            <div className='docPdfWrap'>
              <div className='bottomDivs'>
                <div className=''>
                  <img className='imageAsp' src={video} alt='' />
                </div>
                <h5>Highlight</h5>
                <p className='description-text'>
                  Readiness is foundation to building new skills. A large set of
                  students find it difficult to recall prior competencies when
                  they start a new class.
                </p>
              </div>

              <div className='bottomDivs'>
                <div className=''>
                  <img className='imageAsp' src={video} alt='' />
                </div>
                <h5>Highlight</h5>
                <p className='description-text'>
                  Readiness is foundation to building new skills. A large set of
                  students find it difficult to recall prior competencies when
                  they start a new class.
                </p>
              </div>

              <div className='bottomDivs'>
                <div className=''>
                  <img className='imageAsp' src={video} alt='' />
                </div>
                <h5>Highlight</h5>
                <p className='description-text'>
                  Readiness is foundation to building new skills. A large set of
                  students find it difficult to recall prior competencies when
                  they start a new class.
                </p>
              </div>

              <div className='bottomDivs'>
                <div className=''>
                  <img className='imageAsp' src={video} alt='' />
                </div>
                <h5>Highlight</h5>
                <p className='description-text'>
                  Readiness is foundation to building new skills. A large set of
                  students find it difficult to recall prior competencies when
                  they start a new class.
                </p>
              </div>
            </div>
          </div>

          <div className='videoPdfCon'>
            <h2>Video & PDF Documents</h2>
            <div className='docPdfWrap'>
              <div className='bottomDivs'>
                <div className=''>
                  <img className='imageAsp' src={video} alt='' />
                </div>
                <h5>Readiness sprint</h5>
                <p className='description-text'>
                  Watch a small video on how students and teachers and engaging
                  in Readiness Sprint with interest. Pachak Secondary School
                  Pakyong Class - 6 Science
                </p>
                <a
                  target='_blank'
                  href='https://youtu.be/k0-8Emon6vs'
                  rel='noreferrer'
                >
                  Watch on Youtube
                </a>
              </div>

              <div className='bottomDivs'>
                <div>
                  <img src={pdf} alt='' />
                </div>
                <h5>Readiness Resource Pack</h5>
                <p className='description-text'>
                  This is a sample Readiness Resource Pack. One week of
                  carefully designed Worksheets, Projects and a simple
                  transaction plan is included here. 6 such packs are supplied
                  for all 3 subjects and classes.
                </p>
                <a
                  target='_blank'
                  href='https://drive.google.com/file/d/1fuyn67Jz_O4dY8XVMvoL4DYCRSnGiykC/view?usp=share_link'
                  rel='noreferrer'
                >
                  Download PDF
                </a>
              </div>

              <div className='bottomDivs'>
                <div>
                  <img src={video} alt='' />
                </div>
                <h5>Trash to Treasure</h5>
                <p className='description-text'>
                  Class 7 students exploring how kitchen scrap can grow into
                  edible microgreens or even vegetables with a little care – all
                  as part of their Readiness Sprint. Ralak JHS Mangan
                </p>
                <a
                  target='_blank'
                  href='https://youtu.be/NVbDvOXvBjw'
                  rel='noreferrer'
                >
                  Watch on Youtube
                </a>
              </div>

              <div className='bottomDivs'>
                <div>
                  <img src={pdf} alt='' />
                </div>
                <h5>From the HM’s Desk</h5>
                <p className='description-text'>
                  A Headmaster’s account of how Readiness Sprint has helped
                  students overcoming their fear of studies. Kaizaley Junior
                  High School Namthang, Namchi
                </p>
                <a target='_blank' href={Kaizaley} rel='noreferrer'>
                  Download PDF
                </a>
              </div>
            </div>
          </div>
          {showModal && (
            <div style={{ zIndex: 1000000 }}>
              <SchoolModal closeModal={closeModal} data={activeSchool} />
            </div>
          )}
        </>
      ) : isLoading ? (
        <LoaderSpinner />
      ) : (
        <NotFoundDialog />
      )}
    </div>
  );
};

export default StateHomePage;

const processBarAllSubjectsChartData = (data) => {
  const baselineTotal_gt_75 =
    data[0].baseline.gt_75 + data[1].baseline.gt_75 + data[2].baseline.gt_75;
  const baselineTotal_bet_50_75 =
    data[0].baseline.bet_50_75 +
    data[1].baseline.bet_50_75 +
    data[2].baseline.bet_50_75;
  const baselineTotal_lt_50 =
    data[0].baseline.lt_50 + data[1].baseline.lt_50 + data[2].baseline.lt_50;

  const baselineTotal =
    baselineTotal_gt_75 + baselineTotal_bet_50_75 + baselineTotal_lt_50;

  const endlineTotal_gt_75 =
    data[0].endline.gt_75 + data[1].endline.gt_75 + data[2].endline.gt_75;
  const endlineTotal_bet_50_75 =
    data[0].endline.bet_50_75 +
    data[1].endline.bet_50_75 +
    data[2].endline.bet_50_75;
  const endlineTotal_lt_50 =
    data[0].endline.lt_50 + data[1].endline.lt_50 + data[2].endline.lt_50;

  const endlineTotal =
    endlineTotal_gt_75 + endlineTotal_bet_50_75 + endlineTotal_lt_50;

  const baselineAll = {
    gt_75: baselineTotal_gt_75,
    bet_50_75: baselineTotal_bet_50_75,
    lt_50: baselineTotal_lt_50,
  };
  const endlineAll = {
    gt_75: endlineTotal_gt_75,
    bet_50_75: endlineTotal_bet_50_75,
    lt_50: endlineTotal_lt_50,
  };

  const finalData = {
    baseline: baselineAll,
    endline: endlineAll,
  };

  const result = Object.keys(finalData.baseline).reduce((acc, key) => {
    const range = {
      gt_75: '>75%',
      bet_50_75: '50-75%',
      lt_50: '<75%',
    };

    const baselineValue = finalData.baseline[key];
    const endlineValue = finalData.endline[key];

    const obj = {
      name: range[key],
      Baseline: Math.floor((baselineValue / baselineTotal) * 100),
      End_line: endlineTotal
        ? Math.floor((endlineValue / endlineTotal) * 100)
        : 0,
    };

    acc.push(obj);
    return acc;
  }, []);
  return result;
};

const processBarChartData = (data) => {
  const baselineTotal =
    data.baseline.gt_75 + data.baseline.bet_50_75 + data.baseline.lt_50;
  const endlineTotal =
    data.endline.gt_75 + data.endline.bet_50_75 + data.endline.lt_50;

  const result = Object.keys(data.baseline).reduce((acc, key) => {
    const range = {
      gt_75: '>75%',
      bet_50_75: '50-75%',
      lt_50: '<50%',
    };

    const baselineValue = data.baseline[key];
    const endlineValue = data.endline[key];

    const obj = {
      name: range[key],
      Baseline: Math.floor((baselineValue / baselineTotal) * 100),
      End_line: endlineTotal
        ? Math.floor((endlineValue / endlineTotal) * 100)
        : 0,
    };

    acc.push(obj);
    return acc;
  }, []);
  return result;
};

const BarChartNew = ({ data, name, colors }) => {
  const renderCustomizedLabel = (props) => {
    const { x, y, width, value } = props;
    return (
      <text
        x={x + width / 2}
        y={y + 15}
        fill='#fff'
        textAnchor='middle'
        dominantBaseline='middle'
        fontSize={10}
      >
        {`${value}%`}
      </text>
    );
  };
  return (
    <div className='bar__chart'>
      <div className='bar__chart__top' style={{ backgroundColor: colors[0] }}>
        <h1>{name}</h1>
      </div>
      <div>
        <BarChart
          width={300}
          height={230}
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: -15,
            bottom: 5,
          }}
          barGap={5500}
          barSize={30}
        >
          <Tooltip />

          <CartesianGrid strokeDasharray='3 3' />
          <XAxis dataKey='name' height={20} fontSize={10} />
          <YAxis
            type='number'
            domain={[0, 100]}
            ticks={[0, 20, 40, 60, 80, 100]}
          />
          {/* <Tooltip /> */}
          <Bar
            dataKey='Baseline'
            fill={colors[1]}
            minPointSize={10}
            radius={[50, 50, 0, 0]}
          >
            radius={[50, 50, 0, 0]}
            <LabelList dataKey={'Baseline'} content={renderCustomizedLabel} />
          </Bar>
          <Bar
            dataKey='End_line'
            fill={colors[2]}
            minPointSize={5}
            width={20}
            radius={[50, 50, 0, 0]}
          >
            <LabelList dataKey='End_line' content={renderCustomizedLabel} />
          </Bar>
        </BarChart>
      </div>
    </div>
  );
};

const BarChartNew2 = ({ name, colors, data }) => {
  const initTotal =
    data.initOne + data.initTwo + data.initThree + data.initFour;
  const finalTotal =
    data.finalOne + data.finalTwo + data.finalThree + data.finalFour;
  function calculatePercentage(value) {
    return ((value / finalTotal) * 100).toFixed(0);
  }
  const barChartData = [
    {
      initial: ((data.initOne / initTotal) * 100).toFixed(0),
      final: calculatePercentage(data.finalOne),
    },
    {
      initial: ((data.initTwo / initTotal) * 100).toFixed(0),
      final: data.finalTwo,
    },
    {
      initial: ((data.initThree / initTotal) * 100).toFixed(0),
      final: calculatePercentage(data.finalThree),
    },
    {
      initial: ((data.initFour / initTotal) * 100).toFixed(0),
      final: calculatePercentage(data.finalFour),
    },
  ];
  const renderCustomizedLabel = (props) => {
    const { x, y, width, value } = props;
    return (
      <text
        x={x + width / 2}
        y={y + 15}
        fill='#fff'
        textAnchor='middle'
        dominantBaseline='middle'
        fontSize={10}
      >
        {`${value}%`}
      </text>
    );
  };
  return (
    <div className='class__room__bar__chart'>
      <div
        className='class__room__bar__chart__top'
        style={{ backgroundColor: colors[0] }}
      >
        <h1>{name}</h1>
      </div>
      <>
        <BarChart
          width={250}
          height={230}
          data={barChartData}
          margin={{
            top: 5,
            right: 30,
            left: -15,
            bottom: 5,
          }}
          barGap={5500}
          barSize={30}
        >
          <Tooltip />

          <CartesianGrid strokeDasharray='3 3' />
          <XAxis
            dataKey='name'
            domain={[0, 100]}
            ticks={[0, 1, 40, 60, 80, 100]}
          />
          <YAxis
            type='number'
            domain={[0, 100]}
            ticks={[0, 20, 40, 60, 80, 100]}
          />
          <Bar
            dataKey='final'
            fill={colors[1]}
            minPointSize={10}
            radius={[50, 50, 0, 0]}
          >
            radius={[50, 50, 0, 0]}
            <LabelList dataKey={'final'} content={renderCustomizedLabel} />
          </Bar>
        </BarChart>
      </>
    </div>
  );
};
