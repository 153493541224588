import React from 'react';
import { Link } from 'react-router-dom';
import './styles/CceTabel.css';

function OverallPerformance({ type, opData }) {
  const DisplayData = opData.map((info, index) => {
    return (
      <>
        {type === 'sl' ? (
          <tr key={index}>
            <td>{info.udise_code}</td>
            <td>{info.school_name}</td>
            <td>{info.no_users}</td>
            <td>
              <Link to={'/performance-school/' + info.udise_id}>
                <span className='text-danger'>View Performance</span>
              </Link>
            </td>
          </tr>
        ) : (
          <tr key={index}>
            <td>{info.subject}</td>
            <td>{info.Aspiring}</td>
            <td>{info.Scholar}</td>
            <td>{info.Master}</td>
          </tr>
        )}
      </>
    );
  });

  return (
    <div className='mar-topbot'>
      <div className='table-responsive'>
        <table stripped='true' bordered='true' hover='true' responsive='sm'>
          <thead>
            {type === 'sl' ? (
              <tr>
                <th>UDISE ID</th>
                <th>SCHOOL NAME</th>
                <th>NEEVE USERS</th>
                <th>SEE PERFORMANCE ON SCHOOL PAGE</th>
              </tr>
            ) : (
              <tr>
                <th>Subject</th>
                <th>Aspiring</th>
                <th>Scholar</th>
                <th>Master</th>
              </tr>
            )}
          </thead>
          <tbody>{DisplayData}</tbody>
        </table>
      </div>
    </div>
  );
}

export default OverallPerformance;
