import React from 'react';
import './styles/LoaderSpinner.css';

const NotFoundDialog = () => {
  return (
    <div
      className='d-flex align-items-center justify-content-center'
      style={{ height: '40vh' }}
    >
      <div
        className='d-flex flex-column align-self-center align-items-center justify-content-center'
        style={{
          width: '300px',
          padding: '40px',
          borderRadius: '20px',
          boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
        }}
      >
        <div>
          <i
            className='fa-solid fa-circle-exclamation fa-2xl'
            style={{ color: '#A82929' }}
          ></i>
        </div>
        <div style={{ paddingTop: '15px', fontWeight: 700, fontSize: '20px' }}>
          No Data Found
        </div>
      </div>
    </div>
  );
};
export default NotFoundDialog;
