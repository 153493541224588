import React from 'react';
import MyPieChart from './PieChart';

const pieChartColors = [
  ['#0390f2', '#0073c2', '#004677'],
  ['#f47979', '#a82a2a', '#760c0c'],
  ['#ffc371', '#ffa934', '#f38d00'],
];
const tempDataForEndline = {
  aspiring: 1,
  scholar: 1,
  master: 1,
};
const ExamPrepEndline = ({ endLineData }) => {
  const checkEndline = (data) => {
    if (data.aspiring || data.scholar || data.master) {
      return true;
    } else {
      return false;
    }
  };
  return (
    <div>
      <h2>Exam Prep Endline</h2>
      <p>
        End line is a pen and paper test that is designed to mimic the Board
        Exam style. End line is carried out after 4 weeks of practice on Neeve
        app and various other structured revision resources. This result
        indicates the readiness of the student for the Board Exam.
      </p>
      <div style={{ display: 'flex', marginTop: '-70px' }}>
        {endLineData.length > 0 &&
          endLineData.map((item, index) => {
            return (
              <div className='endlineComparison' key={index}>
                <div className='chartCoTwo'>
                  <MyPieChart
                    heading={item.subject}
                    // data={processPieChartData(item.baseline)}
                    data={processPieChartData(
                      checkEndline(item.endline)
                        ? item.endline
                        : tempDataForEndline
                    )}
                    COLORS={
                      checkEndline(item.endline)
                        ? pieChartColors[index]
                        : ['#dfdfdf', '#c6c6c7', '#bfbfbe']
                    }
                  />
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default ExamPrepEndline;

const processPieChartData = (data) => {
  const tempData = {
    aspiring: Number(data.aspiring),
    scholar: Number(data.scholar),
    master: Number(data.master),
  };
  const result = Object.entries(tempData).reduce((acc, [category, value]) => {
    acc.push({ name: category, value });
    return acc;
  }, []);
  return result;
};
