import React from 'react';

const StudentHeader = ({ level, desc }) => {
  return (
    <div className='col-12'>
      <div
        className='row'
        style={{
          backgroundColor: '#fff',
          marginLeft: 15,
          marginRight: 15,
          marginTop: 5,
          marginBottom: 5,
          paddingTop: 15,
        }}
      >
        <h5>{level}</h5>
        <p>{desc}</p>
      </div>
    </div>
  );
};

export default StudentHeader;
