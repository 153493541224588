import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import React, { useState } from 'react';
import { Redirect } from 'react-router';
import { Link, useHistory } from 'react-router-dom';
import { getMonitoringAuthData } from '../services/api';
import bgimage from './../assets/bg-image.png';
import * as mod from './../resources/url';
import Header from './header-signin';

function Login() {
  if (localStorage.getItem('is_done')) {
    const path = localStorage.getItem('path');
    return <Redirect to={`${path}`} />;
  }
  return (
    <div>
      <Header />
      <div className='singnupbg' style={{ backgroundImage: `url(${bgimage})` }}>
        <div className='container login-padding'>
          <div className='row text-center'>
            <div className='col-0 col-md-2'></div>

            <div className='col-0 col-md-8 formDiv'>
              <h2>Login</h2>
              <br />
              <NameForm />
            </div>

            <div className='col-0 col-md-2'></div>
          </div>
        </div>
      </div>
    </div>
  );
}

const NameForm = () => {
  const [udise, setUdise] = useState('');
  const [password, setPassword] = useState('');
  const [udiseError, setUdiseError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const history = useHistory();

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!udise && !password) {
      setUdiseError('Please fill the UDISE Code');
      setPasswordError('Your password is incorrect');
      return;
    } else if (!udise) {
      setUdiseError('Please fill the UDISE Code');
      return;
    } else if (!password) {
      setPasswordError('Your password is incorrect');
      return;
    } else {
      const apiBaseUrl = mod.api_url + '/user/web/login';
      localStorage.setItem('user_id', udise);
      const data = {
        udiseId: udise,
        password: password,
      };
      axios
        .post(apiBaseUrl, data)
        .then(async function (response) {
          if (response.data.data) {
            localStorage.setItem(
              'u_code',
              encodeURIComponent(JSON.stringify(response.data.data.accessToken))
            );
            // console.log('resssssssssssss*************', response.data.data);
            localStorage.setItem('udise_id', response.data.data.id);
            localStorage.setItem('state', response.data.data.state);
            localStorage.setItem('district', response.data.data.district);
            localStorage.setItem('school_name', response.data.data.school_name);
            localStorage.setItem('role', response.data.data.role);
            localStorage.setItem('is_done', true);

            const payload = {
              udiseCode: `${udise}`,
              authToken: `${response.data.data.accessToken}`,
            };
            const authResult = await getMonitoringAuthData(payload).then(
              (res) => {
                if (response.data.data) {
                  localStorage.setItem(
                    'm_auth_token',
                    res.data.data.access_token
                  );
                  return res.data.data;
                }
              }
            );
            // console.log('auth resssssssss',authResult);
            if (authResult && response.data.data) {
              if (response.data.data.role === 'Admin') {
                localStorage.setItem('path', `/statedashboardnew/`);
                localStorage.setItem('navActive', `/statedashboardnew/`);
                history.push(`/statedashboardnew/`);
              } else {
                localStorage.setItem('path', `/SchoolHomePage`);
                history.push(`/SchoolHomePage`);
              }
            } else {
              alert('Invalid credentials');
            }
          }
        })
        .catch(function () {
          setPasswordError(
            'Your user id or password is incorrect. Please enter your school udise code and account password'
          );
        });
    }
  };
  return (
    <form onSubmit={handleSubmit}>
      <input
        className='input-text-box input-padding'
        type='text'
        name='udise'
        value={udise}
        onChange={(event) => {
          setUdise(event.target.value);
          setUdiseError('');
        }}
        placeholder='UDISE Code'
      />
      <div
        className='error-msg-new'
        style={{ visibility: udiseError ? 'visible' : 'hidden' }}
      >
        <FontAwesomeIcon icon={faExclamationCircle} color='#f00' /> {udiseError}
      </div>

      <input
        className='input-text-box input-padding'
        type='password'
        name='password'
        value={password}
        onChange={(event) => {
          setPassword(event.target.value);
          setPasswordError('');
        }}
        placeholder='Password'
      />

      <div
        className='error-msg-new'
        style={{ visibility: passwordError ? 'visible' : 'hidden' }}
      >
        <FontAwesomeIcon icon={faExclamationCircle} color='#f00' />{' '}
        {passwordError}
      </div>

      <div className='more-info row text-right'>
        <div className='col'>
          <Link to='/forgotpassword'>Forgot Password?</Link>
        </div>
      </div>

      <input
        className='btn header-nav-btn-primary-new input-login-padding'
        type='submit'
        value='Submit'
      />
    </form>
  );
};

export default Login;
