import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import Carousel from 'react-elastic-carousel';
import {
  Bar,
  BarChart,
  CartesianGrid,
  LabelList,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import trending_learning_photo from './../assets/trending_learning_photo.jpg';
import trending_learning_photo2 from './../assets/trending_learning_photo2.jpg';
import data from './data.json';
import { exportPDF } from './pdfDownloader';
import DropdownButton from './Signout';
import './styles/TrainingDevelopment.css';

const barChartData = [
  {
    name: 'Pre',
    Pre: 70,
  },
  {
    name: 'Post',
    Pre: 50,
  },
];

const certList = [
  {
    img: require('./../assets/certificate2.png'),
    title: '',
    subheading: '',
    text: '  ',
  },
  {
    img: require('./../assets/certificate3.png'),
    title: '',
    subheading: '',
    text: '  ',
  },
  {
    img: require('./../assets/certificate4.png'),
    title: '',
    subheading: '',
    text: '  ',
  },
];
const breakPoints = [
  { width: 1, itemsToShow: 1 },
  { width: 550, itemsToShow: 3 },
  { width: 768, itemsToShow: 3 },
  { width: 1200, itemsToShow: 3 },
];
const TrainingDevelopment = () => {
  const [numberOfTrainee, setNumberOfTrainee] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const [state, setState] = useState(localStorage.getItem('state'));
  const filterByStateAndCount = (dataArray, state) => {
    console.log(dataArray);
    const filteredData = dataArray.filter(
      (item) => item.State.toLowerCase() === state.toLowerCase()
    );
    console.log(filteredData);
    return filteredData.length;
  };
  useEffect(() => {
    const getTrainings = async () => {
      try {
        const response = await fetch(
          'https://api.ongraphy.com/public/v2/learners?mid=bhoomieducationalconsultancy&key=06bd94cf-5adf-4f03-9693-911b73d20109&courseInfo=true'
        );
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const result = await response.json();
        console.log('result: state graphy ', result);
        setNumberOfTrainee(() => filterByStateAndCount(data.data, state));
        console.log(numberOfTrainee);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    getTrainings();
  }, [numberOfTrainee, state]);
  const renderCard = (card, index) => {
    return (
      <Card key={index} className='item'>
        <Card.Img variant='top' src={card.img} />
        <Card.Body className='text-dark'>
          <Card.Title>{card.title}</Card.Title>
          <Card.Subtitle>{card.subheading}</Card.Subtitle>
          <Card.Text>{card.text} </Card.Text>
        </Card.Body>
      </Card>
    );
  };

  return (
    <div id='training' style={{ padding: '15px' }}>
      <div className='readHeadingCon'>
        <h2>Training & Development</h2>
        <div>
          <DropdownButton />
        </div>
      </div>
      <div className='improvment'>
        <section className='topSec'>
          <div className='container-fluid'>
            <div className='row'>
              <div className='col-12 nTop'>
                <h2>Neeve Training & Development Centre</h2>
                <p className='text-center whiteText headinSub'>
                  With the aim of providing quality education, LEP has gone
                  online starting 2021. Students and teachers work on digital
                  resources, assessments are conducted...{' '}
                </p>
                <hr className='hrCus' />

                <div className='schoolDetails'>
                  <div className='ban'>
                    <img src={require('./../assets/books.jpg')} alt='' />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <br />
        <div style={{ display: 'flex', gap: '40px' }}>
          <h1>List of Courses</h1>
          <button
            className='dropdownCus'
            style={{ width: '250px', height: '42px', margin: '10px' }}
            onClick={() => exportPDF('training', 'Training Analysis')}
            id='downloadButton'
          >
            Download Report
          </button>
        </div>
        <br />
        <div className='trending__learning__container'>
          <h4>Trending – Learning Recovery to Learning Enhancement</h4>
          <div className='trending__learning__sub__container'>
            <div className='trending__learning__sub__container1'>
              <img
                className='trending__learning__img'
                src={trending_learning_photo}
                alt=''
              />
            </div>
            <div className='trending__learning__sub__container2'>
              <p>4 Modules</p>
              <p>4 hrs 30 min</p>
              <p>online, interactive</p>
              <p>certificates</p>
            </div>
            <div className='trending__learning__sub__container3'>
              <div className='trending__learning__circle'>
                <div>
                  <h1>{numberOfTrainee}</h1>
                  <p>completed</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='trending__learning__container'>
          <h4 style={{ alignItems: 'center' }}>
            Upcoming – Effective Classroom Management Techniques
          </h4>
          <div className='trending__learning__sub__container'>
            <div className='trending__learning__sub__container1'>
              <img
                className='trending__learning__img'
                src={trending_learning_photo2}
                alt=''
              />
            </div>
            <div className='trending__learning__sub__container2'>
              <p>4 Modules</p>
              <p>4 hrs 30 min</p>
              <p>online, interactive</p>
              <p>certificates</p>
            </div>
          </div>
        </div>
      </div>
      <br />
      <h1>Training Scores</h1>
      <br />
      <p>
        {' '}
        Each course starts and ends with a test. Scores of pre and post tests
        are aggregated here. Scores for schools and individual teachers are
        avialble in the school portal.
      </p>
      <br />
      <div className='bar__chart__container'>
        <BarChartNew2
          name='Recovery to Enhancement'
          colors={['#d0e8d7', '#27963C']}
        />
        <BarChartNew2
          name='Classroom Management Techniques'
          colors={['#cdd8ee', '#1249B0']}
        />
        <BarChartNew2 name='Upcoming' colors={['#e9d8ff', '#9747FF']} />
        <BarChartNew2 name='Upcoming' colors={['#ffddaf', '#FFA934']} />
      </div>
      <br />
      <br />
      <h1>Certificates</h1>
      <br />
      <br />
      <p>
        Teachers are issued certificates after successful completion of the
        course. Certificates are available in their training account and also on
        school portal.
      </p>
      <br />
      <section className='skySec'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-12'>
              <div className='w-90'>
                <div className='certificateImg owl-carousel1 owl-theme '>
                  <Carousel
                    itemPadding={[0, 8]}
                    itemsToScroll={1}
                    showArrows={false}
                    breakPoints={breakPoints}
                  >
                    {certList.map(renderCard)}
                  </Carousel>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default TrainingDevelopment;
const BarChartNew2 = ({ name, colors }) => {
  const renderCustomizedLabel = (props) => {
    const { x, y, width, value } = props;
    return (
      <text
        x={x + width / 2}
        y={y + 15}
        fill='#fff'
        textAnchor='middle'
        dominantBaseline='middle'
        fontSize={10}
      >
        {`${value}%`}
      </text>
    );
  };
  return (
    <div className='class__room__bar__chart'>
      <div
        className='class__room__bar__chart__top'
        style={{ backgroundColor: colors[0], width: '250px' }}
      >
        <h1>{name}</h1>
      </div>
      <div>
        <BarChart
          width={250}
          height={230}
          data={barChartData}
          margin={{
            top: 5,
            right: 30,
            left: -15,
            bottom: 5,
          }}
          barGap={550}
          barSize={50}
        >
          <Tooltip />

          <CartesianGrid strokeDasharray='3 3' />
          <XAxis dataKey='name' height={20} fontSize={20} />
          <YAxis
            type='number'
            domain={[0, 100]}
            ticks={[0, 20, 40, 60, 80, 100]}
          />
          <Tooltip />
          <Bar
            dataKey='Pre'
            fill={colors[1]}
            minPointSize={10}
            radius={[50, 50, 0, 0]}
          >
            radius={[50, 50, 0, 0]}
            <LabelList dataKey={'Pre'} content={renderCustomizedLabel} />
          </Bar>
        </BarChart>
      </div>
    </div>
  );
};
