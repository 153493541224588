/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import './styles/Signout.css';

const DropdownButton = () => {
  const [isOpen, setIsOpen] = useState(false);
  const history = useHistory();
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const role = localStorage.getItem('role');
  const school = localStorage.getItem('school_name');
  const udise_id = localStorage.getItem('udise_id');
  const handleSignOut = () => {
    localStorage.removeItem('u_code');
    localStorage.removeItem('udise_id');
    localStorage.removeItem('school_name');
    localStorage.removeItem('role');
    localStorage.removeItem('is_done');
    localStorage.removeItem('path');
    localStorage.removeItem('navActive');
    localStorage.removeItem('m_auth_token');
    history.push(`/`);
  };

  return (
    <div className='dropdown'>
      <div className='readRight'>
        <div className='text-end'>
          <h4>{role === 'Educator' ? school : role}</h4>
          <p>{udise_id}</p>
        </div>
        <div className='profile'>
          <i className='fa-solid fa-circle-user fa-2xl'></i>
          {isOpen && (
            <>
              <div className='dropdown-content'>
                <a href='' onClick={handleSignOut}>
                  Sign Out
                </a>
              </div>
            </>
          )}
        </div>
        <p
          className='dropdown-toggle'
          data-bs-toggle='dropdown'
          aria-expanded='false'
          onClick={toggleDropdown}
        >
          <i className='fa-solid fa-chevron-down'></i>
        </p>
      </div>
    </div>
  );
};

export default DropdownButton;
