import React from 'react';
import Select from 'react-select';
import * as mod from './../resources/url';
// eslint-disable-next-line no-unused-vars
import axios from 'axios';

function createform() {
  return (
    <div>
      <NameForm />
    </div>
  );
}

class NameForm extends React.Component {
  state = {
    types: [],
    subjects: [],
    class: [],
    typeselect: '612dcb0014921b9fecae6772',
    subjectselect: '',
    classselect: '',
    comment: '',
    visibilityselect: 'all',
  };

  constructor(props) {
    super(props);

    this.handleTypes = this.handleTypes.bind(this);
    this.handleSubjects = this.handleSubjects.bind(this);
    this.handleClass = this.handleClass.bind(this);
    this.handleComment = this.handleComment.bind(this);
    this.handleVisibility = this.handleVisibility.bind(this);
    this.handleSubmitForm = this.handleSubmitForm.bind(this);
  }

  handleTypes(event) {
    this.setState({ typeselect: event.value });
  }

  handleSubjects(event) {
    this.setState({ subjectselect: event.value });
  }

  handleClass(event) {
    this.setState({ classselect: event.value });
  }

  handleComment(event) {
    this.setState({ comment: event.target.value });
  }

  handleVisibility(event) {
    this.setState({ visibilityselect: event.value });
  }

  handleSubmitForm() {
    var typeselect = this.state.typeselect;
    var visibilityselect = this.state.visibilityselect;
    var subjectselect = this.state.subjectselect;
    var classselect = this.state.classselect;
    var comment = this.state.comment;
    var apiBaseUrl = mod.api_url + '/user/forum/post';
    var data = {
      userId: { _id: localStorage.getItem('udise_id') },
      typeId: { _id: typeselect },
      udiseId: { _id: localStorage.getItem('udise_id') },
      classId: { _id: classselect },
      subjectId: { _id: subjectselect },
      userName: { _id: localStorage.getItem('school_name') },
      text: comment,
      visibility: visibilityselect,
    };

    axios
      .post(apiBaseUrl, data)
      .then(function (response) {
        if (response.status === 200) {
          alert('Your Post is Submitted Successfully');
          window.location.href = '/forum';
        } else {
          alert(response.data.message);
        }
      })
      .catch(function (error) {
        console.log(error);
        alert('Unable to Post comment');
      });
  }

  componentDidMount() {
    Promise.all([
      fetch(mod.api_url + '/user/forum/types'),
      fetch(mod.api_url + '/user/filter/subjects'),
      fetch(mod.api_url + '/user/classes'),
    ])
      .then(([res1, res2, res3]) => {
        return Promise.all([res1.json(), res2.json(), res3.json()]);
      })
      .then(([res1, res2, res3]) => {
        this.setState({ types: res1, subjects: res2, class: res3 });
      });
  }

  render() {
    var typesrow = [];
    var subjectsrow = [];
    var classrow = [];
    typesrow.push({ value: '612dcb0014921b9fecae6772', label: 'Teachers' });
    typesrow.push({ value: '612dcb3014921b9fecae6773', label: ' Students' });
    typesrow.push({
      value: '612dcb3014921b9fecae6774',
      label: 'Teachers + Students',
    });
    if (this.state.subjects.data) {
      for (let i = 0; i < this.state.subjects.data.length; i++) {
        subjectsrow.push({
          value: this.state.subjects.data[i]._id,
          label: this.state.subjects.data[i].subject,
        });
      }
    }

    if (this.state.class.data) {
      for (var i = 0; i < this.state.class.data.length; i++) {
        classrow.push({
          value: this.state.class.data[i]._id,
          label: this.state.class.data[i].class,
        });
      }
    }

    return (
      <form>
        <textarea
          id='school_name'
          className='input-textboxx'
          name='schoolname'
          placeholder='Type Your Message'
          onChange={this.handleComment}
        ></textarea>

        <p className='label-span'>TO</p>
        <Select
          id='select_category'
          name='select_category'
          className='input-text-boxx'
          placeholder='School Notice'
          options={typesrow}
          onChange={this.handleTypes}
        />

        <p className='label-spanx1'>CLASS</p>
        <Select
          id='select_category'
          name='select_category'
          className='input-text-boxx1'
          placeholder='All Classes'
          options={classrow}
          onChange={this.handleClass}
        />

        <p className='label-spanx2'>SUBJECT</p>
        <Select
          id='select_category'
          name='select_category'
          className='input-text-boxx2'
          placeholder='All Subjects'
          options={subjectsrow}
          onChange={this.handleSubjects}
        />

        <p className='label-spanx3'>ATTACH</p>
        <input type='file' className='input-text-box3'></input>

        <input
          className='btnx header-nav-btn-primary'
          value='SEND'
          onClick={this.handleSubmitForm}
        />
      </form>
    );
  }
}

export default createform;
