import axios from 'axios';
import React from 'react';
import bgimage from './../assets/bg-image.png';
import * as mod from './../resources/url';
import Header from './header-signup';

function Login() {
  return (
    <div>
      <Header />
      <div className='singnupbg' style={{ backgroundImage: `url(${bgimage})` }}>
        <br />
        <br />
        <br />
        <br />

        <div className='container'>
          <div className='row text-center'>
            <div className='col-0 col-md-2'></div>

            <div className='col-0 col-md-8 formDiv'>
              <h2>Forgot Password?</h2>
              <h5>
                Enter the verification code we just sent to your school email
                address.
              </h5>

              <NameForm />
            </div>

            <div className='col-0 col-md-2'></div>
          </div>
        </div>
        <br />
        <br />
        <br />
      </div>
    </div>
  );
}
function handlechangeResend() {
  var email = 0;

  var getemail = localStorage.getItem('forgot_email');
  var pattern = new RegExp(
    /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
  );

  if (getemail && getemail !== '' && pattern.test(getemail)) {
    email = 1;
  } else {
    console.log('Email not defined');
  }

  if (email === 1) {
    var apiBaseUrl = mod.api_url + '/user/web/otp';

    var data = {
      emailId: getemail,
    };
    console.log(data);

    axios
      .post(apiBaseUrl, data)
      .then(function (response) {
        console.log(response);

        if (response.status === 200) {
          localStorage.setItem('forgot_email', getemail);

          window.location.href = `/forgotpassword_verifyotp`;
        } else {
          alert(response.data.message);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }
}
class NameForm extends React.Component {
  constructor(props) {
    super(props);
    const getemail = localStorage.getItem('forgot_email');
    this.state = { email: getemail, verificationcode: '' };

    console.log(getemail);
    this.handleChangeVerificationcode =
      this.handleChangeVerificationcode.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChangeVerificationcode(event) {
    this.setState({ verificationcode: event.target.value });
  }

  handleSubmit(event) {
    event.preventDefault();

    console.log(this.state.email);
    var email_id = this.state.email;
    console.log(this.state.verificationcode);
    var email = 0;

    if (
      this.state.verificationcode &&
      this.state.verificationcode !== '' &&
      this.state.verificationcode.length === 4
    ) {
      console.log('UDISE Defined');
      document.getElementById('verify-error-1').style.display = 'none';
      email = 1;
    } else {
      document.getElementById('verify-error-1').style.display = 'block';
    }
    if (email === 1) {
      var apiBaseUrl = mod.api_url + '/user/web/otp/verify';

      var data = {
        emailId: this.state.email,
        otp: this.state.verificationcode,
      };
      console.log(data);

      axios
        .post(apiBaseUrl, data)
        .then(function (response) {
          console.log(response);

          if (response.status === 200) {
            localStorage.setItem('forgot_email', email_id);

            window.location.href = `/forgotpassword_verifysuccess`;
          } else {
            alert(response.message);
          }
        })
        .catch(function (error) {
          console.log(error);
          alert('You entered an incorrect OTP. Please enter a valid OTP.');
          document.getElementById('verify-error-1').style.display = 'block';
        });
    }
  }

  render() {
    return (
      <form onSubmit={this.handleSubmit}>
        <input
          className='input-text-box'
          type='text'
          name='verificationcode'
          onChange={this.handleChangeVerificationcode}
          placeholder='Verification Code'
          onKeyPress={(event) => {
            if (!/[0-9]/.test(event.key)) {
              event.preventDefault();
            }
          }}
          maxLength='4'
        />
        <h6 id='verify-error-1' className='error-msg'>
          <span>🠕</span> Please enter a valid Verification code
        </h6>
        <p>
          If you didn't receive a code!{' '}
          <a className='resend-link' onClick={handlechangeResend} href='##'>
            Resend Code
          </a>
        </p>

        <input
          className='btn header-nav-btn-primary'
          type='submit'
          value='Verify'
        />
      </form>
    );
  }
}
export default Login;
