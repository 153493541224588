import React from 'react';
import { Link } from 'react-router-dom';
import gPlay from './../assets/googleplay.png';
import logo from './../assets/logo.png';
import './../custom.css';

function Header() {
  return (
    <div>
      <div className='container'>
        <div
          className='row text-center'
          style={{
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div className='col-md-2'>
            <Link to='/'>
              <img src={logo} alt='logo' />
            </Link>
          </div>
          <div className='col-md-8 ' style={{ alignSelf: 'center' }}></div>
          <div className='col-md-2'>
            <Link
              to={{
                pathname:
                  'https://play.google.com/store/apps/details?id=io.wiseowllearning.neeve&hl=en_IN&gl=US',
              }}
              target={'_blank'}
            >
              <img style={{ width: 150 }} src={gPlay} alt='gplay' />
            </Link>
          </div>
        </div>
      </div>
      <hr style={{ marginBottom: '0px' }} />
    </div>
  );
}
export default Header;
