function OnGoingCourses({ courseList }) {
  const openLink = () => {
    window.open(
      'https://bhoomieducationalconsultancy.graphy.com/courses/Learning-Recovery-to-Learning-Enhancement-latest-64c76893e4b04854c742ea47',
      '_blank'
    );
  };
  return (
    <div className='w-90'>
      <div className='tabel-responsive'>
        <table className='table table-bordered whiteTabel table-bordered '>
          <thead>
            <tr>
              <th scope='col'>Course Title </th>
              <th scope='col' className='tex-center'>
                Completed
              </th>
              <th scope='col' className='tex-center'>
                Ongoing
              </th>
              <th scope='col'> </th>
            </tr>
          </thead>
          <tbody>
            {courseList.map((p, i) => (
              <tr key={i}>
                <th scope='row'>{p.title}</th>
                <td className='tex-center'>4</td>
                <td className='tex-center'>1</td>
                {/*<td className="tex-center"><button className="skyButton" onClick={openModal} type="button">Go to course</button></td>*/}
                <td className='tex-center'>
                  <button
                    className='skyButton'
                    type='button'
                    onClick={openLink}
                  >
                    Go to course
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default OnGoingCourses;
