import React from 'react';
import { Link } from 'react-router-dom';
import logo from './../assets/logo.png';
import './../custom.css';
// import userlogo from "./../assets/Component 46 – 1.svg";
// import school_logo from "./../assets/school-icon.png";
// import { BrowserRouter, Route, Switch } from "react-router-dom";
// import Login from "./login";
// import Signup from "./signup";
// import Home from "./home";
// import { Dropdown } from "react-bootstrap";

function logout() {
  localStorage.removeItem('u_code');
  localStorage.removeItem('udise_id');
  localStorage.removeItem('school_name');
  localStorage.removeItem('role');
  localStorage.removeItem('is_done');
  localStorage.removeItem('path');
  localStorage.removeItem('navActive');
  localStorage.removeItem('m_auth_token');
  window.location.href = `/`;
}

function Header({ hideLogout }) {
  return (
    <div>
      <div className='container'>
        <div className='row text-center'>
          <div className='col-md-2 col-8'>
            <Link to='/'>
              <img src={logo} alt='' />
            </Link>
          </div>
          <div className='col-md-10 col-4 ' style={{ textAlign: 'right' }}>
            <br />

            <div className='dropdown'>
              {!hideLogout && (
                <button
                  className='btn'
                  style={{ cursor: 'pointer' }}
                  onClick={logout}
                >
                  Log Out
                </button>
              )}
              {/* <Dropdown>
                                <Dropdown.Toggle 
                                variant="secondary btn-sm" 
                                id="dropdown-basic">
                                    <img src={school_logo} style={{width:"50px", borderRadius: "30px"}}/>
                                </Dropdown.Toggle>

                                <Dropdown.Menu style={{backgroundColor:'#73a47'}} >
                                    <Dropdown.Item href="#" onClick={logout}>Log Out</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown> */}
            </div>
          </div>
        </div>
      </div>
      <hr style={{ marginBottom: '0px' }} />
    </div>
  );
}

export default Header;
