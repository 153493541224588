import React from 'react';
import { Redirect } from 'react-router';
import Header from './header-signedin';
import LeftMenu from './left-menu';

function Login() {
  if (!localStorage.getItem('is_done')) {
    return <Redirect to={'/login'} />;
  }

  return (
    <div>
      <Header />
      <div className='bg-grey min-height'>
        <div className='container-fluid p-10'>
          <div className='row min-height' style={{ padding: 20 }}>
            <div className='col-2 left-menu'>
              <br />
              <LeftMenu />
            </div>
            <div className='col-9 right-menu'>
              <div style={{ textAlign: 'center' }}>
                <br />
                <br />
                <br />
                <br />
                <h2>
                  Make worksheets, projects and set assignments easily on Neeve.
                </h2>
                <h1>Coming soon</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
