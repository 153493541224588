import { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import Carousel from 'react-elastic-carousel';
import { Redirect } from 'react-router';
import {
  Bar,
  BarChart,
  CartesianGrid,
  LabelList,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import {
  getAllCourse,
  getSchoolDtlByUdiseId,
  registerTrainee,
  traineeIsRegister,
} from '../../services/api';
import Header from '../header-signedin';
import trending_learning_photo from './../../assets/trending_learning_photo.jpg';
import trending_learning_photo2 from './../../assets/trending_learning_photo2.jpg';
import data from './../data.json';
import AlertSection from './alert-section';
import CourseDetails from './course-details';
import OnGoingCourses from './on-going-courses';
import TeacherLogin from './teacher-login';
import './training-style.css';

const barChartData = [
  {
    name: 'Pre',
    Pre: 70,
  },
  {
    name: 'Post',
    Pre: 50,
  },
];

function NeeveTraining() {
  // eslint-disable-next-line no-unused-vars
  const [teacherData, setTeacherData] = useState({});
  const udise_id = localStorage.getItem('udise_id');
  const teacher_login = localStorage.getItem('teacher_login');

  const [school, setSchool] = useState([]);
  const [allCourse, setCourse] = useState([]);

  /* Teacher login section */
  const [modalIsOpen, setIsOpen] = useState(false);
  const [validated, setValidated] = useState(false);
  const [initValReg, setInitValReg] = useState({
    inputs: {
      firstName: '',
      lastName: '',
      phoneNumber: '',
      udiseCode: school.udise_code,
      udiseId: school.udise_id,
      designation: '',
    },
    errrors: {
      firstName: '',
      lastName: '',
      phoneNumber: 'Please provide a valid Phone Nnumber.',
    },
    login: false,
  });
  const [numberOfTrainee, setNumberOfTrainee] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const [state, setState] = useState(localStorage.getItem('state'));
  const filterByStateAndCount = (dataArray, state) => {
    const filteredData = dataArray.filter(
      (item) => item.State.toLowerCase() === state.toLowerCase()
    );
    return filteredData.length;
  };
  useEffect(() => {
    const getTrainings = async () => {
      try {
        const response = await fetch(
          'https://api.ongraphy.com/public/v2/learners?mid=bhoomieducationalconsultancy&key=06bd94cf-5adf-4f03-9693-911b73d20109&courseInfo=true'
        );
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const result = await response.json();
        setNumberOfTrainee(() => filterByStateAndCount(data.data, state));
        console.log('result graphy', result);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    getTrainings();
  }, [numberOfTrainee, state]);

  function onEnter() {
    setInitValReg((prevState) => ({
      ...prevState,
      inputs: {
        ...prevState.inputs,
        udiseCode: school.udise_code,
        udiseId: school.udise_id,
      },
      errrors: {
        ...prevState.errrors,
        phoneNumber: 'Please provide a valid Phone Nnumber.',
      },
      login: false,
    }));
  }
  function closeModal() {
    setIsOpen(false);
  }
  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false || initValReg.errrors.phoneNumber) {
      if (initValReg.errrors.phoneNumber && initValReg.login) {
        handleLogin(event);
      }
      event.preventDefault();
      event.stopPropagation();
    } else {
      const inputObj = Object.fromEntries(
        Object.entries(initValReg.inputs).map(([key, value]) => {
          return [key === 'phoneNumber' ? 'mobile' : `${key}`, value];
        })
      );
      registerTrainee(inputObj)
        .then((res) => {
          console.log('=== After submit data return=== ', res.data.data);
          localStorage.setItem('teacher_login', JSON.stringify(res.data.data));
          closeModal();
          openLoginAlert();
        })
        .catch((error) => {
          const { code, message } = error.response.data;

          if (code === 400 && message === 'Mobile Number Already Exist.') {
            console.log(error.response.data);
            setInitValReg((prevState) => ({
              ...prevState,
              inputs: {
                ...prevState.inputs,
              },
              errrors: {
                ...prevState.errrors,
                phoneNumber: 'Mobile Number Already Exist.',
              },
              login: true,
            }));
          }
        });
    }
    event.preventDefault();
    event.stopPropagation();
    setValidated(true);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    //initValReg.inputs[name] = value;
    setInitValReg((prevState) => ({
      ...prevState,
      inputs: {
        ...prevState.inputs,
        [name]: value,
      },
      errrors: {
        ...prevState.errrors,
      },
      login: false,
    }));

    if (name === 'phoneNumber') {
      let errMsg;
      if (phoneValidation(value)) {
        errMsg = '';
      } else {
        errMsg = 'Please provide a valid Phone Nnumber.';
      }

      setInitValReg((prevState) => ({
        ...prevState,
        inputs: {
          ...prevState.inputs,
        },
        errrors: {
          ...prevState.errrors,
          phoneNumber: errMsg,
        },
        login: false,
      }));
    }
  };

  const handleLogin = (event) => {
    const inputObj = Object.fromEntries(
      Object.entries(initValReg.inputs).map(([key, value]) => {
        return [key === 'phoneNumber' ? 'mobile' : `${key}`, value];
      })
    );
    console.log('Logeed in event is clicked', inputObj);
    traineeIsRegister(inputObj)
      .then((res) => {
        console.log('=== login data === ', res.data.data);
        localStorage.setItem('teacher_login', JSON.stringify(res.data.data));
        closeModal();
        openLoginAlert();
      })
      .catch((error) => {
        const { code, message } = error.response.data;

        if (
          code === 400 &&
          message === "Mobile Number doesn't Exist please register."
        ) {
          setInitValReg((prevState) => ({
            ...prevState,
            inputs: {
              ...prevState.inputs,
            },
            errrors: {
              ...prevState.errrors,
              phoneNumber: "Mobile Number doesn't Exist please register.",
            },
            login: false,
          }));
        }
      });

    event.preventDefault();
    event.stopPropagation();
  };

  /* Teacher login section */

  /* Course details modal */
  const [courseModal, setCourseModal] = useState(false);
  function openCourseModal() {
    setCourseModal(true);
  }
  function onEnterCourse() {
    console.log('===Course details API call before modal open=== ');
  }
  function closeCourseModal() {
    setCourseModal(false);
  }
  /* Course details modal */

  const phoneValidation = (phoneNum) => {
    const regex = /^[0-9]{10}$/i;
    return !(!phoneNum || regex.test(phoneNum) === false);
  };

  useEffect(() => {
    if (!localStorage.getItem('is_done')) {
      return <Redirect to={'/login'} />;
    }

    getSchoolDtlByUdiseId(udise_id).then(
      (res) => {
        setSchool(res.data.data);
      },
      (error) => {
        console.log(
          '================ error on getSchoolDtlByUdiseId ==============',
          error
        );
      }
    );

    getAllCourse()
      .then((res) => {
        const { code, errno } = res.data.data;
        if (errno !== -3001 && code !== 'EAI_AGAIN') {
          setCourse(res.data.data);
        }
      })
      .catch((error) => {
        console.log(
          '================ error on getAllCourse ==============',
          error
        );
      });

    if (teacher_login) {
      setTeacherData(JSON.parse(teacher_login));
    }
  }, [udise_id, setSchool, setCourse, teacher_login]);

  // const openCourse = (courseId, learnerId) => {
  //   console.log("--------------courseID & learnerId", courseId, learnerId);
  //   const inputObj = { courseId: courseId, learnerId: learnerId.toString() };

  //   createRegistration(inputObj)
  //     .then((res) => {
  //       if (res.data.code === 200) {
  //         console.log(
  //           "================ res on courseLink ==============",
  //           res.data.data.courseLink
  //         );
  //         const { courseLink } = res.data.data;

  //         var popup = window.open(courseLink, "newWin", 'fullscreen="yes"');
  //         if (popup == null) openPopUpAlert();
  //         else {
  //           popup.moveTo(0, 0);
  //           popup.focus();
  //         }
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(
  //         "================ error on createRegistration ==============",
  //         error.response.data
  //       );
  //     });
  // };

  const intAlertData = {
    show: false,
    fadeOut: false,
    variant: 'success', // success or danger
    heading: 'Login Success!',
    paragraph: 'Click on go to course button to launch course',
  };
  const [alertData, setAlertData] = useState(intAlertData);

  const closeAlert = () => {
    setAlertData((prevState) => ({
      ...prevState,
      fadeOut: true,
    }));

    setTimeout(() => {
      setAlertData((prevState) => ({
        ...prevState,
        show: false,
        fadeOut: false,
      }));
    }, 10000);
  };
  const openLoginAlert = () => {
    setAlertData((prevState) => ({
      ...prevState,
      show: true,
      fadeOut: false,
    }));

    setTimeout(() => {
      setAlertData((prevState) => ({
        ...prevState,
        show: false,
        fadeOut: false,
      }));
    }, 10000);
  };

  // const openPopUpAlert = () => {
  //   setAlertData((prevState) => ({
  //     ...prevState,
  //     show: true,
  //     fadeOut: false,
  //     variant: "danger",
  //     heading: "Popup is Blocked!",
  //     paragraph: "Please allow the pop-ups from Settings",
  //   }));
  //   // console.log("Pop-up alert setting ", alertData);
  //   setTimeout(() => {
  //     setAlertData((prevState) => ({
  //       ...prevState,
  //       show: false,
  //       fadeOut: false,
  //     }));
  //   }, 10000);
  // };

  const certList = [
    {
      img: require('./../../assets/certificate.png'),
      title: '',
      subheading: '',
      text: '  ',
    },
    {
      img: require('./../../assets/certificate2.png'),
      title: '',
      subheading: '',
      text: '  ',
    },
    {
      img: require('./../../assets/certificate3.png'),
      title: '',
      subheading: '',
      text: '  ',
    },
  ];

  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 3 },
    { width: 768, itemsToShow: 3 },
    { width: 1200, itemsToShow: 3 },
  ];

  const renderCard = (card, index) => {
    return (
      <Card key={index} className='item'>
        <Card.Img variant='top' src={card.img} />
        <Card.Body className='text-dark'>
          <Card.Title>{card.title}</Card.Title>
          <Card.Subtitle>{card.subheading}</Card.Subtitle>
          <Card.Text>{card.text} </Card.Text>
        </Card.Body>
      </Card>
    );
  };

  return (
    <div>
      <Header />
      <TeacherLogin
        show={modalIsOpen}
        onHide={closeModal}
        onEnter={onEnter}
        validated={validated.toString()}
        onSubmit={handleSubmit}
        onChange={handleChange}
        initval={initValReg}
        scldata={school}
      />
      <CourseDetails
        show={courseModal}
        onEnter={onEnterCourse}
        onHide={closeCourseModal}
      />

      <div>
        <section className='topSec'>
          <div className='container-fluid'>
            <div className='row'>
              <div className='col-12 nTop'>
                <h2>Neeve Training & Development Centre</h2>
                <p className='text-center whiteText headinSub'>
                  With the aim of providing quality education, LEP has gone
                  online starting 2021. Students and teachers work on digital
                  resources, assessments are conducted...{' '}
                </p>
                <hr className='hrCus' />

                <div className='schoolDetails'>
                  <div className='ban'>
                    <div className='banCon'>
                      <ul className='list-inline mb-3'>
                        <li className='list-inline-item '>
                          <strong className=''>{school.school_name}</strong>{' '}
                        </li>
                        <li className='list-inline-item '>
                          <span>UDISE</span> - {school.udise_code}{' '}
                        </li>
                        <li className='list-inline-item '>
                          <span>State</span> - {school.state}{' '}
                        </li>
                        <li className='list-inline-item'>
                          <span>District</span> - {school.district}{' '}
                        </li>
                        <li className='list-inline-item '>
                          <span>Enrolment</span> - {school.no_student}{' '}
                        </li>
                        <li className='list-inline-item '>
                          <span>ClassNamees</span> : 6 - 10{' '}
                        </li>
                      </ul>
                      <a href='{{1}}' className='sbutton mb-3'>
                        Update School Info
                      </a>
                    </div>
                    <img src={require('./../../assets/books.jpg')} alt='' />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <AlertSection closeAlert={closeAlert} alertData={alertData} />
      <h2 style={{ marginLeft: '100px' }}>List of All courses</h2>
      <br />

      <div className='trending__learning__container' style={{ width: '85%' }}>
        <h4>Trending – Learning Recovery to Learning Enhancement</h4>
        <div className='trending__learning__sub__container'>
          <div className='trending__learning__sub__container1'>
            <img
              className='trending__learning__img'
              src={trending_learning_photo}
              alt=''
            />
          </div>
          <div className='trending__learning__sub__container2'>
            <p>4 Modules</p>
            <p>4 hrs 30 min</p>
            <p>online, interactive</p>
            <p>certificates</p>
          </div>
          <div className='trending__learning__sub__container3'>
            <div className='trending__learning__circle'>
              <div>
                <h1>{numberOfTrainee}</h1>
                <p>completed</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='trending__learning__container' style={{ width: '85%' }}>
        <h4 style={{ alignItems: 'center' }}>
          Upcoming – Effective Classroom Management Techniques
        </h4>
        <div className='trending__learning__sub__container'>
          <div className='trending__learning__sub__container1'>
            <img
              className='trending__learning__img'
              src={trending_learning_photo2}
              alt=''
            />
          </div>
          <div className='trending__learning__sub__container2'>
            <p>4 Modules</p>
            <p>4 hrs 30 min</p>
            <p>online, interactive</p>
            <p>certificates</p>
          </div>
        </div>
      </div>

      <section className='whiteSec'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-12'>
              <div className='col-12 tex-center'>
                <h6 className='blue-text'>Courses</h6>
                <h2 className='assText '>Neeve on going Courses</h2>
              </div>
              <OnGoingCourses
                courseList={allCourse}
                openModal={openCourseModal}
              />
            </div>
          </div>
        </div>
      </section>

      <div style={{ width: '85%', margin: 'auto' }}>
        <br />
        {/* <h1>Training Scores</h1> */}
        <br />
        <p>
          {' '}
          Each course starts and ends with a test. Scores of pre and post tests
          are aggregated here. Scores for schools and individual teachers are
          avialble in the school portal.
        </p>
        <div
          className='bar__chart__container'
          style={{ width: '100%', margin: 'auto', justifyContent: 'center' }}
        >
          <BarChartNew2
            name='Recovery to Enhancement'
            colors={['#d0e8d7', '#27963C']}
          />
          <BarChartNew2
            name='Classroom Management Techniques'
            colors={['#cdd8ee', '#1249B0']}
          />
          <BarChartNew2 name='Upcoming' colors={['#e9d8ff', '#9747FF']} />
          <BarChartNew2 name='Upcoming' colors={['#ffddaf', '#FFA934']} />
        </div>
      </div>
      <br />

      <section className='skySec'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-12'>
              <h6 className='blue-text'>Training & Development</h6>
              <h2 className='whiteText '>Our Latest Certificates </h2>
              <p className='text-center whiteText'>
                3 latest certificates are shown here. Rest are available in the
                individual trainees' accounts{' '}
              </p>
              <hr />
              <div className='w-90'>
                <div className='certificateImg owl-carousel1 owl-theme '>
                  <Carousel
                    itemPadding={[0, 8]}
                    itemsToScroll={1}
                    showArrows={false}
                    breakPoints={breakPoints}
                  >
                    {certList.map(renderCard)}
                  </Carousel>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default NeeveTraining;

const BarChartNew2 = ({ name, colors }) => {
  const renderCustomizedLabel = (props) => {
    const { x, y, width, value } = props;
    return (
      <text
        x={x + width / 2}
        y={y + 15}
        fill='#fff'
        textAnchor='middle'
        dominantBaseline='middle'
        fontSize={10}
      >
        {`${value}%`}
      </text>
    );
  };
  return (
    <div className='class__room__bar__chart'>
      <div
        className='class__room__bar__chart__top'
        style={{ backgroundColor: colors[0], width: '250px' }}
      >
        <h1>{name}</h1>
      </div>
      <div>
        <BarChart
          width={250}
          height={230}
          data={barChartData}
          margin={{
            top: 5,
            right: 30,
            left: -15,
            bottom: 5,
          }}
          barGap={550}
          barSize={50}
        >
          <Tooltip />

          <CartesianGrid strokeDasharray='3 3' />
          <XAxis dataKey='name' height={20} fontSize={20} />
          <YAxis
            type='number'
            domain={[0, 100]}
            ticks={[0, 20, 40, 60, 80, 100]}
          />
          <Tooltip />
          <Bar
            dataKey='Pre'
            fill={colors[1]}
            minPointSize={10}
            radius={[50, 50, 0, 0]}
          >
            radius={[50, 50, 0, 0]}
            <LabelList dataKey={'Pre'} content={renderCustomizedLabel} />
          </Bar>
        </BarChart>
      </div>
    </div>
  );
};
