import axios from 'axios';
import React from 'react';
import * as mod from './../resources/url';

function createform() {
  return (
    <div>
      <NameForm />
    </div>
  );
}

class NameForm extends React.Component {
  state = { email: '', mobile: '' };

  constructor(props) {
    super(props);
    this.handleEmail = this.handleEmail.bind(this);
    this.handleMobile = this.handleMobile.bind(this);
    this.handleSubmitForm = this.handleSubmitForm.bind(this);
  }

  handleEmail(event) {
    this.setState({ email: event.target.value });
  }

  handleMobile(event) {
    this.setState({ mobile: event.target.value });
  }

  handleSubmitForm() {
    var email = this.state.email;
    var mobile = this.state.mobile;
    function validateEmail(email) {
      const re =
        /^(([^<>()[\]\\.,;:\s@\\"]+(\.[^<>()[\]\\.,;:\s@\\"]+)*)|(\\".+\\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    }
    function phonenumber(inputtxt) {
      var phoneNum = inputtxt.replace(/[^\d]/g, '');
      if (phoneNum.length === 10) {
        return true;
      }
    }
    var emailok = 0;
    var mobok = 0;
    var errmsg = '';
    if (validateEmail(email)) {
      emailok = 1;
    } else {
      errmsg += 'Please Enter a Valid Email\n';
    }
    if (phonenumber(mobile)) {
      mobok = 1;
    } else {
      errmsg += 'Please Enter a Valid Mobile Number';
    }

    if (emailok === 1 && mobok === 1) {
      var apiBaseUrl = mod.api_url + '/user/freetrial';

      var data = {
        mobileNumber: mobile,
        countryCode: '+91',
        emailId: email,
      };

      axios
        .post(apiBaseUrl, data)
        .then(function (response) {
          console.log(response);

          if (response.status === 200) {
            alert(
              'Thank you for requesting the  free trial of Neeve. Our support team will contact you shortly.'
            );
            window.location.href = '/';
          } else {
            alert(response.data.message);
          }
        })
        .catch(function (error) {
          console.log(error);
          alert('Unable to Post comment');
        });
    } else {
      alert(errmsg);
    }
  }

  render() {
    return (
      <form>
        <div className='row'>
          <div className='col' style={{ textAlign: 'right' }}>
            <input
              className='input-text-box'
              type='text'
              placeholder='Email Id'
              onChange={this.handleEmail}
            />
          </div>
          <div className='col' style={{ textAlign: 'left' }}>
            <input
              className='input-text-box'
              type='text'
              placeholder='Mobile Number'
              onChange={this.handleMobile}
            />
          </div>
        </div>
        <br />
        <div className='row'>
          <div className='col'>
            <input
              className='btn header-nav-btn-primary'
              type='button'
              value='START FREE TRIAL'
              onClick={this.handleSubmitForm}
            />
          </div>
        </div>
      </form>
    );
  }
}

export default createform;
