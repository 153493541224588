/* eslint-disable no-unused-vars */
import * as _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Redirect } from 'react-router';
import imgp from '../assets/imgp.jpg';
import {
  deleteCommentPostData,
  deletePostData,
  getClassData,
  getForumData,
  getQueriesData,
  getSubjectData,
  submitCommentPostData,
} from '../services/api';
import post from './../assets/Group 2245 (1).svg';
import NewPost from './../components/form-createnewpost';
import './../custom.css';
import Header from './header-signedin';
import LeftMenu from './left-menu';

function Resource() {
  const [classId, setClassId] = useState();
  const [subject, setSubject] = useState();
  const [queries, setQueries] = useState();

  const [queriesData, setQueriesData] = useState([]);
  const [subjectData, setSubjectData] = useState([]);
  const [classData, setClassData] = useState([]);
  const [forumData, setForumData] = useState([]);
  const getAllForumData = () => {
    let data = {};

    if (subject && queries && classId) {
      data = {
        typeId: { _id: queries },
        classId: { _id: classId },
        subjectId: { _id: subject },
        udiseId: { _id: localStorage.getItem('udise_id') },
      };
    } else if (subject && queries) {
      data = {
        typeId: { _id: queries },
        subjectId: { _id: subject },
        udiseId: { _id: localStorage.getItem('udise_id') },
      };
    } else if (subject && classId) {
      data = {
        classId: { _id: classId },
        subjectId: { _id: subject },
        udiseId: { _id: localStorage.getItem('udise_id') },
      };
    } else if (queries && classId) {
      data = {
        typeId: { _id: queries },
        classId: { _id: classId },
        udiseId: { _id: localStorage.getItem('udise_id') },
      };
    } else if (subject && subject !== '') {
      data = {
        subjectId: { _id: subject },
        udiseId: { _id: localStorage.getItem('udise_id') },
      };
    } else if (queries && queries !== '') {
      data = {
        typeId: { _id: queries },
        udiseId: { _id: localStorage.getItem('udise_id') },
      };
    } else if (classId && classId !== '') {
      data = {
        classId: { _id: classId },
        udiseId: { _id: localStorage.getItem('udise_id') },
      };
    } else {
      data = {
        udiseId: { _id: localStorage.getItem('udise_id') },
      };
    }
    data['roll'] = 'school';

    getForumData(data).then((res) => {
      if (res) {
        setForumData(res.data.data);
      }
    });
  };
  useEffect(() => {
    getAllForumData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subject, classId, queries]);

  useEffect(() => {
    //getAllForumData();

    getQueriesData().then(
      (res1) => {
        if (res1) {
          setQueriesData(res1.data.data);
        }
      },
      (error) => {}
    );

    getSubjectData().then((res) => {
      if (res) {
        setSubjectData(res.data.data);
      }
    });

    getClassData().then((res2) => {
      if (res2) {
        setClassData(res2.data.data);
      }
    });
  }, []);

  const Card = ({ item }) => (
    <div className='col mb-6'>
      <div className='card'>
        <img src={item?.thumbnail} className='card-img-top' alt='fdd' />
        <div className='card-body'>
          <h5 className='card-title'>{item?.title}</h5>
          <p className='card-text'>{item?.desc}</p>
          <a href={item?.link} className='btn btn-outline-secondary'>
            Download Now
          </a>
        </div>
      </div>
    </div>
  );

  const HeaderForm = () => {
    return <div className='row'></div>;
  };

  const CommentView = ({ comment, item }) => {
    const today = new Date(comment.createdAt);
    var d = new Intl.DateTimeFormat('hi-IN', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    }).format(today);
    return (
      <div className='row' style={{ marginLeft: '15px' }}>
        <div className='col-1'></div>
        <div className='col-8'>
          <p>
            <b>{comment.userName}</b> {comment._id}
            <br />
            {d}
          </p>
          <div className='comment-box-reply'>
            {comment.text}
            {comment.userName._id}
          </div>
          <br />
        </div>
        <div className='col-1'>
          <br />
          <br />
          <br />
          <Dropdown>
            <Dropdown.Toggle
              variant='secondary btn-sm'
              id='dropdown-basic'
              style={{ boxShadow: '0 0 0 0rem rgb(130 138 145 / 5' }}
            >
              <div className='dots2'>
                <div className='dot2'></div>
              </div>
            </Dropdown.Toggle>

            <Dropdown.Menu style={{ backgroundColor: '#73a47' }}>
              <Dropdown.Item
                onClick={deleteCommentPost(item._id, comment.commentId)}
              >
                Delete Comment
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    );
  };

  const CommentForm = ({ item }) => {
    var d = new Intl.DateTimeFormat('hi-IN', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      // eslint-disable-next-line no-undef
    }).format(today);

    return (
      <div className='container'>
        <div className='row'>
          <div className='col-1'></div>
          <div className='col-8'>
            <p>
              <b>{item.userName}</b> {item.class}
              <br />
              {d}
            </p>
            <div className='comment-box'>{item.text}</div>
          </div>
          <div className='col-1'>
            <br />
            <br />
            <br />

            <Dropdown>
              <Dropdown.Toggle
                variant='secondary btn-sm'
                id='dropdown-basic'
                style={{ boxShadow: '0 0 0 0rem rgb(130 138 145 / 5' }}
              >
                <div className='dots2'>
                  <div className='dot2'></div>
                </div>
              </Dropdown.Toggle>

              <Dropdown.Menu style={{ backgroundColor: '#73a47' }}>
                <Dropdown.Item onClick={deletePost(item._id)}>
                  Delete Post
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
        <br />
        {item.comments &&
          item.comments.length > 0 &&
          _.map(item.comments, (comment, index) => (
            <CommentView comment={comment} item={item} />
          ))}

        <div className='row'>
          <div className='col-1'></div>
          <div className='col-8'>
            <div className='comment-box-input'>
              <textarea
                id={item._id}
                className='comment-box-input-textbox'
                type='textbox'
                placeholder='Post your comment or answer'
                style={{ padding: '2px 5px' }}
              ></textarea>
              <br />
              <br />
            </div>
          </div>
          <div className='col-1' style={{ marginTop: '26px' }}>
            <a
              id={item._id + '-btn'}
              href='##'
              className=''
              onClick={postComment(item)}
            >
              <img src={post} style={{ width: '32px' }} alt='dfdf' />
            </a>
          </div>
        </div>
        <br />
      </div>
    );
  };

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      /*marginRight: '-50%',*/
      width: '80%',
      transform: 'translate(-50%, -50%)',
      padding: '30px 120px',
      maxHeight: '100%',
      overflowY: 'auto',
    },
  };

  let subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    subtitle.style.color = '#f00';
  }

  function closeModal() {
    setIsOpen(false);
  }

  function alertMsg() {
    confirmAlert({
      title: 'Confirm to submit',
      message: 'Are you sure to do this.',
      buttons: [
        { label: 'Yes', onClick: () => alert('Click Yes') },
        { label: 'No', onClick: () => alert('Click No') },
      ],
    });
  }

  if (!localStorage.getItem('is_done')) {
    return <Redirect to={'/login'} />;
  }

  return (
    <div>
      <Header />
      <div className='bg-grey min-height'>
        <div className='container-fluid p-10'>
          <div className='row' style={{ padding: 20 }}>
            <div className='col-2 left-menu'>
              <br />
              <LeftMenu />
            </div>

            <div className='col-9 right-menu'>
              <div className='row'>
                <div className='col md-8'>
                  <h2 className='tet'>
                    This is your digital <strong>School Diary.</strong> You can
                    use this to send messages, notices and homework to students.
                    You can also communicate with the teachers using Neeve
                    Portal. If you send a message or document to students, they
                    will receive it on their own Neeve app.
                  </h2>
                </div>
                <div className='col md-4'>
                  <img src={imgp} alt='' className='imgggg' />
                </div>
              </div>
              <NewPost />

              {forumData.length > 0 ? (
                _.map(forumData, (item, index) => (
                  <CommentForm item={item} key={index} />
                ))
              ) : (
                <h2 className='text-center'>
                  <br />
                  <br />
                  Welcome to School Connect. <br />
                  Here you can post school and student notices, teacher
                  communication and also reply to student queries. <br />
                  Share your first post now by clicking the CREATE POST button
                  above
                  <br />
                  <br />
                </h2>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function postComment(dt) {
  return function () {
    var data = {
      _id: dt._id,
      userId: {
        _id: localStorage.getItem('udise_id'),
      },
      userName: localStorage.getItem('school_name'),
      text: document.getElementById(dt._id).value,
    };
    if (document.getElementById(dt._id).value) {
      submitCommentPostData(data).then((res) => {
        if (res) {
          window.location.href = '/forum';
        }
      });
    }
  };
}

function deletePost(id) {
  return function () {
    var data = {
      _id: id,
    };

    if (id) {
      confirmAlert({
        title: 'Confirm to Delete',
        message: 'Are you sure you want to delete?',
        buttons: [
          {
            label: 'Yes',
            onClick: () =>
              deletePostData(data).then((res) => {
                if (res) {
                  window.location.href = '/forum';
                }
              }),
          },
          {
            label: 'No',
            onClick: () => console.log('Click No'),
          },
        ],
      });
    }
  };
}

function deleteCommentPost(postid, commentid) {
  return function () {
    var data = {
      _id: postid,
      commentId: commentid,
    };

    if (postid && commentid) {
      confirmAlert({
        title: 'Confirm to Delete',
        message: 'Are you sure you want to delete?',
        buttons: [
          {
            label: 'Yes',
            onClick: () =>
              deleteCommentPostData(data).then((res) => {
                if (res) {
                  window.location.href = '/forum';
                }
              }),
          },
          {
            label: 'No',
            onClick: () => console.log('Click No'),
          },
        ],
      });
    }
  };
}
export default Resource;
