import {
  faArrowCircleDown,
  faArrowLeft,
  faList,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import { jsPDF } from 'jspdf';
import * as _ from 'lodash';
import * as moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import { Redirect, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import calender from './../../assets/calendar.svg';
import { api_url } from './../../resources/url';
import { DataPrepear } from './../performance-class';
import StudentHeader from './../StudentHeader';

function Resource() {
  // eslint-disable-next-line no-unused-vars
  const [schoolid, setschoolid] = useState('');
  const { udescId } = useParams();
  const [school, setSchool] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const calenderInput = useRef(null);
  const handleCalenderClick = (e) => {
    const datepickerElement = calenderInput.current;
    datepickerElement.setFocus(true);
  };
  useEffect(() => {
    let udise_id = udescId ? udescId : localStorage.getItem('udise_id');
    let Params = `/user/school/performance?schoolId=` + udise_id;

    axios.get(api_url + Params).then(
      (res) => {
        if (res) {
          setSchool(res.data.data);
          console.log(res.data.data.date);
          if (res.data.data.date) {
            const parts = res.data.data.date.split('-');
            if (parts && parts.length > 2) {
              const date = new Date(parts[2], parts[1] - 1, parts[0]);
              setStartDate(date);
            }
          }
        }
      },
      (error) => {}
    );
  }, [udescId]);

  useEffect(() => {
    let udise_id = udescId ? udescId : localStorage.getItem('udise_id');
    let Params = `/user/school/performance?schoolId=` + udise_id;
    if (startDate) {
      Params = Params + `${'&date=' + moment(startDate).format('DD-MM-YYYY')}`;
    }

    axios.get(api_url + Params).then(
      (res) => {
        if (res) {
          setSchool(res.data.data);
          console.log(res.data.data.date);
        }
      },
      (error) => {}
    );
  }, [schoolid, startDate, udescId]);

  const pdfDownload = (e) => {
    e.preventDefault();

    let subheader = document.getElementById('divsh');
    let footer = document.getElementById('divft');
    let date = document.getElementById('test-date');
    subheader.style.display = 'none';
    footer.style.display = 'none';
    date.innerHTML =
      '<span>Date of Report - ' +
      moment(startDate).format('DD-MM-YYYY') +
      ' </span>';
    let doc = new jsPDF('landscape', 'pt', 'A4');

    let main = document.getElementById('tblStudPer');
    let hdrfFrm = document.getElementById('hdrfFrm');

    var div = document.createElement('div');
    div.setAttribute('id', 'newDivId');
    // div.setAttribute("class", "newDiv")
    div.style.width = '800px';

    div.appendChild(hdrfFrm);
    div.appendChild(main);

    doc.html(div, {
      callback: () => {
        doc.save('performance-school.pdf');
        window.location.reload(false);
      },
    });
  };

  const HeaderForm = () => {
    return (
      <div
        id='hdrfFrm'
        className='row right-menu'
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div className='col md-5'>
          <h5 style={{ marginLeft: 5 }}>
            {udescId ? school.school_name : localStorage.getItem('school_name')}
          </h5>
        </div>
        <div
          className='col md-3 rightborderdiv vl2'
          style={{ fontWeight: 'bold' }}
        >
          <span style={{ fontSize: 13 }}>
            No. of Students - {school.no_student}
          </span>
          <br />
          <span style={{ fontSize: 13, display: 'flex' }} id='test-date'>
            <span style={{ width: 130 }}>Date of Report -</span>
            <span style={{ display: 'flex' }}>
              <DatePicker
                ref={calenderInput}
                dateFormat='dd-MM-yyyy'
                selected={startDate}
                minDate={new Date('01/01/2022')}
                maxDate={new Date()}
                onChange={(date) => setStartDate(date)}
              />
              <img
                alt='fdfd'
                onClick={handleCalenderClick}
                src={calender}
                style={{
                  marginLeft: 10,
                  width: 22,
                  height: 24,
                  cursor: 'pointer',
                }}
              />
            </span>
          </span>
        </div>
        <div className='col md-4 vl' style={{ width: '100%' }}>
          <span
            className='boldspan'
            style={{
              display: 'flex',
              justifyContent: 'left',
              alignItems: 'left',
              paddingLeft: 10,
            }}
          >
            Score Reference Table
          </span>
          <ul>
            <li>
              <span style={{ color: '#f1a253' }}>0-40% Aspiring</span>
            </li>
            <li>
              <span style={{ color: '#ef1b1b' }}>41-80% Scholar</span>
            </li>
            <li>
              <span style={{ color: '#82a1e0' }}>81-100% Master</span>
            </li>
          </ul>
        </div>
      </div>
    );
  };

  const SubHeaderForm = () => {
    const state = localStorage.getItem('state');
    console.log(state);
    const district = localStorage.getItem('district');
    console.log(district);
    return (
      <div
        id='divsh'
        className='row right-menu-nobg'
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div className='col md-6'>
          <button className='btn btn-sm btn-primary me-2'>
            List View <FontAwesomeIcon icon={faList} />{' '}
          </button>

          {/* <span style={{ color: "#499b47" }}>List View</span>{" "}
          <img
            src={noun_list_logo}
            style={{ width: "25px", alignItems: "right" }}
          />
          <span style={{ color: '#000', marginLeft: 15, marginRight: 5 }}>Graph View</span><img src={graph_logo} style={{ width: "22px", alignItems: "right" }} /> */}
        </div>
        <div
          className='col md-6'
          style={{
            display: 'flex',
            justifyContent: 'right',
            alignItems: 'right',
          }}
        >
          <Link
            to={`/statedashboardnew/${state}/${district}`}
            className='btn btn-sm btn-primary me-2'
          >
            <FontAwesomeIcon icon={faArrowLeft} /> Back to District Performance{' '}
          </Link>
          <button
            onClick={pdfDownload}
            className='btn btn-sm btn-success text-light'
          >
            <FontAwesomeIcon icon={faArrowCircleDown} /> Download PDF{' '}
          </button>
        </div>
      </div>
    );
  };
  const FooterForm = () => {
    return (
      <div
        id='divft'
        className='row right-menu-nobg'
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div
          className='col md-12'
          style={{
            display: 'flex',
            justifyContent: 'right',
            alignItems: 'right',
          }}
        >
          <button
            onClick={pdfDownload}
            className='btn btn-sm btn-success text-light'
          >
            <FontAwesomeIcon icon={faArrowCircleDown} /> Download PDF{' '}
          </button>
        </div>
      </div>
    );
  };

  if (!localStorage.getItem('is_done')) {
    return <Redirect to={'/login'} />;
  }

  return (
    <div>
      <div className=''>
        <div className='container-fluid p-10'>
          <div className='row'>
            <div className='col-12' style={{ height: '5%' }}>
              <StudentHeader
                level={'Skill Analytics Report - School Level'}
                desc='This report shows aggregate subject and skill wise performance at school level.'
              />
              <HeaderForm />
              <SubHeaderForm />

              <div id='tblStudPer'>
                <div
                  className='row'
                  style={{
                    backgroundColor: '#fff',
                    marginLeft: 10,
                    marginRight: 10,
                    marginTop: 10,
                  }}
                >
                  <table style={{ width: '100%', marginBottom: 5 }}>
                    <tr>
                      <th style={{ width: '40%' }}>SCIENCE</th>
                      <th style={{ width: '20%', textAlign: 'center' }}>
                        <span className='aspiring'>ASPIRING</span>
                      </th>
                      <th style={{ width: '20%', textAlign: 'center' }}>
                        <span className='scholar'>SCHOLAR</span>
                      </th>
                      <th style={{ width: '20%', textAlign: 'center' }}>
                        <span className='master'>MASTER</span>
                      </th>
                    </tr>
                    {school &&
                      _.map(school.science?.order, (key) => (
                        <>
                          <DataPrepear title={key} data={school.science[key]} />
                        </>
                      ))}
                  </table>
                </div>

                <div
                  className='row'
                  style={{
                    backgroundColor: '#fff',
                    marginLeft: 10,
                    marginRight: 10,
                    marginTop: 10,
                  }}
                >
                  <table style={{ width: '100%', marginBottom: 5 }}>
                    <tr>
                      <th style={{ width: '40%' }}>MATHS</th>
                      <th style={{ width: '20%', textAlign: 'center' }}>
                        <span className='aspiring'>ASPIRING</span>
                      </th>
                      <th style={{ width: '20%', textAlign: 'center' }}>
                        <span className='scholar'>SCHOLAR</span>
                      </th>
                      <th style={{ width: '20%', textAlign: 'center' }}>
                        <span className='master'>MASTER</span>
                      </th>
                    </tr>
                    {school &&
                      _.map(school.maths?.order, (key) => (
                        <>
                          <DataPrepear title={key} data={school.maths[key]} />
                        </>
                      ))}
                  </table>
                </div>

                <div
                  className='row'
                  style={{
                    backgroundColor: '#fff',
                    marginLeft: 10,
                    marginRight: 10,
                    marginTop: 10,
                  }}
                >
                  <table style={{ width: '100%', marginBottom: 5 }}>
                    <tr>
                      <th style={{ width: '40%' }}>ENGLISH</th>
                      <th style={{ width: '20%', textAlign: 'center' }}>
                        <span className='aspiring'>ASPIRING</span>
                      </th>
                      <th style={{ width: '20%', textAlign: 'center' }}>
                        <span className='scholar'>SCHOLAR</span>
                      </th>
                      <th style={{ width: '20%', textAlign: 'center' }}>
                        <span className='master'>MASTER</span>
                      </th>
                    </tr>
                    {school &&
                      _.map(school.english?.order, (key) => (
                        <>
                          <DataPrepear title={key} data={school.english[key]} />
                        </>
                      ))}
                  </table>
                </div>
              </div>

              <FooterForm />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Resource;
