import React from 'react';
import trending_learning_photo from './../../assets/trending_learning_photo.jpg';

const TrendingCourses = () => {
  return (
    <div>
      <br />
      <div className='trending__learning__container' style={{ width: '85%' }}>
        <h4>Trending – Learning Recovery to Learning Enhancement</h4>
        <div className='trending__learning__sub__container'>
          <div className='trending__learning__sub__container1'>
            <img
              className='trending__learning__img'
              src={trending_learning_photo}
              alt=''
            />
          </div>
          <div className='trending__learning__sub__container2'>
            <p>4 Modules</p>
            <p>4 hrs 30 min</p>
            <p>online, interactive</p>
            <p>certificates</p>
          </div>
          <div className='trending__learning__sub__container3'>
            <div className='trending__learning__circle'>
              <div>
                <h1>4</h1>
                <p>completed</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrendingCourses;
