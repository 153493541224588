import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  LabelList,
  Legend,
  Pie,
  PieChart,
  Sector,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { getReadinessScores } from '../services/api';
import LoaderSpinner from './LoaderSpinner';
import NotFoundDialog from './NotFoundDialog';
import './styles/SchoolModal.css';

const tempDataForEndline = {
  aspiring: 1,
  scholar: 1,
  master: 1,
};

const barChartColors = [
  ['#c0e3fb', '#0390f2', '#004677'],
  ['#fcdcdc', '#f47979', '#750b0b'],
  ['#ffddaf', '#ffa834', '#f38d00'],
];
const pieChartColors = [
  ['#0390f2', '#0073c2', '#004677'],
  ['#f47979', '#a82a2a', '#760c0c'],
  ['#ffc371', '#ffa934', '#f38d00'],
];

const SchoolModal = ({ closeModal, data }) => {
  const [readinessScoresData, setReadinessScoresData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const checkEndline = (data) => {
    if (data.aspiring || data.scholar || data.master) {
      return true;
    } else {
      return false;
    }
  };
  useEffect(() => {
    const u_code = localStorage.getItem('m_auth_token');
    const payload = {
      udiseId: `${data.udiseId}`,
    };
    const config = {
      headers: {
        Authorization: `${u_code}`,
      },
    };
    setIsLoading(true);
    getReadinessScores(payload, config).then((res) => {
      setReadinessScoresData(res.data.data);
      setIsLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal
      show={true}
      onHide={closeModal}
      dialogClassName='border-radius-2'
      style={{ width: '80%' }}
    >
      <Modal.Header
        closeButton
        style={{ height: '55px', backgroundColor: '#27963c', color: '#fff' }}
      >
        <Modal.Title id='contained-modal-title-md'>
          School Name: {data.schoolName}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{ height: window.innerHeight - 150, overflow: 'auto' }}
      >
        {!isLoading && readinessScoresData.length > 0 ? (
          <>
            <div className='bar-pie-text'>Baseline - Endline Comparison</div>
            <div className='barchart-container'>
              <BarChartNew
                data={processBarChartData(readinessScoresData[0])}
                name={'English'}
                colors={barChartColors[0]}
              />
              <BarChartNew
                data={processBarChartData(readinessScoresData[1])}
                name={'Math'}
                colors={barChartColors[1]}
              />
              <BarChartNew
                data={processBarChartData(readinessScoresData[2])}
                name={'Science'}
                colors={barChartColors[2]}
              />
            </div>
            <div className='bar-pie-text' style={{ marginTop: '20px' }}>
              Baseline -Endline Result - English
            </div>
            <div className='chartCoTwo'>
              <MyPieChart
                heading='Baseline Assessment'
                data={processPieChartData(readinessScoresData[0].baseline)}
                COLORS={pieChartColors[0]}
              />
              <MyPieChart
                heading={'Endline Assessment'}
                data={processPieChartData(
                  checkEndline(readinessScoresData[0].endline)
                    ? readinessScoresData[0].endline
                    : tempDataForEndline
                )}
                COLORS={
                  checkEndline(readinessScoresData[0].endline)
                    ? pieChartColors[0]
                    : ['#dfdfdf', '#c6c6c7', '#bfbfbe']
                }
              />
            </div>
            <div className='bar-pie-text' style={{ marginTop: '20px' }}>
              Baseline -Endline Result - Math
            </div>
            <div className='chartCoTwo'>
              <MyPieChart
                heading='Baseline Assessment'
                data={processPieChartData(readinessScoresData[1].baseline)}
                COLORS={pieChartColors[1]}
              />
              <MyPieChart
                heading={'Endline Assessment'}
                data={processPieChartData(
                  checkEndline(readinessScoresData[1].endline)
                    ? readinessScoresData[1].endline
                    : tempDataForEndline
                )}
                COLORS={
                  checkEndline(readinessScoresData[1].endline)
                    ? pieChartColors[1]
                    : ['#dfdfdf', '#c6c6c7', '#bfbfbe']
                }
              />
            </div>
            <div className='bar-pie-text' style={{ marginTop: '20px' }}>
              Baseline -Endline Result - Science
            </div>
            <div className='chartCoTwo'>
              <MyPieChart
                heading='Baseline Assessment'
                data={processPieChartData(readinessScoresData[2].baseline)}
                COLORS={pieChartColors[2]}
              />
              <MyPieChart
                heading={'Endline Assessment'}
                data={processPieChartData(
                  checkEndline(readinessScoresData[2].endline)
                    ? readinessScoresData[2].endline
                    : tempDataForEndline
                )}
                COLORS={
                  checkEndline(readinessScoresData[2].endline)
                    ? pieChartColors[2]
                    : ['#dfdfdf', '#c6c6c7', '#bfbfbe']
                }
              />
            </div>{' '}
          </>
        ) : isLoading ? (
          <LoaderSpinner />
        ) : (
          <NotFoundDialog />
        )}
      </Modal.Body>
    </Modal>
  );
};

export default SchoolModal;

const processBarChartData = (data) => {
  const baselineTotal =
    Number(data.baseline.aspiring) +
    Number(data.baseline.scholar) +
    Number(data.baseline.master);
  const endlineTotal =
    Number(data.endline.aspiring) +
    Number(data.endline.scholar) +
    Number(data.endline.master);

  const result = Object.keys(data.baseline).reduce((acc, key) => {
    const range = {
      aspiring: '0-40',
      scholar: '41-80',
      master: '81-100',
    };

    const baselineValue = Number(data.baseline[key]);
    const endlineValue = Number(data.endline[key]);

    const obj = {
      name: range[key],
      Baseline: Math.floor((baselineValue / baselineTotal) * 100),
      End_line: endlineTotal
        ? Math.floor((endlineValue / endlineTotal) * 100)
        : 0,
    };

    acc.push(obj);
    return acc;
  }, []);
  return result;
};

const BarChartNew = ({ data, name, colors }) => {
  const renderCustomizedLabel = (props) => {
    const { x, y, width, value } = props;
    return (
      <text
        x={x + width / 2}
        y={y + 15}
        fill='#fff'
        textAnchor='middle'
        dominantBaseline='middle'
        fontSize={10}
      >
        {`${value ? value : 0}%`}
      </text>
    );
  };
  return (
    <div className='bar__chart'>
      <div className='bar__chart__top' style={{ backgroundColor: colors[0] }}>
        <div className='bar-pie-text'>{name}</div>
      </div>
      <div>
        <BarChart
          width={280}
          height={230}
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: -15,
            bottom: 5,
          }}
          barGap={5000}
          barSize={30}
        >
          <Tooltip />

          <CartesianGrid strokeDasharray='3 3' />
          <XAxis dataKey='name' height={20} fontSize={10} />
          <YAxis
            type='number'
            domain={[0, 100]}
            ticks={[0, 20, 40, 60, 80, 100]}
          />
          {/* <Tooltip /> */}
          <Bar
            dataKey='Baseline'
            fill={colors[1]}
            minPointSize={10}
            radius={[50, 50, 0, 0]}
          >
            radius={[50, 50, 0, 0]}
            <LabelList dataKey={'Baseline'} content={renderCustomizedLabel} />
          </Bar>
          <Bar
            dataKey='End_line'
            fill={colors[2]}
            minPointSize={5}
            width={20}
            radius={[50, 50, 0, 0]}
          >
            <LabelList dataKey='End_line' content={renderCustomizedLabel} />
          </Bar>
        </BarChart>
      </div>
    </div>
  );
};

const MyPieChart = ({ heading, data, COLORS }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const onPieEnter = (_, index) => {
    setActiveIndex(index);
  };
  return (
    <div className='pie__chart__card' onClick={null}>
      <div className='bar-pie-text'>{heading}</div>
      <hr />
      <div className='pie__chart__card__content'>
        <PieChart width={400} height={400}>
          <Pie
            activeIndex={activeIndex}
            activeShape={renderActiveShape}
            data={data}
            cx='50%'
            cy='50%'
            outerRadius={120}
            fill='#8884d8'
            dataKey='value'
            onMouseEnter={onPieEnter}
            onClick={null}
            onPieClick={(index) => {
              console.log('onPieClick' + index.value);
            }}
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>
          <Legend />
        </PieChart>
      </div>
    </div>
  );
};
const processPieChartData = (data) => {
  const tempData = {
    aspiring: Number(data.aspiring),
    scholar: Number(data.scholar),
    master: Number(data.master),
  };
  const result = Object.entries(tempData).reduce((acc, [category, value]) => {
    acc.push({ name: category, value });
    return acc;
  }, []);
  return result;
};

const renderActiveShape = (props) => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    value,
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 15) * cos;
  const my = cy + (outerRadius + 15) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 29;
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';

  return (
    <g
      strokeWidth='2'
      onClick={() => {
        console.log('first');
      }}
    >
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path
        d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
        stroke={fill}
        fill='none'
      />
      <circle cx={ex} cy={ey} r={6} fill={fill} stroke='none' />
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        textAnchor={textAnchor}
        fill='#333'
      >
        {value}
      </text>
    </g>
  );
};
