const API_URL = process.env.REACT_APP_API_URL;
const REPORTING_API_URL = process.env.REACT_APP_REPORTING_API_URL;
const TRAINING_URL = process.env.REACT_APP_TRAINING_URL;

export const api_url = API_URL;

export const api_url_new = `${REPORTING_API_URL}/api/survey/`;

export const monitoring_auth_url = `${REPORTING_API_URL}/api/neeve/auth`;

export const training_url = TRAINING_URL;
