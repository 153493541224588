import axios from 'axios';
import React from 'react';
import { Redirect } from 'react-router';
import Select from 'react-select';
import bgimage from './../assets/bg-image.png';
import './../custom.css';
import * as mod from './../resources/url';
import Header from './header-signup';
// eslint-disable-next-line no-unused-vars

function Signup() {
  if (localStorage.getItem('is_done')) {
    return <Redirect to={'/resource/student-readiness'} />;
  }
  return (
    <div>
      <Header />
      <div className='singnupbg' style={{ backgroundImage: `url(${bgimage})` }}>
        <br />
        <br />
        <div className='container'>
          <div className='row text-center'>
            <div className='col-0 col-md-2'></div>

            <div className='col-0 col-md-8 formDiv'>
              <h2>SignUp</h2>
              <br />
              <NameForm />
            </div>

            <div className='col-0 col-md-2'></div>
          </div>
        </div>
        <br />
      </div>
    </div>
  );
}

class NameForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = { value: '' };
    this.state = { schooldata: [] };
    this.state = { main_schooldata: [] };
    this.state = { schoolname: '', schooltype: '', schoolstate: [] };
    this.state = {
      statename: '',
      selected_udise: '',
      udise_placeholder: 'Select UDISE Code',
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleChangeSchoolType = this.handleChangeSchoolType.bind(this);
    this.handleChangeSchoolState = this.handleChangeSchoolState.bind(this);
    this.handleChangeSelectSchool = this.handleChangeSelectSchool.bind(this);
    this.handleChangeSchoolname = this.handleChangeSchoolname.bind(this);
    this.handleChangeEmail = this.handleChangeEmail.bind(this);
    this.handleChangeSchoolphoneno = this.handleChangeSchoolphoneno.bind(this);
    this.handleChangePassword = this.handleChangePassword.bind(this);
    this.handleChangeRetypepassword =
      this.handleChangeRetypepassword.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    this.setState({ udise: event.target.value });
  }

  handleChangeSchoolType(event) {
    this.setState({ schooltype: event.target.value });
    var url = mod.api_url + '/user/udises';
    this.setState({ schoolname: '' });

    if (event.target.value === 'private') {
      this.setState({ udise_placeholder: 'School Neeve Id' });
      document.getElementById('select_state').style.display = 'none';
      url += '?type=Private';

      fetch(url)
        .then((res) => res.json())
        .then((json) => this.setState({ schooldata: json }));
    } else {
      this.setState({ udise_placeholder: 'Select UDISE Id' });

      document.getElementById('select_state').style.display = 'block';
      Promise.all([
        fetch(mod.api_url + '/user/udises'),
        fetch(mod.api_url + '/user/states'),
      ])

        .then(([res1, res2]) => {
          return Promise.all([res1.json(), res2.json()]);
        })
        .then(([res1, res2]) => {
          // set state in here
          this.setState({ schooldata: res1, schoolstate: res2 });
        });

      fetch(mod.api_url + `/user/states?&type=` + this.state.schooltype)
        .then((res) => res.json())
        .then((json) => this.setState({ schoolstate: json }));
    }
  }

  handleChangeSchoolState(event) {
    var state_name = this.state.schoolstate.data[event.value - 1].state;
    this.setState({ statename: state_name });
    this.setState({ value: '' });
    fetch(mod.api_url + `/user/udises?state=` + state_name)
      .then((res) => res.json())
      .then((json) => this.setState({ schooldata: json }));
  }

  handleChangeSelectSchool(event) {
    this.setState({ udise: event.value });
    var udise_id = event.value - 1;
    this.setState({
      schoolname: this.state.schooldata.data[udise_id].school_name,
    });
    this.setState({ udiseid: this.state.schooldata.data[udise_id].UDISE_code });
    this.selected_udise = event.value;
  }

  handleChangeSchoolname(event) {
    this.setState({ schoolname: event.target.value });
  }

  handleChangeEmail(event) {
    this.setState({ schoolemail: event.target.value });
  }

  handleChangeSchoolphoneno(event) {
    this.setState({ schoolphoneno: event.target.value });
  }

  handleChangePassword(event) {
    this.setState({ password: event.target.value });
  }

  handleChangeRetypepassword(event) {
    this.setState({ retypepassword: event.target.value });
  }

  handleSubmit(event) {
    event.preventDefault();
    var udise_ok = 0;
    var schooltype_ok = 0;
    var schoolemail_ok = 0;
    var schoolphoneno_ok = 0;
    var password_ok = 0;
    var retypepassword_ok = 0;
    if (this.state.udise && this.state.udise !== '') {
      document.getElementById('udise-error-1').style.display = 'none';
      udise_ok = 1;
    } else {
      document.getElementById('udise-error-1').style.display = 'block';
    }

    if (this.state.schooltype && this.state.schooltype !== '') {
      // eslint-disable-next-line no-unused-vars
      schooltype_ok = 1;
    } else {
    }

    if (this.state.statename && this.state.statename !== '') {
      console.log('statename Defined');
    } else {
      //document.getElementById("statename-error-1").style.display = 'block';
    }

    if (this.state.schoolname && this.state.schoolname !== '') {
      console.log('schoolname Defined');
    } else {
      //document.getElementById("udise-error-1").style.display = 'block';
    }

    if (this.state.schoolemail && this.state.schoolemail !== '') {
      console.log('schoolemail Defined');
      document.getElementById('email-error-1').style.display = 'none';
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );

      if (!pattern.test(this.state.schoolemail)) {
        document.getElementById('email-error-1').style.display = 'none';
        document.getElementById('email-error-2').style.display = 'block';
      } else {
        document.getElementById('email-error-2').style.display = 'none';
        schoolemail_ok = 1;
      }
    } else {
      document.getElementById('email-error-2').style.display = 'none';
      document.getElementById('email-error-1').style.display = 'block';
    }

    if (this.state.schoolphoneno && this.state.schoolphoneno !== '') {
      console.log('schoolphoneno Defined');
      if (this.state.schoolphoneno.length === 10) {
        document.getElementById('phone-error-1').style.display = 'none';
        schoolphoneno_ok = 1;
      } else {
        document.getElementById('phone-error-1').style.display = 'block';
      }
    } else {
      document.getElementById('phone-error-1').style.display = 'block';
    }

    if (this.state.password && this.state.password !== '') {
      document.getElementById('password-error-1').style.display = 'none';
      if (
        this.state.password.length >=
        6 /*&& /[a-z]/.test(this.state.password) && /[A-Z]/.test(this.state.password) */
      ) {
        document.getElementById('password-error-1').style.display = 'none';
        document.getElementById('password-error-2').style.display = 'none';
        password_ok = 1;
      } else {
        document.getElementById('password-error-1').style.display = 'none';
        document.getElementById('password-error-2').style.display = 'block';
      }
    } else {
      document.getElementById('password-error-1').style.display = 'block';
    }

    if (this.state.retypepassword && this.state.retypepassword !== '') {
      console.log('retypepassword Defined');
      document.getElementById('retypepassword-error-1').style.display = 'none';
      document.getElementById('retypepassword-error-2').style.display = 'none';

      if (this.state.password === this.state.retypepassword) {
        document.getElementById('retypepassword-error-2').style.display =
          'none';
        retypepassword_ok = 1;
      } else {
        document.getElementById('retypepassword-error-2').style.display =
          'block';
      }
    } else {
      document.getElementById('retypepassword-error-1').style.display = 'block';
    }

    console.log('udise_ok: ' + udise_ok);
    console.log('schoolemail_ok: ' + schoolemail_ok);
    console.log('schoolphoneno_ok: ' + schoolphoneno_ok);
    console.log('password_ok: ' + password_ok);
    console.log('retypepassword_ok: ' + retypepassword_ok);
    if (
      udise_ok === 1 &&
      schoolemail_ok === 1 &&
      schoolphoneno_ok === 1 &&
      password_ok === 1 &&
      retypepassword_ok === 1
    ) {
      console.log('All OK');
      var apiBaseUrl = mod.api_url + '/user/web/signup';

      var data = {
        udiseId: this.state.udiseid,
        phoneNumber: this.state.schoolphoneno,
        emailId: this.state.schoolemail,
        password: this.state.password,
        schoolType: this.state.schooltype,
        stateName: this.state.statename,
      };

      axios
        .post(apiBaseUrl, data)
        .then(function (response) {
          console.log(response);

          if (response.status === 200) {
            localStorage.setItem(
              'u_code',
              encodeURIComponent(JSON.stringify(response.data.data.accessToken))
            );

            localStorage.setItem(
              'udise_id',
              encodeURIComponent(JSON.stringify(response.data.data.id))
            );
            localStorage.setItem('school_name', response.data.data.school_name);
            localStorage.setItem('state', response.data.data.state);

            localStorage.setItem('temp_pass', data.password);

            window.location.href = `/licence_key_verificaton?udise=${data.udiseId}`;
          } else {
            alert(response.data.message);
          }
        })
        .catch(function (error) {
          console.log(error);
          alert(
            'This udise id has already been registered. Login to your account or use a separate udise id to sign up.'
          );
        });
    }
  }

  componentDidMount() {
    Promise.all([
      fetch(mod.api_url + '/user/udises'),
      fetch(mod.api_url + '/user/states'),
    ])

      .then(([res1, res2]) => {
        return Promise.all([res1.json(), res2.json()]);
      })
      .then(([res1, res2]) => {
        // set state in here
        this.setState({ schooldata: res1, schoolstate: res2 });
      });
  }

  render() {
    var udiserow = [];
    var staterow = [];
    if (!this.state.schooldata) {
      return null;
    } else {
      for (var i = 0; i < this.state.schooldata.data.length; i++) {
        udiserow.push({
          value: i + 1,
          label: this.state.schooldata.data[i].UDISE_code,
        });
      }
    }

    if (!this.state.schoolstate) {
      return null;
    } else {
      for (let i = 0; i < this.state.schoolstate.data.length; i++) {
        staterow.push({
          value: i + 1,
          label: this.state.schoolstate.data[i].state,
        });
      }
    }

    return (
      <form onSubmit={this.handleSubmit}>
        <div className='row'>
          <div className='col text-right'>
            <input
              type='radio'
              className='form-radio'
              name='schoolType'
              value='state'
              onChange={this.handleChangeSchoolType}
              defaultChecked
            />
            <span style={{ marginLeft: '10px', marginRight: '14px' }}>
              <b>State School</b>
            </span>
          </div>
          <div className='col'>
            <input
              type='radio'
              className='form-radio'
              name='schoolType'
              value='private'
              onChange={this.handleChangeSchoolType}
            />
            <span style={{ marginLeft: '10px' }}>
              <b>Private School</b>
            </span>
          </div>
        </div>

        <Select
          id='select_state'
          name='state'
          className='input-text-box select-box-udise'
          options={staterow}
          onChange={this.handleChangeSchoolState}
          placeholder='Select State'
        />
        <h6 id='statename-error-1' className='error-msg'>
          <span>🠕</span> Please fill the State Name
        </h6>

        <Select
          id='udise_code'
          name='udise_code'
          className='input-text-box select-box-udise'
          options={udiserow}
          onChange={this.handleChangeSelectSchool}
          placeholder={this.state.udise_placeholder}
        />
        <h6 id='udise-error-1' className='error-msg'>
          <span>🠕</span> Please fill the UDISE Code
        </h6>

        <input
          id='school_name'
          className='input-text-box'
          type='text'
          name='schoolname'
          onChange={this.handleChangeSchoolname}
          placeholder='School Name'
          value={this.state.schoolname}
          disabled
        />
        <input
          className='input-text-box'
          type='text'
          name='schoolemail'
          onChange={this.handleChangeEmail}
          placeholder='Email'
        />
        <h6 id='email-error-1' className='error-msg'>
          <span>🠕</span> Please enter a valid email id
        </h6>
        <h6 id='email-error-2' className='error-msg'>
          <span>🠕</span> Please enter a valid email id
        </h6>

        <input
          className='input-text-box'
          type='text'
          name='schoolphoneno'
          onChange={this.handleChangeSchoolphoneno}
          placeholder='Mobile / Landline no'
          onKeyPress={(event) => {
            if (!/[0-9]/.test(event.key)) {
              event.preventDefault();
            }
          }}
        />
        <h6 id='phone-error-1' className='error-msg'>
          <span>🠕</span> Phone number should consist of only numbers or digits
        </h6>

        <input
          className='input-text-box'
          type='password'
          name='password'
          onChange={this.handleChangePassword}
          placeholder='Set Password'
        />
        <p className='error-msg' style={{ display: 'block' }}>
          Minimum 6 characters
        </p>

        <h6 id='password-error-1' className='error-msg'>
          <span>🠕</span> Please fill in the password
        </h6>
        <h6 id='password-error-2' className='error-msg'>
          <span>🠕</span> Please follow the password creation rules
        </h6>
        <h6 id='password-error-3' className='error-msg'>
          <span>🠕</span> Please fill in the password
        </h6>

        <input
          className='input-text-box'
          type='password'
          name='retypepassword'
          onChange={this.handleChangeRetypepassword}
          placeholder='Retype Password'
        />
        <h6 id='retypepassword-error-1' className='error-msg'>
          <span>🠕</span> Passwords do not match
        </h6>

        <h6 id='retypepassword-error-2' className='error-msg'>
          <span>🠕</span> Passwords do not match
        </h6>

        <br />
        <input
          className='btn header-nav-btn-primary'
          type='submit'
          value='Submit'
        />
      </form>
    );
  }
}
export default Signup;
