import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

export const exportPDF = (id, fileName) => {
  const input = document.getElementById(id);
  const button = document.getElementById('downloadButton');
  button.style.display = 'none';

  html2canvas(input, { logging: true, letterRendering: 2, useCORS: true }).then(
    (canvas) => {
      var imgData = canvas.toDataURL('image/png');
      var imgWidth = 210;
      var pageHeight = 295;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      var heightLeft = imgHeight;
      var doc = new jsPDF('p', 'mm');
      var position = 0;
      doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;
      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }
      doc.save(fileName);
    }
  );
  button.style.display = 'block';
};
