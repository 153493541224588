import React from 'react';
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';
import abhigyaninfo from './../assets/Abhigyan.jpg';
import harshinfo from './../assets/Harsh.jpg';
import administratorinfo from './../assets/administratorinfo.PNG';
import artha from './../assets/artha.png';
import dikshainfo from './../assets/diksha.jpg';
import homebanner from './../assets/home-banner.png';
import schoolicon from './../assets/knowledge@2x.png';
import teachericon from './../assets/lecture@2x.png';
import logicalIndian from './../assets/logical-indian.png';
import spotleAI from './../assets/spotle-ai.png';
import startups from './../assets/startups.png';
import studenticon from './../assets/student-(1)@2x.png';
import studentinfo from './../assets/studentinfo.PNG';
import teacherinfo from './../assets/teacherinfo.PNG';
import thePrint from './../assets/the-print.png';
import usaicon from './../assets/usa@2x.png';
import FreeTrial from './../components/free_trial_form';
import './../custom.css';
import HeaderHome from './header-home';

function Home() {
  const state = localStorage.getItem('state');

  if (localStorage.getItem('role') === 'Admin' && state !== null) {
    return <Redirect to={'/statedashboardnew'} />;
  } else if (
    localStorage.getItem('is_done') &&
    localStorage.getItem('role') !== 'Admin'
  ) {
    return <Redirect to={'/SchoolHomePage'} />;
  }

  return (
    <div>
      <HeaderHome />
      <div
        className='ctn'
        style={{ width: '100%', height: 'auto', position: 'relative' }}
      >
        <img
          className
          src={homebanner}
          style={{ width: '100%', height: 'auto' }}
          alt='imageAlt'
        />
        <Link className='btn ' to='/login'>
          Login{' '}
        </Link>
        <Link className='btn2 ' to='/signup'>
          SignUp{' '}
        </Link>
      </div>
      <div className='container container-the-impact text-center'>
        <div className='row '>
          <div className='col'>
            <h2>The Impact</h2>
            <p>
              Neeve has transformed schools in the remotest parts of India, in
              collaboration with
              <br /> state governments building equal learning opportunities for
              all.
            </p>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-3 '>
            <div className='impact-details'>
              <h5>
                <img
                  className
                  src={usaicon}
                  style={{ width: '50%', height: 'auto' }}
                  alt='imageAlt'
                />
                <br />
                <br />
                4+
                <br />
                STATES
                <br />
              </h5>
            </div>
          </div>

          <div className='col-md-3 '>
            <div className='impact-details'>
              <h5>
                <img
                  className
                  src={schoolicon}
                  style={{ width: '50%', height: 'auto' }}
                  alt='imageAlt'
                />
                <br />
                <br />
                500+
                <br />
                SCHOOLS
                <br />
              </h5>
            </div>
          </div>

          <div className='col-md-3 '>
            <div className='impact-details'>
              <h5>
                <img
                  className
                  src={studenticon}
                  style={{ width: '50%', height: 'auto' }}
                  alt='imageAlt'
                />
                <br />
                <br />
                25000+
                <br />
                STUDENTS
                <br />
              </h5>
            </div>
          </div>

          <div className='col-md-3 '>
            <div className='impact-details'>
              <h5>
                <img
                  className
                  src={teachericon}
                  style={{ width: '50%', height: 'auto' }}
                  alt='imageAlt'
                />
                <br />
                <br />
                5000+
                <br />
                TEACHERS
                <br />
              </h5>
            </div>
          </div>
        </div>
        <br />
        <br />

        <FreeTrial />

        <br />
        <br />

        <div className='row '>
          <div className='col'>
            <h2>
              Grow Your School 10X With Neeve’s Automated,
              <br /> Affordable Learning Platform
            </h2>
            <p>
              Neeve powers new age online learning for students, engages parents
              and simplifies teacher’s tasks all
              <br /> on one connected platform.
            </p>
          </div>
        </div>

        <br />
        <br />

        <div className='row'>
          <div className='col'>
            <iframe
              width='100%'
              height='441'
              src='https://www.youtube.com/embed/hR4OTAUavJw'
              title='YouTube video player'
              border='0px'
              allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </div>

      <div className='container-fluid container-admininstrators'>
        <div className='container '>
          <div className='row'>
            <div className='col'>
              <h2>
                Neeve Schools Is A Win-Win Solution For Teachers, Students,
                <br /> Parents and School Administrators
              </h2>
            </div>
          </div>

          <div className='row'>
            <div className='col'>
              <h2 style={{ color: 'green', fontWeight: '700' }}>
                Starting at ₹ 565+ Per Child
              </h2>
            </div>
          </div>

          <br />
          <br />
          <div className='row'>
            <div className='col-md-4' style={{ textAlign: 'right' }}>
              <img
                className
                src={teacherinfo}
                style={{ width: '90%', height: 'auto' }}
                alt='imageAlt'
              />
            </div>
            <div className='col-md-4' style={{ textAlign: 'center' }}>
              <img
                className
                src={studentinfo}
                style={{ width: '90%', height: 'auto' }}
                alt='imageAlt'
              />
            </div>
            <div className='col-md-4' style={{ textAlign: 'left' }}>
              <img
                className
                src={administratorinfo}
                style={{ width: '90%', height: 'auto' }}
                alt='imageAlt'
              />
            </div>
          </div>

          <br />
          <br />
          <FreeTrial />

          <br />
          <br />
        </div>
      </div>

      <div className='container-fluid container-testimonials'>
        <div className='container '>
          <div className='row'>
            <div className='col'>
              <h2>Testimonials</h2>
              <h6 style={{ padding: '0% 18%' }}>
                Hear from government officers, administrators, teachers and
                students why Neeve is the trusted learning platform for schools
              </h6>
            </div>
          </div>

          <br />
          <br />
          <div className='row'>
            <div className='col-md-4' style={{ textAlign: 'center' }}>
              <div
                className=''
                style={{
                  width: '90%',
                  height: 'auto',
                  backgroundColor: '#f2f2f2',
                  padding: '25px 20px',
                  minHeight: '435px',
                  borderRadius: '10px',
                }}
              >
                <img
                  className
                  src={abhigyaninfo}
                  style={{
                    width: '50%',
                    height: 'auto',
                    padding: '10px',
                    margin: '10px',
                    borderRadius: '50%',
                  }}
                  alt='imageAlt'
                />

                <h5>
                  <b>Abhigyan</b>
                </h5>
                <h6 style={{ color: '#7ed488' }}>
                  <b>Class 7</b>
                </h6>
                <br />

                <p>
                  I love puzzles, projects and stories. I have a lot of fun when
                  I am on Neeve.
                  <br />
                  Is this really studying?
                </p>
              </div>
            </div>
            <div className='col-md-4' style={{ textAlign: 'center' }}>
              <div
                className=''
                style={{
                  width: '90%',
                  height: 'auto',
                  backgroundColor: '#f2f2f2',
                  padding: '25px 20px',
                  minHeight: '435px',
                  borderRadius: '10px',
                }}
              >
                <img
                  className
                  src={harshinfo}
                  style={{
                    width: '50%',
                    height: 'auto',
                    padding: '10px',
                    margin: '10px',
                    borderRadius: '50%',
                  }}
                  alt='imageAlt'
                />

                <h5>
                  <b>Harsh Verdhan Singh</b>
                </h5>
                <h6 style={{ color: '#7ed488' }}>
                  <b>Class 8</b>
                </h6>
                <br />

                <p>
                  I have downloaded the app and my experience was wonderful the
                  all content was from my book only in a systematic manner.
                  Overall the app is best for learning.
                </p>
              </div>
            </div>
            <div className='col-md-4' style={{ textAlign: 'center' }}>
              <div
                className=''
                style={{
                  width: '90%',
                  height: 'auto',
                  backgroundColor: '#f2f2f2',
                  padding: '25px 20px',
                  minHeight: '435px',
                  borderRadius: '10px',
                }}
              >
                <img
                  className
                  src={dikshainfo}
                  style={{
                    width: '50%',
                    height: 'auto',
                    padding: '10px',
                    margin: '10px',
                    borderRadius: '50%',
                  }}
                  alt='imageAlt'
                />

                <h5>
                  <b>Diksha</b>
                </h5>
                <h6 style={{ color: '#7ed488' }}>
                  <b>Class 8</b>
                </h6>
                <br />

                <p>
                  Neeve is very helpful app to learn and study all the concepts.
                  I am enjoying solving quizzes on it
                </p>
              </div>
            </div>
          </div>

          <br />
          <br />
          <br />
        </div>
      </div>

      <div className='container-fluid container-testimonials'>
        <div className='container '>
          <div className='row'>
            <div className='col'>
              <h2>Media & Partners</h2>
              <h6 style={{ padding: '0% 10%' }}>
                The Neeve School Ecosystem is supported by Artha Impact, NASSCOM
                10000 startups, AWE accelerator.
              </h6>
              <h5 style={{ padding: '0% 10%', fontWeight: '550' }}>
                Our impact story has been covered by leading media.
              </h5>
            </div>
          </div>

          <br />
          <br />
          <div className='row'>
            <div className='col-md-4' style={{ textAlign: 'right' }}>
              <img
                className
                src={logicalIndian}
                style={{ width: '50%', height: 'auto' }}
                alt='imageAlt'
              />
            </div>
            <div className='col-md-4' style={{ textAlign: 'center' }}>
              <img
                className
                src={artha}
                style={{ width: '50%', height: 'auto' }}
                alt='imageAlt'
              />
            </div>
            <div className='col-md-4' style={{ textAlign: 'left' }}>
              <img
                className
                src={startups}
                style={{ width: '50%', height: 'auto' }}
                alt='imageAlt'
              />
            </div>
          </div>
          <div className='row'>
            <div className='col-md-2' style={{ textAlign: 'right' }}></div>
            <div className='col-md-4' style={{ textAlign: 'right' }}>
              <img
                className
                src={spotleAI}
                style={{ width: '50%', height: 'auto' }}
                alt='imageAlt'
              />
            </div>

            <div className='col-md-4' style={{ textAlign: 'left' }}>
              <img
                className
                src={thePrint}
                style={{ width: '50%', height: 'auto' }}
                alt='imageAlt'
              />
            </div>
            <div className='col-md-2' style={{ textAlign: 'right' }}></div>
          </div>
          <br />
          <br />
          <br />
        </div>
      </div>
    </div>
  );
}
export default Home;
