import {
  faBook,
  faChalkboardTeacher,
  faChartLine,
  faClipboardList,
  faComment,
  faThLarge,
  faVideo,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useLocation } from 'react-router-dom';
import dashboard_icon from './../assets/dashboard-icon.png';
import schoolIcon from './../assets/schoolIcon.png';

function LeftMenu() {
  const token = localStorage.getItem('m_auth_token');
  const udise_id = localStorage.getItem('user_id');
  const query = `?udise=${udise_id}&auth_token=${token}`;
  const location = useLocation();
  var path_arr = location.pathname.split('/');
  var path_length = path_arr.length;
  var path_name = '';
  var district = localStorage.getItem('district');
  var state = localStorage.getItem('state');

  const openWorkSheet = () => {
    const u_code = localStorage
      .getItem('u_code')
      .replace('"', '')
      .replace('"', '')
      .replace('%22', '')
      .replace('%22', '');
    const user_id = localStorage
      .getItem('user_id')
      .replace('"', '')
      .replace('"', '');
    const url = `https://worksheetwarehouse.neeve.io/?udise_code=${user_id}&auth_token=${u_code}`;
    window.open(url);
  };

  if (path_arr) {
    path_name = path_arr[path_length - 1];
  }

  var statedashboard = (
    <Link className='btn left-menu-btn' to={'/Statedashboard/' + state}>
      <h5>State Performance</h5>
    </Link>
  );

  var districtperformance = (
    <Link
      className='btn left-menu-btn'
      to={'/districtperformance/' + state + '/' + district}
    >
      <h5>District Performance</h5>
    </Link>
  );

  var performance = (
    <Link className='btn left-menu-btn' to='/dashboard'>
      <h5>Performance</h5>
    </Link>
  );
  let schoolHomePage = (
    <Link className='btn left-menu-btn' to='/SchoolHomePage'>
      <h5>School Homepage</h5>
    </Link>
  );
  var teacher_resc = (
    <Link
      className='btn left-menu-subheading btn-lesspad'
      to='/resource/teachers'
    >
      <h5>21st Century Skills </h5>
    </Link>
  );
  var student_resc = (
    <Link
      className='btn left-menu-subheading btn-lesspad'
      to='/resource/student-readiness'
    >
      <h5>Readiness Sprint</h5>
    </Link>
  );
  var qlib_resc = (
    <Link className='btn left-menu-subheading btn-lesspad' to='/resource/qlib'>
      <h5>QLib</h5>
    </Link>
  );
  var lesson_plans_resc = (
    <Link
      className='btn left-menu-subheading btn-lesspad'
      to='/resource/lesson-plans'
    >
      <h5>Lesson Plans</h5>
    </Link>
  );
  var topic_study_packs_resc = (
    <Link
      className='btn left-menu-subheading btn-lesspad'
      to='/resource/topic-study-packs'
    >
      <h5>Topic Study Packs</h5>
    </Link>
  );
  var exam_prep_resc = (
    <Link className='btn left-menu-subheading' to='/resource/exam-prep'>
      <h5>Board Exam Prep</h5>
    </Link>
  );
  var videos_lnk = (
    <Link className='btn left-menu-btn' to='/video'>
      <h5>Videos</h5>
    </Link>
  );
  var worksheet_mkr = (
    <Link className='house btn left-menu-btn' onClick={openWorkSheet} to=''>
      <h5>
        <b>
          <u style={{ color: 'green', textDecorationColor: 'green' }}>
            Worksheet Warehouse
          </u>
        </b>
      </h5>
    </Link>
  );
  var school_cnt = (
    <Link className='btn left-menu-btn' to='/forum'>
      <h5>School Diary</h5>
    </Link>
  );
  let goToMonitoring = (
    <a
      href={`https://monitoring.neeve.io/${query}`}
      role='button'
      className='btn left-menu-btn'
      data-bs-toggle='button'
      target='_blank'
      rel='noreferrer'
    >
      <h5>Go to Monitoring</h5>
    </a>
  );
  let goToTraining = (
    <Link className='btn left-menu-btn' to='/training-home'>
      <h5>Go to Training</h5>
    </Link>
  );

  if (path_name === 'statedashboard') {
    statedashboard = (
      <Link
        className='btn left-menu-btn path-selected'
        //        to="/statedashboard/Sikkim"
        to={'/statedashboard/' + state}
      >
        <h5>State Dashboard</h5>
      </Link>
    );
  } else if (path_name === 'districtperformance') {
    districtperformance = (
      <Link
        className='btn left-menu-btn path-selected'
        to={'/districtperformance/' + state + '/' + district}
      >
        <h5>District Performance</h5>
      </Link>
    );
  } else if (path_name === 'dashboard') {
    // eslint-disable-next-line no-unused-vars
    performance = (
      <Link className='btn left-menu-btn path-selected' to='/dashboard'>
        <h5>Performance</h5>
      </Link>
    );
  } else if (path_name === 'teachers') {
    teacher_resc = (
      <Link
        className='btn left-menu-subheading  btn-lesspad path-selected'
        to='/resource/teachers'
      >
        <h5>21st Century Skills</h5>
      </Link>
    );
  } else if (path_name === 'student-readiness') {
    student_resc = (
      <Link
        className='btn left-menu-subheading btn-lesspad path-selected'
        to='/resource/student-readiness'
      >
        <h5>Readiness Sprint</h5>
      </Link>
    );
  } else if (path_name === 'video') {
    videos_lnk = (
      <Link className='btn left-menu-btn path-selected' to='/video'>
        <h5>Videos</h5>
      </Link>
    );
  } else if (path_name === 'worksheet-maker') {
    worksheet_mkr = (
      <Link className='btn left-menu-btn path-selected' onClick={openWorkSheet}>
        <h5>Worksheet Warehouse</h5>
      </Link>
    );
  } else if (path_name === 'forum') {
    school_cnt = (
      <Link className='btn left-menu-btn path-selected' to='/forum'>
        <h5>School Diary</h5>
      </Link>
    );
  } else if (
    path_name === 'performance-students' ||
    path_name === 'performance-student-individual'
  ) {
    // eslint-disable-next-line no-undef
    perfor_students = (
      <Link
        className='btn left-menu-subheading path-selected'
        to='/performance-students'
      >
        <h5>Students</h5>
      </Link>
    );
  } else if (path_name === 'performance-school') {
    // eslint-disable-next-line no-undef
    perfor_school = (
      <Link
        className='btn left-menu-subheading path-selected'
        to='/performance-school'
      >
        <h5>School</h5>
      </Link>
    );
  } else if (path_name === 'performance-class') {
    // eslint-disable-next-line no-undef
    perfor_class = (
      <Link
        className='btn left-menu-subheading btn-lesspad path-selected'
        to='/performance-class'
      >
        <h5>Class</h5>
      </Link>
    );
  } else if (path_name === 'qlib') {
    qlib_resc = (
      <Link
        className='btn left-menu-subheading path-selected'
        to='/resource/qlib'
      >
        <h5>QLib</h5>
      </Link>
    );
  } else if (path_name === 'topic-study-packs') {
    topic_study_packs_resc = (
      <Link
        className='btn left-menu-subheading btn-lesspad path-selected'
        to='/resource/topic-study-packs'
      >
        <h5>Topic Study Packs</h5>
      </Link>
    );
  } else if (path_name === 'lesson-plans') {
    lesson_plans_resc = (
      <Link
        className='btn left-menu-subheading btn-lesspad path-selected'
        to='/resource/lesson-plans'
      >
        <h5>Lesson Plans</h5>
      </Link>
    );
  } else if (path_name === 'exam-prep') {
    exam_prep_resc = (
      <Link
        className='btn left-menu-subheading btn-lesspad'
        to='/resource/exam-prep'
      >
        <h5>Board Exam Prep</h5>
      </Link>
    );
  }

  const role = localStorage.getItem('role');
  return (
    <div>
      {role === 'Admin' ? (
        <>
          <div className='row'>
            <div className='col-2'>
              <img
                className='left-menu-img'
                src={dashboard_icon}
                alt='dashboard_icon'
              />
            </div>
            <div className='col-10'>{statedashboard}</div>
          </div>
          <div className='row'>
            <div className='col-2'>
              <img
                className='left-menu-img'
                src={schoolIcon}
                alt='schoolIcon'
              />
            </div>
            <div className='col-10'>{districtperformance}</div>
          </div>
        </>
      ) : (
        <>
          <div className='row'>
            <div className='col-2'>
              <FontAwesomeIcon
                icon={faThLarge}
                color='#7f615d'
                style={{ marginLeft: '10px', marginTop: '10px' }}
              />
            </div>
            <div className='col-10'>
              {schoolHomePage}
              <hr className='left-menu-hr' />
            </div>
          </div>

          <div className='row'>
            <div className='col-2'>
              <FontAwesomeIcon
                icon={faBook}
                color='#7f615d'
                style={{ marginLeft: '10px', marginTop: '10px' }}
              />
            </div>
            <div className='col-10'>
              <Link className='btn left-menu-btn' to=''>
                <h5>Resources</h5>
              </Link>
              <hr className='left-menu-hr' />

              <div className='row '>
                <div className='col-1'></div>
                <div className='col-10' style={{ paddingRight: '4px' }}>
                  {teacher_resc}
                  <hr className='left-menu-hr' />
                </div>
              </div>

              <div className='row '>
                <div className='col-1'></div>
                <div className='col-10' style={{ paddingRight: '4px' }}>
                  {student_resc}
                  <hr className='left-menu-hr' />
                </div>
              </div>

              <div className='row '>
                <div className='col-1'></div>
                <div className='col-10' style={{ paddingRight: '4px' }}>
                  {qlib_resc}
                  <hr className='left-menu-hr' />
                </div>
              </div>

              <div className='row '>
                <div className='col-1'></div>
                <div className='col-10' style={{ paddingRight: '4px' }}>
                  {lesson_plans_resc}
                  <hr className='left-menu-hr' />
                </div>
              </div>
              <div className='row '>
                <div className='col-1'></div>
                <div className='col-10' style={{ paddingRight: '4px' }}>
                  {topic_study_packs_resc}
                  <hr className='left-menu-hr' />
                </div>
              </div>
              <div className='row '>
                <div className='col-1'></div>
                <div className='col-10' style={{ paddingRight: '4px' }}>
                  {exam_prep_resc}
                  <hr className='left-menu-hr' />
                </div>
              </div>
            </div>
          </div>

          <div className='row'>
            <div className='col-2'>
              <FontAwesomeIcon
                icon={faVideo}
                color='#7f615d'
                style={{ marginLeft: '10px', marginTop: '10px' }}
              />
            </div>
            <div className='col-10'>
              {videos_lnk}
              <hr className='left-menu-hr' />
            </div>
          </div>
          <div className='row'>
            <div className='col-2'>
              <FontAwesomeIcon
                icon={faClipboardList}
                color='#7f615d'
                style={{ marginLeft: '10px', marginTop: '10px' }}
              />
            </div>
            <div className='col-10'>
              {worksheet_mkr}
              <hr className='left-menu-hr' />
            </div>
          </div>
          <div className='row'>
            <div className='col-2'>
              <FontAwesomeIcon
                icon={faComment}
                color='#7f615d'
                style={{ marginLeft: '10px', marginTop: '10px' }}
              />
            </div>
            <div className='col-10'>
              {school_cnt}
              <hr className='left-menu-hr' />
            </div>
          </div>
          <div className='row'>
            <div className='col-2'>
              <FontAwesomeIcon
                icon={faChartLine}
                color='#7f615d'
                style={{ marginLeft: '10px', marginTop: '10px' }}
              />
            </div>
            <div className='col-10'>
              {goToMonitoring}
              <hr className='left-menu-hr' />
            </div>
          </div>
          <div className='row'>
            <div className='col-2'>
              <FontAwesomeIcon
                icon={faChalkboardTeacher}
                color='#7f615d'
                style={{ marginLeft: '10px', marginTop: '10px' }}
              />
            </div>
            <div className='col-10'>
              {goToTraining}
              <hr className='left-menu-hr' />
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default LeftMenu;
