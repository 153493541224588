/* eslint-disable jsx-a11y/scope */
import React from 'react';

const OnGoingCourses = ({ courseList, openModal }) => {
  return (
    <div className='w-90'>
      <div className='tabel-responsive'>
        <table className='table table-bordered whiteTabel table-bordered '>
          <thead>
            <tr>
              <th scope='col'>Course Title </th>
              <th scope='col' className='tex-center'>
                Completed
              </th>
              <th scope='col' className='tex-center'>
                Ongoing
              </th>
              <th scope='col'> </th>
            </tr>
          </thead>
          <tbody>
            {courseList?.map((p, i) => (
              <tr key={i}>
                <td scope='row'>{p.title}</td>
                <td className='tex-center'>4</td>
                <td className='tex-center'>1</td>
                <td className='tex-center'>
                  <button
                    className='skyButton'
                    onClick={openModal}
                    type='button'
                  >
                    Go to course
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default OnGoingCourses;
