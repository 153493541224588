/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import {
  Area,
  AreaChart,
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  LabelList,
  Legend,
  Pie,
  PieChart,
  Sector,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { getDistList, getReadinessScores } from '../services/api';
import LoaderSpinner from './LoaderSpinner';
import NotFoundDialog from './NotFoundDialog';
import { exportPDF } from './pdfDownloader';
import DropdownButton from './Signout';
import './styles/PerformanceAnalysis.css';
const barChartColors = [
  ['#c0e3fb', '#0390f2', '#004677'],
  ['#fcdcdc', '#f47979', '#750b0b'],
  ['#ffddaf', '#ffa834', '#f38d00'],
];
const pieChartColors = [
  ['#0390f2', '#0073c2', '#004677'],
  ['#f47979', '#a82a2a', '#760c0c'],
  ['#ffc371', '#ffa934', '#f38d00'],
];
const tempDataForEndline = {
  aspiring: 1,
  scholar: 1,
  master: 1,
};

const PerformanceAnalysis = () => {
  const [readinessScoresData, setReadinessScoresData] = useState([]);
  const [state, setState] = useState(localStorage.getItem('state'));
  const [district, setDistrict] = useState('');
  const [className, setClassName] = useState('');
  const [distList, setDistList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const checkEndline = (data) => {
    if (data.aspiring || data.scholar || data.master) {
      return true;
    } else {
      return false;
    }
  };
  const handleOnSelectedDistrict = (e) => {
    setDistrict(e.target.value);
  };
  const handleOnSelectedClassName = (e) => {
    setClassName(e.target.value);
  };
  useEffect(() => {
    getDistList(state).then((res) => {
      setDistList(res.data.data);
    });
  }, [state]);

  useEffect(() => {
    const u_code = localStorage.getItem('m_auth_token');

    const payload = {
      state: `${state}`,
      district: `${district}`,
      className: `${className}`,
    };

    const config = {
      headers: {
        Authorization: `${u_code}`,
      },
    };
    setIsLoading(true);
    getReadinessScores(payload, config).then((res) => {
      const newData = res.data.data;
      newData.sort((a, b) => {
        const subjectOrder = { English: 0, Maths: 1, Science: 2 };
        return subjectOrder[a.subject] - subjectOrder[b.subject];
      });
      setReadinessScoresData(newData);
      setIsLoading(false);
    });
  }, [state, district, className]);

  return (
    <>
      <div className='container' id='main'>
        <div className='readinessCon'>
          <div className='readHeadingCon' id='perfor_subheader'>
            <h2>Readiness - Performance Analysis</h2>
            <div className='readRight'>
              <DropdownButton />
            </div>
          </div>

          <div className='readMain'>
            <p>
              Readiness is foundation to building new skills. A large set of
              students find it difficult to recall prior competencies when they
              start a new class. Consolidation of learning is helpful to make
              them ready and confident to accept newer and higher competencies.
            </p>

            <p>
              A <strong>Baseline Assessment</strong> is conducted to estimate
              the learning level right at the beginning of the academic year. A
              short 6-8 week revision sprint of prior competencies is then
              delivered. An
              <strong> Endline Assessment</strong> after the Readiness Sprint
              determines the efficacy as well as estimates the Readiness for the
              new class.
            </p>

            <p>
              Readiness resources, mapped to the key prior competency is
              available in the{' '}
              <strong>Neeve Learning Portal of the schools</strong> .
            </p>
          </div>

          <div>
            <div className='row'>
              <div className='readSearch'>
                <div id='custom-search-input'>
                  <select
                    className='dropdown dropdownCus'
                    onChange={handleOnSelectedDistrict}
                    value={district}
                  >
                    <option
                      className='dropdown-toggle'
                      value=''
                      role='button'
                      data-bs-toggle='dropdown'
                      aria-expanded='false'
                      key={0}
                    >
                      All Districts
                    </option>
                    {distList.length > 0
                      ? distList.map((item, index) => {
                          return (
                            <option
                              className='dropdown-item'
                              value={item.district}
                              key={index + 1}
                            >
                              {item.district}
                            </option>
                          );
                        })
                      : ''}
                  </select>
                </div>
              </div>
              <div className='readSearch'>
                <div id='custom-search-input'>
                  <select
                    className='dropdown dropdownCus'
                    onChange={handleOnSelectedClassName}
                    value={className}
                  >
                    <option
                      className='dropdown-toggle'
                      value=''
                      role='button'
                      data-bs-toggle='dropdown'
                      aria-expanded='false'
                    >
                      All Classes
                    </option>
                    <option className='dropdown-item' value='6'>
                      Class 6
                    </option>
                    <option className='dropdown-item' value='7'>
                      Class 7
                    </option>
                    <option className='dropdown-item' value='8'>
                      Class 8
                    </option>
                    <option className='dropdown-item' value='9'>
                      Class 9
                    </option>
                    <option className='dropdown-item' value='10'>
                      Class 10
                    </option>
                    <option className='dropdown-item' value='11'>
                      Class 11
                    </option>
                    <option className='dropdown-item' value='12'>
                      Class 12
                    </option>
                  </select>
                </div>
              </div>
              <button
                className='dropdownCus'
                style={{ width: 'auto', height: '42px', margin: '10px' }}
                onClick={() => exportPDF('main', 'Performance Analysis')}
                id='downloadButton'
              >
                Download Report
              </button>
            </div>
          </div>
          <hr />

          {!isLoading && readinessScoresData.length > 0 ? (
            <>
              <div className='endlineComparison'>
                {readinessScoresData.length > 0
                  ? readinessScoresData.map((item, index) => {
                      return (
                        <div key={index}>
                          <br />
                          <h2>
                            Readiness Snapshot: EndLine - Baseline Difference :{' '}
                            {item.subject ? item.subject : ''}
                          </h2>
                          <div className='chartCon'>
                            <AreaChartCard
                              data={item}
                              base={item.baseline.aspiring}
                              end={item.endline.aspiring}
                              name='Aspiring level'
                            />
                            <AreaChartCard
                              data={item}
                              base={item.baseline.scholar}
                              end={item.endline.scholar}
                              name='Scholar level'
                            />
                            <AreaChartCard
                              data={item}
                              base={item.baseline.master}
                              end={item.endline.master}
                              name='Master level'
                            />
                          </div>
                        </div>
                      );
                    })
                  : ''}
              </div>
              <hr />
              <div className='endlineComparison'>
                <h2>Baseline - Endline Comparison</h2>
                <div className='chartCon'>
                  {readinessScoresData.length > 0
                    ? readinessScoresData.map((item, index) => {
                        return (
                          <BarChartNew
                            data={processBarChartData(item)}
                            name={item.subject}
                            colors={barChartColors[index]}
                            key={index + 1}
                          />
                        );
                      })
                    : ''}
                </div>
              </div>
              <hr />

              {readinessScoresData.length > 0 &&
                readinessScoresData.map((item, index) => {
                  return (
                    <div className='endlineComparison' key={index}>
                      <h2>Baseline - Endline Result - {item.subject}</h2>
                      <div className='chartCoTwo'>
                        <MyPieChart
                          heading='Baseline Assessment'
                          // data={processPieChartData(item.baseline)}
                          data={processPieChartData(
                            checkEndline(item.baseline)
                              ? item.baseline
                              : tempDataForEndline
                          )}
                          COLORS={
                            checkEndline(item.baseline)
                              ? pieChartColors[index]
                              : ['#dfdfdf', '#c6c6c7', '#bfbfbe']
                          }
                        />
                        <MyPieChart
                          heading={'Endline Assessment'}
                          data={processPieChartData(
                            checkEndline(item.endline)
                              ? item.endline
                              : tempDataForEndline
                          )}
                          COLORS={
                            checkEndline(item.endline)
                              ? pieChartColors[index]
                              : ['#dfdfdf', '#c6c6c7', '#bfbfbe']
                          }
                        />
                      </div>
                    </div>
                  );
                })}
            </>
          ) : isLoading ? (
            <LoaderSpinner />
          ) : (
            <NotFoundDialog />
          )}
        </div>
      </div>
    </>
  );
};

const BarChartNew = ({ data, name, colors }) => {
  const renderCustomizedLabel = (props) => {
    const { x, y, width, value } = props;
    return (
      <text
        x={x + width / 2}
        y={y + 15}
        fill='#fff'
        textAnchor='middle'
        dominantBaseline='middle'
        fontSize={10}
      >
        {`${value ? value : 0}%`}
      </text>
    );
  };
  return (
    <div className='bar__chart'>
      <div className='bar__chart__top' style={{ backgroundColor: colors[0] }}>
        <h1>{name}</h1>
      </div>
      <div>
        <BarChart
          width={300}
          height={230}
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: -15,
            bottom: 5,
          }}
          barGap={5500}
          barSize={30}
        >
          <Tooltip />

          <CartesianGrid strokeDasharray='3 3' />
          <XAxis dataKey='name' height={20} fontSize={10} />
          <YAxis
            type='number'
            domain={[0, 100]}
            ticks={[0, 20, 40, 60, 80, 100]}
          />
          {/* <Tooltip /> */}
          <Bar
            dataKey='Baseline'
            fill={colors[1]}
            minPointSize={10}
            radius={[50, 50, 0, 0]}
          >
            radius={[50, 50, 0, 0]}
            <LabelList dataKey={'Baseline'} content={renderCustomizedLabel} />
          </Bar>
          <Bar
            dataKey='End_line'
            fill={colors[2]}
            minPointSize={5}
            width={20}
            radius={[50, 50, 0, 0]}
          >
            <LabelList dataKey='End_line' content={renderCustomizedLabel} />
          </Bar>
        </BarChart>
      </div>
    </div>
  );
};

const MyAreaChart = () => {
  const data = [
    { x: '', y: 100 },
    { x: '', y: 200 },
    { x: '', y: 150 },
    { x: '', y: 300 },
    { x: '', y: 400 },
  ];
  return (
    <AreaChart width={120} height={100} data={data}>
      <Area type='monotone' dataKey='y' stroke='transparent' fill='#8884d8' />
    </AreaChart>
  );
};

const renderActiveShape = (props) => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    value,
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 15) * cos;
  const my = cy + (outerRadius + 15) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 29;
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';

  return (
    <g strokeWidth='2' onClick={() => {}}>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path
        d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
        stroke={fill}
        fill='none'
      />
      <circle cx={ex} cy={ey} r={6} fill={fill} stroke='none' />
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        textAnchor={textAnchor}
        fill='#333'
      >
        {value}
      </text>
    </g>
  );
};

const MyPieChart = ({ heading, data, COLORS }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const onPieEnter = (_, index) => {
    setActiveIndex(index);
  };
  return (
    <div className='pie__chart__card' onClick={null}>
      <h4>{heading}</h4>
      <hr />
      <div className='pie__chart__card__content'>
        <PieChart width={450} height={400}>
          <Pie
            activeIndex={activeIndex}
            activeShape={renderActiveShape}
            data={data}
            cx='50%'
            cy='50%'
            outerRadius={130}
            fill='#8884d8'
            dataKey='value'
            onMouseEnter={onPieEnter}
            onClick={null}
            onPieClick={(index, data) => {}}
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>
          <Legend />
        </PieChart>
      </div>
    </div>
  );
};

const processBarChartData = (data) => {
  const baselineTotal =
    Number(data.baseline.aspiring) +
    Number(data.baseline.scholar) +
    Number(data.baseline.master);
  const endlineTotal =
    Number(data.endline.aspiring) +
    Number(data.endline.scholar) +
    Number(data.endline.master);

  const result = Object.keys(data.baseline).reduce((acc, key) => {
    const range = {
      aspiring: '0-40',
      scholar: '41-80',
      master: '81-100',
    };

    const baselineValue = Number(data.baseline[key]);
    const endlineValue = Number(data.endline[key]);

    const obj = {
      name: range[key],
      Baseline: Math.floor((baselineValue / baselineTotal) * 100),
      End_line: endlineTotal
        ? Math.floor((endlineValue / endlineTotal) * 100)
        : 0,
    };

    acc.push(obj);
    return acc;
  }, []);

  return result;
};
const processPieChartData = (data) => {
  const tempData = {
    aspiring: Number(data.aspiring),
    scholar: Number(data.scholar),
    master: Number(data.master),
  };
  const result = Object.entries(tempData).reduce((acc, [category, value]) => {
    acc.push({ name: category, value });
    return acc;
  }, []);
  return result;
};

const calculatePercent = (data, base, end) => {
  const res1 =
    (base /
      (data.baseline.aspiring + data.baseline.scholar + data.baseline.master)) *
    100;
  const res2 =
    (end /
      (data.endline.aspiring + data.endline.scholar + data.endline.master)) *
    100;
  return res2 - res1;
};

const AreaChartCard = ({ data, base, end, name }) => {
  let n = calculatePercent(data, base, end).toFixed(2);

  return (
    <div className='area__chart__container'>
      <div className='area__chart__sub__container1'>
        <h4>{name}</h4>
        <br />
        <h5>{base || end ? end - base : 0}</h5>
        <p className='snapshot'>{`${n > 0 ? '+' : ''}${
          base || end ? n : 0
        }% vs baseline assessment`}</p>
      </div>
      <div className='area__chart__sub__container2'>
        <MyAreaChart />
      </div>
    </div>
  );
  // {item.baseline.aspiring}
};
export default PerformanceAnalysis;
