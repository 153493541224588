import React, { useEffect, useState } from 'react';
import {
  getDistList,
  getExamPrepRandinessData,
  getReadinessAspiringExamPrep,
  getStudentObservation,
  getTeacherObservationScores,
} from '../../services/api';
import ExamPrepBaseline from './ExamPrepBaseline';
import ExamPrepBaselineEndLineComparison from './ExamPrepBaselineEndLineComparison';
import ExamPrepDocumentsAndReports from './ExamPrepDocumentsAndReports';
import ExamPrepEndline from './ExamPrepEndline';
import FilterAndDownload from './FilterAndDownload';
import PmShriBaseLine from './PmShriBaseLine';
import PmShriEndLine from './PmShriEndLine';
import SpecialFocusSchoolsBaseline from './SpecialFocusSchoolsBaseline';
import SpecialFocusSchoolsEndline from './SpecialFocusSchoolsEndline';
import pmShriData from './pmShriData';

const BoardExamPrep = () => {
  // eslint-disable-next-line no-unused-vars
  const [className, setClassName] = useState(10);
  // eslint-disable-next-line no-unused-vars
  const [state, setState] = useState(localStorage.getItem('state'));
  // eslint-disable-next-line no-unused-vars
  const [district, setDistrict] = useState('');
  const [readinessScoresData, setReadinessScoresData] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [studentObservation, setStudentObservation] = useState([]);
  const [, setTeacherObservationScores] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [readinessData, setReadinessData] = useState([]);
  const [distList, setDistList] = useState([]);

  const [topStudentObservationBaseLine, setTopStudentObservationBaseLine] =
    useState([]);
  const [topStudentObservationEndline, setTopStudentObservationEndline] =
    useState([]);
  const handleOnSelectedDistrict = (e) => {
    setDistrict(e.target.value);
  };
  function sortAndFormatData(data) {
    const sortedData = data.sort((a, b) => {
      // Find the subject with the highest aspiring count in baseline data
      const aspiringMathsA = a.baseline.find((subject) => subject.Maths)?.Maths
        ?.aspiring;
      const aspiringEnglishA = a.baseline.find((subject) => subject.English)
        ?.English?.aspiring;
      const aspiringScienceA = a.baseline.find((subject) => subject.Science)
        ?.Science?.aspirbing;

      const aspiringMathsB = b.baseline.find((subject) => subject.Maths)?.Maths
        ?.aspiring;
      const aspiringEnglishB = b.baseline.find((subject) => subject.English)
        ?.English?.aspiring;
      const aspiringScienceB = b.baseline.find((subject) => subject.Science)
        ?.Science?.aspiring;

      const highestAspiringA = Math.max(
        aspiringEnglishA,
        aspiringMathsA,
        aspiringScienceA
      );
      const highestAspiringB = Math.max(
        aspiringEnglishB,
        aspiringMathsB,
        aspiringScienceB
      );

      return highestAspiringB - highestAspiringA;
    });

    const formattedData = sortedData.slice(0, 10).map((entry, index) => {
      // Find the subject with the highest aspiring count in baseline data

      const aspiringMathsA = entry.baseline.find((subject) => subject.Maths)
        ?.Maths?.aspiring;
      const aspiringEnglishA = entry.baseline.find((subject) => subject.English)
        ?.English?.aspiring;
      const aspiringScienceA = entry.baseline.find((subject) => subject.Science)
        ?.Science?.aspiring;

      const highestAspiring = Math.max(
        aspiringEnglishA,
        aspiringMathsA,
        aspiringScienceA
      );
      return {
        id: index + 1,
        udise: entry.udiseId,
        schoolName: entry.schoolName,
        district: entry.district,
        aspiringCount: highestAspiring,
      };
    });
    return formattedData.sort((a, b) => b.aspiringCount - a.aspiringCount);
  }

  function sortAndFormatDataEndLine(data) {
    const sortedData = data.sort((a, b) => {
      // Find the subject with the highest aspiring count in endline data
      const aspiringMathsA = a.endline.find((subject) => subject.Maths)?.Maths
        ?.aspiring;
      const aspiringEnglishA = a.endline.find((subject) => subject.English)
        ?.English?.aspiring;
      const aspiringScienceA = a.endline.find((subject) => subject.Science)
        ?.Science?.aspirbing;

      const aspiringMathsB = b.endline.find((subject) => subject.Maths)?.Maths
        ?.aspiring;
      const aspiringEnglishB = b.endline.find((subject) => subject.English)
        ?.English?.aspiring;
      const aspiringScienceB = b.endline.find((subject) => subject.Science)
        ?.Science?.aspiring;

      const highestAspiringA = Math.max(
        aspiringEnglishA,
        aspiringMathsA,
        aspiringScienceA
      );
      const highestAspiringB = Math.max(
        aspiringEnglishB,
        aspiringMathsB,
        aspiringScienceB
      );

      return highestAspiringB - highestAspiringA;
    });

    const formattedData = sortedData.slice(0, 10).map((entry, index) => {
      // Find the subject with the highest aspiring count in endline data

      const aspiringMathsA = entry.endline.find((subject) => subject.Maths)
        ?.Maths?.aspiring;
      const aspiringEnglishA = entry.endline.find((subject) => subject.English)
        ?.English?.aspiring;
      const aspiringScienceA = entry.endline.find((subject) => subject.Science)
        ?.Science?.aspiring;

      const highestAspiring = Math.max(
        aspiringEnglishA,
        aspiringMathsA,
        aspiringScienceA
      );
      return {
        id: index + 1,
        udise: entry.udiseId,
        schoolName: entry.schoolName,
        district: entry.district,
        aspiringCount: highestAspiring,
      };
    });
    return formattedData.sort((a, b) => b.aspiringCount - a.aspiringCount);
  }
  useEffect(() => {
    getDistList(state).then((res) => {
      setDistList(res.data.data);
    });
  }, [state]);

  useEffect(() => {
    const u_code = localStorage.getItem('m_auth_token');

    const payload = {
      state: `${state}`,
      district: `${district}`,
      className: `${className}`,
    };

    const config = {
      headers: {
        Authorization: `${u_code}`,
      },
    };
    getExamPrepRandinessData(payload, config).then((res) => {
      const newData = res.data.data;
      newData.sort((a, b) => {
        const subjectOrder = { English: 0, Maths: 1, Science: 2 };
        return subjectOrder[a.subject] - subjectOrder[b.subject];
      });
      setReadinessScoresData(newData);
    });
    getTeacherObservationScores(payload, config).then((res) => {
      setTeacherObservationScores(res.data.data ? res.data.data : []);
    });
    getStudentObservation(payload, config).then((res) => {
      setStudentObservation(res.data.data.length ? res.data.data : []);
    });
    getReadinessAspiringExamPrep(payload, config).then((res) => {
      setReadinessData(res.data.data.length ? res.data.data : []);
      const baselineData = res.data.data.length
        ? sortAndFormatData(res.data.data)
        : [];
      setTopStudentObservationBaseLine(() => [...baselineData]);
      const endLineData = res.data.data.length
        ? sortAndFormatDataEndLine(res.data.data)
        : [];
      setTopStudentObservationEndline(endLineData);
    });
  }, [className, district, state]);

  return (
    <div className='container' id='main'>
      <br />
      <h1>Board Exam Prep - Class 10</h1>
      <br />
      <p>
        Class 10 Board Exam is the first major external exam in a students life.
        Preparing for board exams might feel overwhelming, It’s best to give at
        least a month to study for the board exams, as it will take time to go
        through all of the material. Board Exam Prep Programme helps the
        students prepare for this very important event in their lives by
        providing structured exam style mock papers, stress busting and slow
        infusion of learning through Neeve app practice. A baseline and end line
        test determines the level or readiness for Board Exam, as well as
        efficacy of the programme.
      </p>
      <br />
      <hr />
      <FilterAndDownload
        handleOnSelectedDistrict={handleOnSelectedDistrict}
        district={district}
        distList={distList}
      />
      <br />
      <ExamPrepBaseline baselineData={readinessScoresData} />
      <br />
      {/* <hr /> <ExamPrepOnAppNeeve /> */}
      <hr />
      <ExamPrepEndline endLineData={readinessScoresData} />
      <hr />
      <ExamPrepBaselineEndLineComparison
        data={readinessScoresData}
        title={'Exam Prep Baseline - End Line Comparison'}
        description={`Comparison of baseline and end line results is presented in this
				analytics graph. This indicates the efficacy of Board Exam Prep
				Programme.`}
      />
      <hr />
      {/* 
     		<BaselineAssessmentMonitoring
				teacherObservationScores={teacherObservationScores}
			/>
			<hr />
			<EndLineAssessmentMonitoring
				teacherObservationScores={teacherObservationScores}
			/>
			<hr />
			*/}
      <SpecialFocusSchoolsBaseline
        topStudentObservationBaseLine={topStudentObservationBaseLine}
      />
      <hr />
      <SpecialFocusSchoolsEndline
        topStudentObservationEndline={topStudentObservationEndline}
      />
      <br />
      <hr />
      <br />
      <PmShriBaseLine baselineData={pmShriData.data} />
      <br />
      <hr />
      <br />
      <PmShriEndLine endLineData={pmShriData.data} />
      <br />
      <hr />
      <br />
      <ExamPrepBaselineEndLineComparison
        data={pmShriData.data}
        title={'PM Shri Baseline - End Line Comparison'}
        description={`Comparison of baseline and end line results is presented in this
				analytics graph. This indicates the efficacy of Board PM Shri
				Programme.`}
      />
      <br />
      <hr />
      <br />
      <ExamPrepDocumentsAndReports />
      <br />
      {/*
			<hr />
			<Testimonials />
			*/}
    </div>
  );
};

export default BoardExamPrep;
