import Alert from 'react-bootstrap/Alert';

function AlertSection(props) {
  const { closeAlert } = props;
  const { show, fadeOut, variant, heading, paragraph } = props.alertData;

  const alertHidden = {
    opacity: !fadeOut ? 1 : 0,
    transition: 'opacity 10s ease-in-out',
  };

  return (
    <>
      {show && (
        <section style={alertHidden} className='secGap'>
          <div className='container'>
            <div className='col-md-12'>
              <Alert variant={variant} onClose={() => closeAlert()} dismissible>
                <Alert.Heading>{heading}</Alert.Heading>
                <p>{paragraph}</p>
              </Alert>
            </div>
          </div>
        </section>
      )}
    </>
  );
}

export default AlertSection;
