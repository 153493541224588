import React, { useEffect, useState } from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import logo from './../assets/NeeveLogo.svg';
import './styles/ClassroomObservation.css';

import DropdownButton from './Signout';
// import { teacherObservationScoresStatic, teacherScoreAnalysisStatic, topTenTeacherAchievementStatic, topTenTeachersProgressStatic } from "./classRoomApi";
import {
  Bar,
  BarChart,
  CartesianGrid,
  LabelList,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import {
  getDistList,
  getStudentObservation,
  getTeacherObservationScores,
  getTeacherScoreAnalysis,
  getTopTenTeacherAchievement,
  getTopTenTeacherProgress,
} from '../services/api';
import { exportPDF } from './pdfDownloader';

const testimonialsData = [
  {
    image: logo,
    name: 'John Doe',
    role: 'Happy Customer',
    description: `I love puzzles, projects and stories. 
I have a lot of fun when I am on Neeve. Is this really studying? `,
  },
  {
    image: logo,
    name: 'Jane Smith',
    role: 'Satisfied Client',
    description: 'Sed ut perspiciatis unde omnis iste natus error.',
  },
  {
    image: logo,
    name: 'Jane Smith',
    role: 'Satisfied Client',
    description: 'Sed ut perspiciatis unde omnis iste natus error.',
  },
  {
    image: logo,
    name: 'Jane Smith',
    role: 'Satisfied Client',
    description: 'Sed ut perspiciatis unde omnis iste natus error.',
  },
];

const microParameterData = [
  {
    heading: 'Preparation for Class',
    questions: [
      {
        question: 'Preparation of Lesson Plan',
      },
      {
        question: 'Remedial Inputs connected to Lesson Plan',
      },
      {
        question: 'Use of Student Workbooks for Readiness',
      },
      {
        question: 'Eliciting prior knowledge',
      },
    ],
  },
  {
    heading: 'Classroom Management',
    questions: [
      {
        question: 'Classroom Environment – equity of gender, caste, ability',
      },
      {
        question: 'Managing Classroom Behavior',
      },
      {
        question: 'Monitoring',
      },
      {
        question: 'Teacher Talking Time (TTT)',
      },
    ],
  },
  {
    heading: 'Teaching-Learning Practice',
    questions: [
      {
        question: 'Using Open-ended questions',
      },
      {
        question: 'Instructions',
      },
      {
        question: 'Group work and Peer Learning',
      },
      {
        question: 'Students’ Own Experiences ',
      },
      {
        question: 'Developing Communication Skills',
      },
      {
        question: 'Stages of the lesson ',
      },
      {
        question: 'Use of the board',
      },
    ],
  },
  {
    heading: 'Assessment, Feedback and Follow-Up',
    questions: [
      {
        question: 'Error Correction',
      },
      {
        question: 'Provide feedback to students ',
      },
      {
        question: 'Use formative methods for assessing progress ',
      },
    ],
  },
];

const headers = [
  'SL',
  'Teacher Name',
  'Name of School',
  'Subject',
  'Final Score',
  'District',
  'State',
];
const teachersProgressHeader = [
  'SL',
  'Teacher Name',
  'Name of School',
  'Subject',
  'Progress Score',
  'District',
  'State',
];
const studentsObservation = [
  'SL',
  'Observation Parameters',
  'Final Observation',
  'Comparison',
];

const ClassroomObservation = () => {
  // eslint-disable-next-line no-unused-vars
  const [state, setState] = useState(localStorage.getItem('state'));
  const [district, setDistrict] = useState('');
  const [distList, setDistList] = useState([]);
  const [visibleMicroParam, setVisibleMicroParam] = useState(false);
  const [microParam, setMicroParam] = useState({});
  const [teacherObservationScores, setTeacherObservationScores] = useState([]);
  const [teacherScoreAnalysis, setTeacherScoreAnalysis] = useState([]);
  const [topTenInitialTeacher, setTopTenInitialTeacher] = useState([]);
  const [topTenProgressTeacher, setTopTenProgressTeacher] = useState([]);
  const [topStudentObservation, setStudentObservation] = useState([]);
  const showMicroParameters = (id) => {
    setMicroParam(microParameterData[id - 1]);
    setMicroParam((prev) => microParameterData[id - 1]);
    toggleMicroParameters();
  };
  const toggleMicroParameters = () => {
    setVisibleMicroParam((prev) => !prev);
  };
  const handleOnSelectedDistrict = (e) => {
    setDistrict(e.target.value);
  };

  useEffect(() => {
    getDistList(state).then((res) => {
      setDistList(res.data.data);
    });
  }, [state]);

  useEffect(() => {
    const u_code = localStorage.getItem('m_auth_token');
    const state = localStorage.getItem('state');
    const payload = {
      state: `${state}`,
      district: district ? `${district}` : '',
    };

    const config = {
      headers: {
        Authorization: `${u_code}`,
      },
    };

    getTeacherObservationScores(payload, config).then((res) => {
      setTeacherObservationScores(res.data.data ? res.data.data : []);
    });

    getTeacherScoreAnalysis(payload, config).then((res) => {
      setTeacherScoreAnalysis(res.data.data ? res.data.data : []);
    });

    getTopTenTeacherAchievement(payload, config).then((res) => {
      if (res.data.data['topTenFinalTeacher'].length > 0) {
        setTopTenInitialTeacher(
          (prev) => (prev = res.data.data['topTenFinalTeacher'])
        );
      } else if (res.data.data['topTenInitialTeacher'].length > 0) {
        setTopTenInitialTeacher(
          (prev) => (prev = res.data.data['topTenInitialTeacher'])
        );
      } else {
        setTopTenInitialTeacher((prev) => (prev = []));
      }
    });

    getTopTenTeacherProgress(payload, config).then((res) => {
      setTopTenProgressTeacher(
        res.data.data['topTenProgressTeacher']
          ? res.data.data['topTenProgressTeacher']
          : []
      );
    });
    getStudentObservation(payload, config).then((res) => {
      setStudentObservation(res.data.data.length ? res.data.data : []);
    });
  }, [district]);
  return (
    <div
      className='class__room__observation'
      id='classroom'
      style={{ padding: '15px' }}
    >
      {visibleMicroParam ? (
        <Modal
          microParam={microParam}
          toggleMicroParameters={toggleMicroParameters}
        />
      ) : (
        ''
      )}
      <br />
      <div className='readHeadingCon'>
        <h2>Classroom Observation - Teachers</h2>
        <div>
          <DropdownButton />
        </div>
      </div>
      <br />
      <br />
      <p className='classroom__header__description'>
        While transacting a lesson, teachers are observed against a set of
        parameters, and graded on a scale of 4. Each identified teacher is
        observed twice, their progress and achievement are captured in each
        observation. This page shows the analysis of data collected during
        teachers observation for helping the administrators with strategic
        interventions
      </p>
      <div>
        <div className='row'>
          <div className='readSearch'>
            <div id='custom-search-input'>
              <select
                className='dropdown dropdownCus'
                onChange={handleOnSelectedDistrict}
                value={district}
              >
                <option
                  className='dropdown-toggle'
                  value=''
                  role='button'
                  data-bs-toggle='dropdown'
                  aria-expanded='false'
                  key={0}
                >
                  All Districts
                </option>
                {distList.length > 0
                  ? distList.map((item, index) => {
                      return (
                        <option
                          className='dropdown-item'
                          value={item.district}
                          key={index + 1}
                        >
                          {item.district}
                        </option>
                      );
                    })
                  : ''}
              </select>
            </div>
          </div>
          <button
            className='dropdownCus'
            style={{ width: '250px', height: '42px', margin: '10px' }}
            onClick={() => exportPDF('classroom', 'Classroom Observation')}
            id='downloadButton'
          >
            Download Report
          </button>
          <button
            className='dropdownCus'
            style={{ width: '250px', height: '42px', margin: '10px' }}
          >
            <a
              // href={Classroom_Observation_Form1}
              href='https://drive.google.com/file/d/1tSHghVd1NSLYvgfQY8b3QkPgYwGtmq4H/view?usp=share_link'
              target='_blank'
              download='Classroom_Observation_Form.pdf'
              rel='noreferrer'
            >
              Download Form
            </a>
          </button>
        </div>
      </div>

      <br />
      <div className='progressBar-container'>
        {teacherObservationScores.length ? (
          <>
            <CircularProgressBar
              heading={'Preparation for class'}
              pathColor={'#27963c'}
              trailColor={'#d4ead8'}
              value={
                teacherObservationScores[0]['Preparation for Class'].initial
              }
              totalValue={4}
              points={
                teacherObservationScores[0]['Preparation for Class'].final -
                teacherObservationScores[0]['Preparation for Class'].initial
              }
            />
            <CircularProgressBar
              heading={'Classroom Management'}
              pathColor={'#1249B0'}
              trailColor={'#d0dbef'}
              value={
                teacherObservationScores[1]['Classroom Management'].initial
              }
              totalValue={4}
              points={
                teacherObservationScores[1]['Classroom Management'].final -
                teacherObservationScores[1]['Classroom Management'].initial
              }
            />
            <CircularProgressBar
              heading={'Teaching Learning Practice'}
              pathColor={'#9747FF'}
              trailColor={'#eadaff'}
              value={
                teacherObservationScores[2]['Teaching-Learning Practice']
                  .initial
              }
              totalValue={4}
              points={
                teacherObservationScores[2]['Teaching-Learning Practice']
                  .final -
                teacherObservationScores[2]['Teaching-Learning Practice']
                  .initial
              }
            />
            <CircularProgressBar
              heading={'Assessment, Feedback, Followup'}
              pathColor={'#FFA934'}
              trailColor={'#ffeed6'}
              value={
                teacherObservationScores[3][
                  'Assessment, Feedback and Follow-Up'
                ].initial
              }
              totalValue={4}
              points={
                teacherObservationScores[3][
                  'Assessment, Feedback and Follow-Up'
                ].final -
                teacherObservationScores[3][
                  'Assessment, Feedback and Follow-Up'
                ].initial
              }
            />
          </>
        ) : (
          ''
        )}
      </div>
      <hr />
      <div>
        <br />
        <h3>Score Comparison - Initial vs Final Observation</h3>
        <br />
        {teacherObservationScores.length > 0 ? (
          <>
            <ScoreComparison
              header={'Preparation for class'}
              footer={`(Click to view 4 micro parameters)`}
              value={{
                initial:
                  teacherObservationScores[0]['Preparation for Class'].initial,
                final:
                  teacherObservationScores[0]['Preparation for Class'].final,
                total: 4,
              }}
              id={1}
              data={microParameterData[0]}
              showMicroParameters={(id) => {
                showMicroParameters(id);
              }}
            />
            <ScoreComparison
              header={'Classroom Management'}
              footer={`(Click to view 4 micro parameters)`}
              value={{
                initial:
                  teacherObservationScores[1]['Classroom Management'].initial,
                final:
                  teacherObservationScores[1]['Classroom Management'].final,
                total: 4,
              }}
              id={2}
              data={microParameterData[1]}
              showMicroParameters={(id) => {
                showMicroParameters(id);
              }}
            />
            <ScoreComparison
              header={'Teaching Learning Practice'}
              footer={`(Click to view 7 micro parameters)`}
              value={{
                initial:
                  teacherObservationScores[2]['Teaching-Learning Practice']
                    .initial,
                final:
                  teacherObservationScores[2]['Teaching-Learning Practice']
                    .final,
                total: 4,
              }}
              id={3}
              data={microParameterData[2]}
              showMicroParameters={(id) => {
                showMicroParameters(id);
              }}
            />
            <ScoreComparison
              header={'Assessment, Feedback, Followup'}
              footer={`(Click to view 3 micro parameters)`}
              value={{
                initial:
                  teacherObservationScores[3][
                    'Assessment, Feedback and Follow-Up'
                  ].initial,
                final:
                  teacherObservationScores[3][
                    'Assessment, Feedback and Follow-Up'
                  ].final,
                total: 4,
              }}
              id={4}
              data={microParameterData[3]}
              showMicroParameters={(id) => {
                showMicroParameters(id);
              }}
            />
          </>
        ) : null}
      </div>
      <hr />
      <div>
        <br />
        <h3>Score Analysis - Final Observation</h3>
        <br />
        <p>
          This graph shows the % age and number of teachers who’s points are 1
          (low) to 4 (high).
        </p>
        <div className='bar__chart__container'>
          {teacherScoreAnalysis.length > 0 ? (
            <>
              <BarChartNew
                name='Preparation for Class'
                colors={['#d0e8d7', '#27963C']}
                data={teacherScoreAnalysis[0]['Preparation for Class']}
              />
              <BarChartNew
                name='Classroom management'
                colors={['#cdd8ee', '#1249B0']}
                data={teacherScoreAnalysis[1]['Classroom Management']}
              />
              <BarChartNew
                name='Teaching learning practice'
                colors={['#e9d8ff', '#9747FF']}
                data={teacherScoreAnalysis[2]['Teaching-Learning Practice']}
              />
              <BarChartNew
                name='Assessment & feedback'
                colors={['#ffddaf', '#FFA934']}
                data={
                  teacherScoreAnalysis[3]['Assessment, Feedback and Follow-Up']
                }
              />
            </>
          ) : (
            ''
          )}
        </div>
        <br />
        <br />
      </div>
      <hr />
      <div>
        <br />
        <h3>Top 10 Teachers - Achievement</h3>
        <br />
        {topTenInitialTeacher.length > 0 ? (
          <>
            <DynamicTable2
              headers={headers}
              data={topTenInitialTeacher}
              color={'#ccd9ee'}
            />
          </>
        ) : (
          ''
        )}
        <br />
      </div>
      <hr />
      <div>
        <br />
        <h3>Top 10 Teachers - Progress</h3>
        <br />
        {topTenProgressTeacher.length > 0 ? (
          <>
            <DynamicTable
              headers={teachersProgressHeader}
              data={topTenProgressTeacher}
              color={'#feecd5'}
            />
          </>
        ) : (
          ''
        )}
      </div>
      <br />
      <hr />
      <div>
        <br />
        <h3>Classroom observation - Students</h3>
        <br />
        {topStudentObservation.length > 0 ? (
          <>
            <DynamicTable3
              headers={studentsObservation}
              data={topStudentObservation}
              color={'#e7f5d4'}
            />
          </>
        ) : (
          ''
        )}
      </div>
      <br />
      <br />
      <div>
        <h3>Testimonials</h3>
        <br />
        {state === 'Sikkim' ? (
          <div className='testimonials'>
            <div className='testimonial-card'>
              <iframe
                width='240'
                height='250'
                src='https://www.youtube.com/embed/w7792VHEj5o?si=uilWXIxYXRDtd2Ut'
                title='YouTube video player'
                frameBorder='0'
                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                allowFullScreen
              ></iframe>
              <h3 className='testimonial-name'>
                FOCUS GROUP DISCUSSION ON SELSI PROGRAMME
              </h3>
            </div>
            <div className='testimonial-card'>
              <iframe
                width='240'
                height='250'
                src='https://www.youtube.com/embed/1B1ZtvVglpk?si=-YP4dsxjhBG7ZRV3'
                title='YouTube video player'
                frameBorder='0'
                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                allowFullScreen
              ></iframe>
              <h3 className='testimonial-name'>
                SELSI FGD programme | PAKYONG DISTRICT
              </h3>
            </div>
            <div className='testimonial-card'>
              <iframe
                width='240'
                height='250'
                src='https://www.youtube.com/embed/CgDHGYTWFys?si=F9Je9pK4f7Iaee7V'
                title='YouTube video player'
                frameBorder='0'
                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                allowFullScreen
              ></iframe>
              <h3 className='testimonial-name'>
                SELSI FGD programme | GYALSHINH DISTRICT
              </h3>
            </div>

            <div className='testimonial-card'>
              <iframe
                width='240'
                height='250'
                src='https://www.youtube.com/embed/QjNQukxbKGg?si=zX62_NoZOExjElHn'
                title='YouTube video player'
                frameBorder='0'
                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                allowFullScreen
              ></iframe>
              <h3 className='testimonial-name'>
                SELSI programme FGD | School Sanganath ss | Namchi District
              </h3>
            </div>
          </div>
        ) : (
          <div className='testimonials'>
            {testimonialsData.map((testimonial, index) => (
              <TestimonialCard
                key={index}
                name={testimonial.name}
                image={testimonial.image}
                role={testimonial.role}
                description={testimonial.description}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default ClassroomObservation;

const CircularProgressBar = ({
  heading,
  pathColor,
  trailColor,
  value,
  totalValue,
  points,
}) => {
  const percentage = Math.floor((value / totalValue) * 100);
  return (
    <div className='progress__bar__container'>
      <p className='progress__bar__heading' style={{ color: pathColor }}>
        {heading}
      </p>
      <br />
      <br />
      <div style={{ width: '171px', height: '171px' }}>
        <CircularProgressbar
          value={percentage}
          text={`${value}/${totalValue}`}
          styles={buildStyles({
            textColor: pathColor,
            pathColor: pathColor,
            trailColor: trailColor,
            strokeLinecap: 'butt',
          })}
          strokeWidth={10}
          counterClockwise
        />
      </div>
      <br />
      <p>
        <span className='progress__bar__footer1' style={{ color: pathColor }}>
          {points.toFixed(2)} points
        </span>{' '}
        <span className='progress__bar__footer2'>from initial observation</span>
      </p>
    </div>
  );
};

const HorizontalBarChart = ({ height, width, data }) => {
  const calculatedWidth = (value, total) => {
    return (value / total) * 100;
  };
  const width1 = calculatedWidth(data.initial, data.total);
  const width2 = calculatedWidth(data.final, data.total);
  const barContainerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    width: `${width}px`,
    backgroundColor: '#fff',
    justifyContent: 'space-around',
  };
  const barStyle = {
    display: 'flex',
    alignItems: 'center',
    color: '#fff',
    fontWeight: 'bold',
    paddingLeft: '15px',
    transition: 'width 0.3s ease-in-out',
    borderRadius: '50px',
    height: `50%`,
  };
  return (
    <div style={barContainerStyle}>
      <div style={{ display: 'flex', alignItems: 'center', height: '60%' }}>
        <div
          style={{
            ...barStyle,
            width: `${width1}%`,
            backgroundColor: '#2C7FFF',
          }}
        >
          {' '}
          <h5>INITIAL</h5>
        </div>
        <h5>{data.initial.toFixed(2)}</h5>
      </div>
      <div style={{ display: 'flex', alignItems: 'center', height: '60%' }}>
        <div
          style={{
            ...barStyle,
            width: `${width2}%`,
            backgroundColor: '#FF4A8B',
          }}
        >
          {' '}
          <h5>FINAL</h5>
        </div>
        <h5>{data.final.toFixed(2)}</h5>
      </div>
    </div>
  );
};

const ScoreComparison = ({
  header,
  footer,
  value,
  id,
  showMicroParameters,
}) => {
  return (
    <div className='score__comparison__container' key={id}>
      <div className='score__comparison__sub__container'>
        <h5>{header}</h5>
        <br />
        <p
          onClick={() => {
            showMicroParameters(id);
          }}
        >
          {footer}
        </p>
      </div>
      <>
        <HorizontalBarChart height={60} width={400} data={value} />
      </>
    </div>
  );
};

const DynamicTable = ({ headers, data, color }) => {
  const tableData = data.filter((row) => row);
  return (
    <table style={{ border: '0px' }}>
      <thead>
        <tr>
          {headers.map((header, index) => (
            <th
              key={index}
              className='table__heading'
              style={color ? { backgroundColor: color, color: 'black' } : {}}
            >
              {header}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {tableData?.map((row, index) => (
          <tr key={index}>
            <td>{index + 1}</td>
            <td>{row.teacherName}</td>
            <td>{row.schoolName}</td>
            <td>{row.subject}</td>
            <td>{row.progressScore}</td>
            <td>{row.district}</td>
            <td>{row.state}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
const DynamicTable2 = ({ headers, data, color }) => {
  const tableData = data.filter((row) => row);
  return (
    <table style={{ border: '0px' }}>
      <thead>
        <tr>
          {headers.map((header, index) => (
            <th
              key={index}
              className='table__heading'
              style={color ? { backgroundColor: color, color: 'black' } : {}}
            >
              {header}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {tableData?.map((row, index) => (
          <tr key={index}>
            <td>{index + 1}</td>
            <td>{row?.teacherName}</td>
            <td>{row?.schoolName}</td>
            <td>{row?.subject}</td>
            <td>{row?.finalScore ?? '0'}</td>
            <td>{row?.district}</td>
            <td>{row?.state}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
const DynamicTable3 = ({ headers, data, color }) => {
  const tableData = data.filter((row) => row);
  return (
    <table style={{ border: '0px' }}>
      <thead>
        <tr>
          {headers.map((header, index) => (
            <th
              key={index}
              className='table__heading'
              style={color ? { backgroundColor: color, color: 'black' } : {}}
            >
              {header}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {tableData?.map((row, index) => (
          <tr key={index}>
            <td>{index + 1}</td>
            <td>{row?.observationParam}</td>
            <td>{row?.final}</td>
            <td>Upto {row?.comparision} from Initial Observation</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

const BarChartNew = ({ name, colors, data }) => {
  const initTotal =
    data.initOne + data.initTwo + data.initThree + data.initFour;
  const finalTotal =
    data.finalOne + data.finalTwo + data.finalThree + data.finalFour;
  function calculatePercentage(value) {
    return ((value / finalTotal) * 100).toFixed(0);
  }
  const barChartData = [
    {
      name: '1',
      initial: ((data.initOne / initTotal) * 100).toFixed(0),
      final: calculatePercentage(data.finalOne),
    },
    {
      name: '2',
      initial: ((data.initTwo / initTotal) * 100).toFixed(0),
      final: calculatePercentage(data.finalTwo),
    },
    {
      name: '3',
      initial: ((data.initThree / initTotal) * 100).toFixed(0),
      final: calculatePercentage(data.finalThree),
    },
    {
      name: '4',
      initial: ((data.initFour / initTotal) * 100).toFixed(0),
      final: calculatePercentage(data.finalFour),
    },
  ];
  const renderCustomizedLabel = (props) => {
    const { x, y, width, value } = props;
    return (
      <text
        x={x + width / 2}
        y={y + 15}
        fill='#fff'
        textAnchor='middle'
        dominantBaseline='middle'
        fontSize={10}
      >
        {`${value}%`}
      </text>
    );
  };
  return (
    <div className='class__room__bar__chart'>
      <div
        className='class__room__bar__chart__top'
        style={{ backgroundColor: colors[0] }}
      >
        <h1>{name}</h1>
      </div>
      <div>
        <BarChart
          width={250}
          height={230}
          data={barChartData}
          margin={{
            top: 5,
            right: 30,
            left: -15,
            bottom: 5,
          }}
          barGap={5500}
          barSize={30}
        >
          <Tooltip />

          <CartesianGrid strokeDasharray='3 3' />
          <XAxis dataKey='name' height={20} fontSize={15} />
          <YAxis
            type='number'
            domain={[0, 100]}
            ticks={[0, 20, 40, 60, 80, 100]}
          />
          <Bar
            dataKey='final'
            fill={colors[1]}
            minPointSize={10}
            radius={[50, 50, 0, 0]}
          >
            radius={[50, 50, 0, 0]}
            <LabelList dataKey={'final'} content={renderCustomizedLabel} />
          </Bar>
        </BarChart>
      </div>
    </div>
  );
};

const TestimonialCard = ({ image, name, role, description }) => {
  return (
    <div className='testimonial-card'>
      <div className='testimonial-image'>
        <img src={image} alt='Testimonial' />{' '}
      </div>
      <br />
      <h3 className='testimonial-name'>{name}</h3>
      <div className='testimonial-content'>
        <h4 className='testimonial-role'>{role}</h4>
        <p className='testimonial-description'>{description}</p>
      </div>
    </div>
  );
};

const Modal = ({ microParam, toggleMicroParameters }) => {
  return (
    <div className='modal__container'>
      <div className='modal__card'>
        <div className='modal__button' onClick={toggleMicroParameters}>
          <button>Close</button>
        </div>
        <h2>{microParam.heading} </h2>
        {microParam.questions.length > 0 ? (
          <div className='micro__parameter'>
            {microParam.questions.map((item, index) => {
              return (
                <h5 key={index + 1}>
                  <span>
                    <b>-</b>
                  </span>{' '}
                  {item.question}
                </h5>
              );
            })}
          </div>
        ) : null}
      </div>
    </div>
  );
};
