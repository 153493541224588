import React from 'react';

const DynamicTable = ({ headers, data, color }) => {
  const tableData = data.filter((row) => row);
  return (
    <table style={{ border: '0px' }}>
      <thead>
        <tr>
          {headers.map((header, index) => (
            <th
              key={index}
              className='table__heading'
              style={color ? { backgroundColor: color, color: 'black' } : {}}
            >
              {header}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {tableData?.map((row, index) => (
          <tr key={index}>
            <td>{index + 1}</td>
            <td>{row.udise}</td>
            <td>{row.schoolName}</td>
            <td>{row.district}</td>
            <td>{row.aspiringCount}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default DynamicTable;
