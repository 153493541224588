import React from 'react';
import DynamicTable from './DynamicTable';
const headers = [
  'SL',
  'UDISE',
  'Name of School',
  'District',
  'Students in aspiring',
];

const SpecialFocusSchoolsEndline = ({ topStudentObservationEndline }) => {
  return (
    <div>
      <h2>10 Special Focus Schools - Endline</h2>
      <p>
        Assessment time monitoring is done to maintain the sanctity and efficacy
        of the asssessments that are being administered. Analytics of the
        monitoring parameters are presented here.
      </p>
      {topStudentObservationEndline?.length > 0 && (
        <DynamicTable
          headers={headers}
          data={topStudentObservationEndline}
          color={'#ccd9ee'}
        />
      )}
    </div>
  );
};

export default SpecialFocusSchoolsEndline;
