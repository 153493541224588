import { useState } from 'react';
import { FaSquareFull } from 'react-icons/fa';
import { Cell, Legend, Pie, PieChart, Sector } from 'recharts';

const legendLabel = ['0-40', '41-80', '81-100'];

const MyPieChart = ({ heading, data, COLORS }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const onPieEnter = (_, index) => {
    setActiveIndex(index);
  };
  const RenderLegend = (props) => {
    const { payload } = props;
    return (
      <div className='d-flex justify-content-end'>
        {payload.map((_entry, index) => (
          <div key={index}>
            <FaSquareFull className='mx-1' size={18} color={COLORS[index]} />
            <span className='mx-1' key={`item-${index}`}>
              {legendLabel[index]}%
            </span>
          </div>
        ))}
      </div>
    );
  };
  return (
    <div className='pie__chart__card' onClick={null}>
      <h4>{heading}</h4>
      <hr />
      <div className='pie__chart__card__content'>
        <PieChart width={300} height={300}>
          <Pie
            activeIndex={activeIndex}
            activeShape={renderActiveShape}
            data={data}
            cx='50%'
            cy='50%'
            outerRadius={80}
            fill='#8884d8'
            dataKey='value'
            onMouseEnter={onPieEnter}
            onClick={null}
            onPieClick={(_index, _data) => {}}
          >
            {data.map((_entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>
          <Legend content={<RenderLegend />} />
        </PieChart>
      </div>
    </div>
  );
};

const renderActiveShape = (props) => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    value,
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + outerRadius * cos;
  const sy = cy + outerRadius * sin;
  const mx = cx + outerRadius * cos;
  const my = cy + outerRadius * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 29;
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';

  return (
    <g strokeWidth='2' onClick={() => {}}>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius}
        outerRadius={outerRadius}
        fill={fill}
      />
      <path
        d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
        stroke={fill}
        fill='none'
      />
      <circle cx={ex} cy={ey} r={6} fill={fill} stroke='none' />
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        textAnchor={textAnchor}
        fill='#333'
      >
        {value}
      </text>
    </g>
  );
};

export default MyPieChart;
