import {
  faBook,
  faChalkboardTeacher,
  faChartLine,
  faPencilAlt,
  faThLarge,
  faUserGraduate,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import logo from './../assets/NeeveLogo.svg';
import './styles/Sidebar.css';

const SidebarSchool = () => {
  // eslint-disable-next-line no-unused-vars
  const [, setActiveNavBar] = useState('');

  const saveRoute = (route) => {
    localStorage.setItem('navActive', `${route}`);
    setActiveNavBar(route);
  };

  useEffect(() => {
    const active = localStorage.getItem('navActive');
    setActiveNavBar(active);
  }, []);
  return (
    <div className='sidebar'>
      <img
        src={logo}
        alt=''
        style={{ marginTop: '40px', marginBottom: '40px', height: '150px' }}
      />
      <NavLink to={'/'} className='state__main__bar'>
        <FontAwesomeIcon icon={faThLarge} color='#fff' />
        <div className='state__main__bar__title'>{`School Homepage`}</div>
      </NavLink>
      <NavLink
        to={'/monitoring-student'}
        className='main__bar__nav'
        activeClassName='main-link-active'
        onClick={() => saveRoute('/school-readiness/')}
      >
        <FontAwesomeIcon icon={faPencilAlt} color='#111' />
        <div className='main__title'>{`App Performance`}</div>
      </NavLink>
      <NavLink
        to={'/monitoring-student'}
        className='sub__bar'
        activeClassName='sub-link-active'
      >
        <div className='sub__title'>{`Student`}</div>
      </NavLink>
      <NavLink
        to={'/monitoring-class'}
        className='sub__bar'
        activeClassName='sub-link-active'
      >
        <div className='sub__title'>{`Class`}</div>
      </NavLink>
      <NavLink
        to={'/monitoring-school'}
        className='sub__bar'
        activeClassName='sub-link-active'
      >
        <div className='sub__title'>{`School`}</div>
      </NavLink>
      <NavLink
        to={'/school-readiness/'}
        className='main__bar__nav'
        activeClassName='main-link-active'
      >
        <FontAwesomeIcon icon={faChartLine} color='#111' />
        <div className='main__title'>{`Readiness Performance`}</div>
      </NavLink>
      <NavLink
        to={'/school-classroom-observation/'}
        className='main__bar__nav'
        activeClassName='main-link-active'
      >
        <FontAwesomeIcon icon={faUserGraduate} color='#111' />
        <div className='main__title'>{`Classroom Observation`}</div>
      </NavLink>
      <NavLink
        to={'/resource/student-readiness'}
        className='main__bar__nav'
        activeClassName='main-link-active'
      >
        <FontAwesomeIcon icon={faBook} color='#111' />
        <div className='main__title'>{`Go to Learning`}</div>
      </NavLink>
      <NavLink
        to={'/training-home'}
        className='main__bar__nav'
        activeClassName='main-link-active'
      >
        <FontAwesomeIcon icon={faChalkboardTeacher} color='#111' />
        <div className='main__title'>{`Go to Training`}</div>
      </NavLink>
    </div>
  );
};

export default SidebarSchool;
