import axios from 'axios';
import React from 'react';
import bgimage from './../assets/bg-image.png';
import * as mod from './../resources/url';
import Header from './header-signup';

function Login() {
  return (
    <div>
      <Header />
      <div className='singnupbg' style={{ backgroundImage: `url(${bgimage})` }}>
        <br />
        <br />
        <br />
        <br />

        <div className='container'>
          <div className='row text-center'>
            <div className='col-0 col-md-2'></div>

            <div className='col-0 col-md-8 formDiv'>
              <h2>Forgot Password?</h2>
              <NameForm />
            </div>

            <div className='col-0 col-md-2'></div>
          </div>
        </div>
        <br />
        <br />
        <br />
      </div>
    </div>
  );
}

class NameForm extends React.Component {
  constructor(props) {
    super(props);
    const getemail = localStorage.getItem('forgot_email');

    this.state = { email: getemail, password: '', retypepassword: '' };

    this.handleChangePassword = this.handleChangePassword.bind(this);
    this.handleChangeRetypepassword =
      this.handleChangeRetypepassword.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChangePassword(event) {
    this.setState({ password: event.target.value });
  }

  handleChangeRetypepassword(event) {
    this.setState({ retypepassword: event.target.value });
  }

  handleSubmit(event) {
    event.preventDefault();

    var password_ok = 0;
    var retypepassword_ok = 0;
    var getemail = localStorage.getItem('forgot_email');

    if (this.state.password && this.state.password !== '') {
      console.log('password Defined');
      console.log(this.state.password);
      console.log(this.state.password.length);
      //LOWER
      console.log(/[a-z]/.test(this.state.password));
      //UPPER
      console.log(/[A-Z]/.test(this.state.password));

      document.getElementById('password-error-1').style.display = 'none';
      if (
        this.state.password.length > 7 &&
        /[a-z]/.test(this.state.password) &&
        /[A-Z]/.test(this.state.password)
      ) {
        document.getElementById('password-error-1').style.display = 'none';
        document.getElementById('password-error-2').style.display = 'none';
        password_ok = 1;
      } else {
        document.getElementById('password-error-1').style.display = 'none';
        document.getElementById('password-error-2').style.display = 'block';
      }
    } else {
      document.getElementById('password-error-1').style.display = 'block';
    }

    if (this.state.retypepassword && this.state.retypepassword !== '') {
      console.log('retypepassword Defined');
      document.getElementById('retypepassword-error-1').style.display = 'none';
      document.getElementById('retypepassword-error-2').style.display = 'none';

      if (this.state.password === this.state.retypepassword) {
        document.getElementById('retypepassword-error-2').style.display =
          'none';
        retypepassword_ok = 1;
      } else {
        document.getElementById('retypepassword-error-2').style.display =
          'block';
      }
    } else {
      document.getElementById('retypepassword-error-1').style.display = 'block';
    }

    console.log(password_ok);
    console.log(retypepassword_ok);
    if (password_ok === 1 && retypepassword_ok === 1) {
      var apiBaseUrl = mod.api_url + '/user/web/changepassword';

      var data = {
        emailId: getemail,
        password: this.state.password,
      };
      //console.log(data);

      axios
        .post(apiBaseUrl, data)
        .then(function (response) {
          console.log(response);

          if (response.status === 200) {
            window.localStorage.removeItem('forgot_email');
            alert(response.data.data.message);
            window.location.href = `/login`;
          } else {
            alert(response.data.message);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }

  render() {
    return (
      <form onSubmit={this.handleSubmit}>
        <input
          className='input-text-box'
          type='password'
          x
          name='password'
          onChange={this.handleChangePassword}
          placeholder='Set New Password'
        />
        <h6 id='password-error-1' className='error-msg'>
          <span>🠕</span> Please Fill the Password
        </h6>
        <h6 id='password-error-2' className='error-msg'>
          <span>🠕</span> Your password should consist of at least one uppercase
          letter, one lowercase letter and one number
        </h6>
        <h6 id='password-error-3' className='error-msg'>
          <span>🠕</span> Please Fill the Password
        </h6>

        <input
          className='input-text-box'
          type='password'
          name='retypepassword'
          onChange={this.handleChangeRetypepassword}
          placeholder='Retype New Password'
        />
        <h6 id='retypepassword-error-1' className='error-msg'>
          <span>🠕</span> Passwords do not match
        </h6>

        <h6 id='retypepassword-error-2' className='error-msg'>
          <span>🠕</span> Passwords do not match
        </h6>

        <input
          className='btn header-nav-btn-primary'
          type='submit'
          value='Submit'
        />
      </form>
    );
  }
}
export default Login;
