import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function CourseDetails(props) {
  return (
    <Modal
      {...props}
      size='lg'
      dialogClassName='modal-90w'
      aria-labelledby='example-custom-modal-styling-title'
    >
      <Modal.Header closeButton>
        <Modal.Title id='example-custom-modal-styling-title'>
          Modal heading
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='row'>
          <div className='col-12'>
            <div className=' pb-4'>
              <div className='row g-0'>
                <div className='col-md-4'>
                  <div className='sec'>
                    <img
                      src={require('./../../assets/te_3.jpg')}
                      className=''
                      alt='...'
                    />
                  </div>
                </div>
                <div className='col-md-8 '>
                  <div className='card-body  cardBodyCus  cusMarLeft'>
                    <h3 className='card-title skText'>
                      Technology in Education
                    </h3>
                    <h5>What is the course about? </h5>
                    <p className='card-text'>
                      The course covers the adnacement on technology in school
                      education and technological tools available in Neeve app
                      Lorem ipsum dolor sit amet.
                    </p>
                    <ul className='list-unstyled cusList'>
                      <li>
                        <i className='fa-solid fa-list-ol'></i> Number of
                        sessions - 4
                      </li>
                      <li>
                        <i className='fa-regular fa-clock'></i> Time - 3 hours
                        40 min
                      </li>

                      <li className='pt-3'>
                        {' '}
                        <a href='##' className='sbutton'>
                          Go to course
                        </a>{' '}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-12 '>
            <div className='seper '>
              <div className='tex-center'>
                <h2 className='assText '>List of Trainees</h2>
                <h6 className='blue-text'>
                  Start a course, or pick up training from where you had left
                  off. Dont see your name in the list? Register in 1 minute and
                  start the training.
                </h6>
              </div>

              <div className='w-90 pt-4'>
                <div className='tabel-responsive'>
                  <table className='table table-bordered whiteTabel table-bordered '>
                    <thead>
                      <tr>
                        <th scope='col'>Name</th>
                        <th scope='col' className='tex-center'>
                          Designation
                        </th>
                        <th scope='col' className='tex-center'>
                          Function
                        </th>
                        <th scope='col'> </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Joyati B</td>
                        <td className='tex-center'>Teacher</td>
                        <td className='tex-center'>Science/Maths</td>
                        <td className='tex-center'>
                          <a href='##' className='bluebutton'>
                            Go to course
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td>Pallavi</td>
                        <td className='tex-center'>HOI/Principal</td>
                        <td className='tex-center'>Administration</td>
                        <td className='tex-center'>
                          <a href='##' className='greenbutton'>
                            Completed
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button className='btn btn-danger' onClick={props.onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default CourseDetails;
