import {
  faBookOpen,
  faChalkboardTeacher,
  faChartLine,
  faGraduationCap,
  faThLarge,
  faUserGraduate,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { NavLink } from 'react-router-dom';
import logo from './../assets/NeeveLogo.svg';
import './styles/Sidebar.css';

const Sidebar = () => {
  const state = localStorage.getItem('state');
  const district = localStorage.getItem('district');
  return (
    <div className='sidebar'>
      <img
        src={logo}
        alt=''
        style={{ marginTop: '40px', marginBottom: '40px', height: '150px' }}
      />
      <NavLink to={'/statedashboardnew/'} className='state__main__bar'>
        <FontAwesomeIcon icon={faThLarge} color='#fff' />
        <div className='state__main__bar__title'>{`State Homepage`}</div>
      </NavLink>
      <NavLink
        to={'/statedashboardnew/Readiness/'}
        className='main__bar__nav'
        activeClassName='main-link-active'
      >
        <FontAwesomeIcon icon={faChartLine} color='#000' />
        <div className='main__title'>{`Readiness`}</div>
      </NavLink>
      <NavLink
        to={'/statedashboardnew/Readiness/PerformanceAnalysis'}
        className='sub__bar'
        activeClassName='sub-link-active'
      >
        <div className='sub__title'>{`Performance Analysis`}</div>
      </NavLink>
      <NavLink
        to={'/statedashboardnew/Readiness/AspiringSchools'}
        className='sub__bar'
        activeClassName='sub-link-active'
      >
        <div className='sub__title'>{`Aspiring Schools`}</div>
      </NavLink>

      <NavLink
        to={`/statedashboardnew/${state}/`}
        className='main__bar__nav'
        activeClassName='main-link-active'
      >
        <FontAwesomeIcon icon={faBookOpen} color='#000' />
        <div className='main__title'>{`Supplementary Learning`}</div>
      </NavLink>
      <NavLink
        to={`/statedashboardnew/${state}/`}
        className='sub__bar'
        activeClassName='sub-link-active'
      >
        <div className='sub__title'>{`State Level Performance`}</div>
      </NavLink>
      <NavLink
        to={`/statedashboardnew/${state}/${district}`}
        className='sub__bar'
        activeClassName='sub-link-active1'
      >
        <div className='sub__title'>{`District Level Performance`}</div>
      </NavLink>
      <NavLink
        to={`/statedashboardnew/ClassroomObservation`}
        className='main__bar__nav'
        activeClassName='main-link-active'
      >
        <FontAwesomeIcon icon={faChalkboardTeacher} color='#000' />
        <div className='main__title'>{`Classroom Observation`}</div>
      </NavLink>
      <NavLink
        to={`/statedashboardnew/TrainingDevelopment`}
        className='main__bar__nav'
        activeClassName='main-link-active'
      >
        <FontAwesomeIcon icon={faUserGraduate} color='#000' />
        <div className='main__title'>{`Training & Development`}</div>
      </NavLink>
      <NavLink
        to={`/statedashboardnew/exam`}
        className='main__bar__nav'
        activeClassName='main-link-active'
      >
        <FontAwesomeIcon icon={faGraduationCap} color='#000' />
        <div className='main__title'>{`Class 10 Exam Prep`}</div>
      </NavLink>
    </div>
  );
};

export default Sidebar;
