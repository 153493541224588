import React from 'react';
import MyPieChart from './PieChart';

const pieChartColors = [
  ['#0390f2', '#0073c2', '#004677'],
  ['#f47979', '#a82a2a', '#760c0c'],
  ['#ffc371', '#ffa934', '#f38d00'],
];
const tempDataForEndline = {
  aspiring: 1,
  scholar: 1,
  master: 1,
};
const ExamPrepBaseline = ({ baselineData }) => {
  const checkEndline = (data) => {
    if (data.aspiring || data.scholar || data.master) {
      return true;
    } else {
      return false;
    }
  };
  return (
    <div>
      <h3>Exam Prep Baseline</h3>
      <p>
        Baseline is a pen and paper test that is designed to mimic the Board
        Exam style. Baseline is carried out right in the beginning of Exam Prep
        programme to assess the level of the students. Practice session and mock
        tests commence after Baseline exam.
      </p>
      <br />
      <div style={{ display: 'flex', marginTop: '-70px' }}>
        {baselineData.length > 0 &&
          baselineData.map((item, index) => {
            return (
              <div className='endlineComparison' key={index}>
                <div className='chartCoTwo'>
                  <MyPieChart
                    heading={item.subject}
                    // data={processPieChartData(item.baseline)}
                    data={processPieChartData(
                      checkEndline(item.baseline)
                        ? item.baseline
                        : tempDataForEndline
                    )}
                    COLORS={
                      checkEndline(item.baseline)
                        ? pieChartColors[index]
                        : ['#dfdfdf', '#c6c6c7', '#bfbfbe']
                    }
                  />
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default ExamPrepBaseline;

const processPieChartData = (data) => {
  const tempData = {
    aspiring: Number(data.aspiring),
    scholar: Number(data.scholar),
    master: Number(data.master),
  };
  const result = Object.entries(tempData).reduce((acc, [category, value]) => {
    acc.push({ name: category, value });
    return acc;
  }, []);
  return result;
};
