// import { useState, } from "react";
// import Modal from 'react-modal';
// import close from "../../assets/close.svg";
// import Select from 'react-select'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';

function TeacherLogin(props) {
  const { validated, onSubmit, onChange, initval, scldata } = props;

  //console.log("-----",initval);

  return (
    <Modal
      {...props}
      size='lg'
      // aria-labelledby="example-modal-sizes-title-lg"
      dialogClassName='modal-90w'
      aria-labelledby='example-custom-modal-styling-title'
    >
      <Modal.Header closeButton>
        <Modal.Title id='example-custom-modal-styling-title'>
          Register as a Trainee
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='row'>
          <div className='col-12'>
            <div className=' pb-4'>
              <div className='row g-0'>
                <div className='col-md-12 '>
                  <div className='card-body  cardBodyCus  cusMarLeft'>
                    <Form noValidate validated={validated} onSubmit={onSubmit}>
                      <Form.Group
                        className='mb-3'
                        controlId='exampleForm.ControlInput1'
                      >
                        <Form.Label>First Name</Form.Label>
                        <Form.Control
                          required
                          type='text'
                          name='firstName'
                          placeholder=''
                          onChange={onChange}
                          autoFocus
                        />
                        <Form.Control.Feedback>
                          Looks good!
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group
                        className='mb-3'
                        controlId='exampleForm.ControlInput2'
                      >
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control
                          required
                          type='text'
                          name='lastName'
                          placeholder=''
                          onChange={onChange}
                          autoFocus
                        />
                        <Form.Control.Feedback>
                          Looks good!
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group
                        className='mb-3'
                        controlId='exampleForm.ControlInput3'
                      >
                        <Form.Label>Phone Nnumber</Form.Label>
                        <Form.Control
                          required
                          type='text'
                          name='phoneNumber'
                          placeholder=''
                          autoFocus
                          onChange={onChange}
                          isInvalid={!!initval.errrors.phoneNumber}
                        />
                        {/* <Form.Control.Feedback>Looks good!</Form.Control.Feedback> */}
                        <Form.Control.Feedback type='invalid'>
                          {initval.errrors.phoneNumber}
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group
                        className='mb-3'
                        controlId='exampleForm.ControlInput4'
                      >
                        <Form.Label>Designation</Form.Label>
                        <Form.Control
                          required
                          as='select'
                          name='designation'
                          onChange={onChange}
                        >
                          <option value=''>--Select Designation--</option>
                          {scldata.designation
                            ? scldata.designation.map((p, i) => (
                                <option key={i} value={p.id}>
                                  {p.text}
                                </option>
                              ))
                            : ''}
                        </Form.Control>
                        <Form.Control.Feedback>
                          Looks good!
                        </Form.Control.Feedback>
                      </Form.Group>

                      {initval.login ? (
                        <button type='submit' className='sbutton'>
                          Login
                        </button>
                      ) : (
                        <button type='submit' className='sbutton'>
                          Submit
                        </button>
                      )}
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button className='btn btn-danger' onClick={props.onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default TeacherLogin;
