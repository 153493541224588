import React from 'react';
import { BrowserRouter, Link, useParams } from 'react-router-dom';
import './styles/CceTabel.css';

const CceTabel = ({ cceReport }) => {
  const { state } = useParams();
  const DisplayData = cceReport.map((info, index) => {
    return (
      <tr key={index}>
        <td>
          <Link to={`/districtperformance/${state}/${info.district}`}>
            {info.district}
          </Link>
        </td>
        <td>{info.schools}</td>
        <td>{info.students}</td>
        <td>
          <BrowserRouter>
            <Link
              to={'/performance-school/' + info.udise_id}
              target={info.udise_id}
            >
              {info.top_school_name}
            </Link>
          </BrowserRouter>
        </td>
      </tr>
    );
  });

  return (
    <div className='mar-topbot'>
      <div className='table-responsive'>
        <table stripped='true' bordered='true' hover='true' responsive='sm'>
          <thead>
            <tr>
              <th>District</th>
              <th>School</th>
              <th>students</th>
              <th>SHOWCASE SCHOOL</th>
            </tr>
          </thead>
          <tbody>{DisplayData}</tbody>
        </table>
      </div>
    </div>
  );
};

export default CceTabel;
