import Vimeo from '@u-wave/react-vimeo';
import axios from 'axios';
import * as _ from 'lodash';
import { useEffect, useState } from 'react';
import Pagination from 'react-js-pagination';
import { api_url } from '../resources/url';
import download from './../assets/downloadicon.jpg';
import Header from './header-signedin';

import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Redirect } from 'react-router';
import LeftMenu from './left-menu';

function Resource() {
  const [classId, setClassId] = useState('');
  const [subject, setSubject] = useState('');
  const [resources, setResources] = useState([]);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const [scrollFalse, setScrollFalse] = useState(false);
  const [oriResource, setOriResource] = useState([]);
  const [searchResource, setSearchResource] = useState('');

  useEffect(() => {
    let params = `/user/resources?mediatype=PDF&resource_type=lesson_plan&${
      classId ? 'class=' + classId + '&' : ''
    }${subject ? 'subject=' + subject : ''}`;
    params = params + `${subject || classId ? '&' : ''}page=1&count=22`;
    axios.get(api_url + params).then(
      (res) => {
        if (res) {
          setResources(res.data.data);
          setOriResource(res.data.data);
        }
      },
      () => {}
    );
    let countParams = `/user/resources/count?mediatype=PDF&resource_type=lesson_plan&${
      classId ? 'class=' + classId + '&' : ''
    }${subject ? 'subject=' + subject : ''}`;
    countParams = countParams + `${subject || classId ? '&' : ''}`;
    axios.get(api_url + countParams).then(
      (res) => {
        if (res) {
          setTotalCount(res.data.data?.total);
        }
      },
      () => {}
    );
  }, [subject, classId]);

  const getData = (nPage) => {
    let params = `/user/resources?mediatype=PDF&resource_type=lesson_plan&${
      classId ? 'class=' + classId + '&' : ''
    }${subject ? 'subject=' + subject : ''}`;
    params = params + `${subject || classId ? '&' : ''}page=${nPage}&count=15`;
    axios.get(api_url + params).then(
      (res) => {
        if (res && res.data.data.length > 0) {
          // const newR = resources;
          // _.map(res.data.data, (item) => {
          //   newR.push(item);
          // });
          setResources(res.data.data);
        } else {
          setScrollFalse(true);
        }
      },
      () => {}
    );
  };
  const handlePageChange = (e) => {
    setPage(e);
    getData(e);
  };

  useEffect(() => {
    if (searchResource.length > 0) {
      console.log(oriResource);
      console.log(searchResource);
      const filteredData = _.filter(oriResource, (item) => {
        const regex = new RegExp(searchResource, 'i');
        return regex.test(item.title?.trim());
      });
      if (filteredData) {
        setResources(filteredData);
      }
    } else {
      setResources(oriResource);
    }
  }, [oriResource, searchResource]);

  const Card = ({ item }) => (
    <div className='colll mb-6'>
      <div className='cardl'>
        {item?.mediatype === 'VIMEO' ? (
          <div className='embed-container'>
            <Vimeo video={item.link.replace('https://vimeo.com/', '')} />
          </div>
        ) : (
          <img src={item?.thumbnail} className='carda-img-top' alt='imageAlt' />
        )}

        <div className='cardd-body'>
          <h5 className='carddd-title'>{item?.title}</h5>

          {item?.mediatype !== 'VIMEO' && (
            <a href={item?.link} className='btnm btn-outline-secondary'>
              <img
                src={download}
                style={{ width: 70, height: 60 }}
                alt='imageAlt'
              />
            </a>
          )}

          <p className='carddddd-text'>{item?.desc}</p>
        </div>
      </div>
    </div>
  );

  const HeaderForm = () => {
    return (
      <div className='row'>
        <div className='col md-'>
          <select
            className='custom-select custom-select-lg mb-3'
            style={{ width: '100%', padding: 8, borderRadius: 5 }}
            onChange={(e) => setClassId(e.target.value)}
            value={classId}
          >
            <option value=''>Select Class</option>
            <option value='1'>Class 1</option>
            <option value='2'>Class 2</option>
            <option value='3'>Class 3</option>
            <option value='4'>Class 4</option>
            <option value='5'>Class 5</option>
            <option value='6'>Class 6</option>
            <option value='7'>Class 7</option>
            <option value='8'>Class 8</option>
            <option value='9'>Class 9</option>
            <option value='10'>Class 10</option>
          </select>
        </div>
        <div className='col md-5'>
          <select
            className='custom-select custom-select-lg mb-3'
            onChange={(e) => setSubject(e.target.value)}
            style={{ width: '100%', padding: 8, borderRadius: 5 }}
            value={subject}
          >
            <option value=''>Select Subject</option>
            <option value='Science'>Science</option>
            <option value='Maths'>Maths</option>
            <option value='English'>English</option>
            <option value='EVS'>EVS</option>
          </select>
        </div>

        <div className='col md-4'>
          <div className='input-group'>
            <input
              id='txtSearch'
              type='text'
              autoFocus
              value={searchResource}
              onChange={(e) => {
                setSearchResource(e.target.value);
              }}
              className='form-control serachtb'
              placeholder='Search by key'
            />
            <div className='input-group-append'>
              <span className='btn btnSearch' type='button'>
                <FontAwesomeIcon icon={faSearch} />
              </span>
            </div>
          </div>
        </div>

        <div className='col md-4' style={{ width: '100%' }}></div>
      </div>
    );
  };

  if (!localStorage.getItem('is_done')) {
    return <Redirect to={'/login'} />;
  }

  return (
    <div>
      <Header />
      <div className='bg-grey'>
        <div className='container-fluid p-10'>
          <div className='row' style={{ padding: 20 }}>
            <div className='col-2 left-menu'>
              <br />
              <LeftMenu />
            </div>

            <div className='col-9 right-menu'>
              <HeaderForm />
              {resources ? (
                <div className='rowww'>
                  {_.map(resources, (item, index) => (
                    <div key={index}>
                      <Card item={item} key={item?.title} />
                      {(index + 1) % 2 === 0 ? (
                        <div className='w-100' style={{}}></div>
                      ) : (
                        index === resources.length - 1 && (
                          <>
                            <div className='col mb-6'></div>
                            <div className='w-100' style={{}}></div>
                          </>
                        )
                      )}
                    </div>
                  ))}
                </div>
              ) : (
                <h1> </h1>
              )}
              {totalCount !== 0 ? (
                <div className='col-12 center flex justify-content-center'>
                  <Pagination
                    activePage={page}
                    itemsCountPerPage={10}
                    totalItemsCount={totalCount}
                    pageRangeDisplayed={7}
                    onChange={handlePageChange}
                    itemClass='page-item'
                    linkClass='page-link'
                    innerClass='pagination justify-content-center'
                  />
                </div>
              ) : (
                <h1 style={{ textAlign: 'center', marginTop: 100 }}>
                  Coming Soon...
                </h1>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Resource;
