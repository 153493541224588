import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Home from './../home';
import './../styles/StateDashboardNew.css';
import NeeveTraining from './neeve-training';
import OnGoingCourses from './OnGoingCourses';
import TrainingSideBar from './TrainingSideBar';
import TrendingCourses from './TrendingCourses';

const TrainingDashboard = () => {
  return (
    <div className='container-fluid'>
      <div className='row'>
        <div
          className='col-md-3'
          style={{ height: '100vh', backgroundColor: '#fff' }}
        >
          <TrainingSideBar />
        </div>
        <div
          className='col-md-9 main-container'
          style={{ height: '100vh', backgroundColor: '#FFFFFF' }}
        >
          <Switch>
            <Route exact path={`/training-home`} component={NeeveTraining} />
            <Route
              exact
              path={`/trending-courses`}
              component={TrendingCourses}
            />
            <Route
              exact
              path={`/ongoing-training`}
              component={OnGoingCourses}
            />
            <Route exact path={`/`} component={Home} />
          </Switch>
        </div>
      </div>
    </div>
  );
};

export default TrainingDashboard;
