import React from 'react';
import { Route, Switch } from 'react-router-dom';
import SchoolClassRoomObservation from './SchoolClassRoomObservation';
import SchoolReadinessHome from './SchoolReadinessHome';
import SidebarSchool from './SidebarSchool';
import Home from './home';
import MonitoringClass from './monitoring/MonitoringClass';
import MonitoringSchool from './monitoring/MonitoringSchool';
import MonitoringStudent from './monitoring/MonitoringStudent';
import './styles/StateDashboardNew.css';

const SchoolReadiness = () => {
  return (
    <div className='container-fluid'>
      <div className='row'>
        <div
          className='col-md-3'
          style={{ height: '100vh', backgroundColor: '#fff' }}
        >
          <SidebarSchool />
        </div>
        <div
          className='col-md-9 main-container'
          style={{ height: '100vh', backgroundColor: '#FFFFFF' }}
        >
          <Switch>
            <Route
              exact
              path={`/monitoring-school`}
              component={MonitoringSchool}
            />
            <Route
              exact
              path={`/monitoring-class`}
              component={MonitoringClass}
            />
            <Route
              exact
              path={`/monitoring-student`}
              component={MonitoringStudent}
            />
            <Route
              exact
              path={`/school-readiness`}
              component={SchoolReadinessHome}
            />
            <Route
              exact
              path={`/school-classroom-observation`}
              component={SchoolClassRoomObservation}
            />
            <Route exact path={`/`} component={Home} />
          </Switch>
        </div>
      </div>
    </div>
  );
};

export default SchoolReadiness;
