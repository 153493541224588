import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router';
import { useHistory, useParams } from 'react-router-dom';
import { getMonitoringAuthData } from '../services/api';
import * as mod from './../resources/url';
import LoaderSpinner from './LoaderSpinner';

function StaticLogin() {
  // eslint-disable-next-line no-unused-vars
  const [, setPasswordError] = useState('');
  const history = useHistory();
  const { param } = useParams();
  const cred = getCredential(atob(param));
  const udise = cred.u;
  const password = cred.p;

  useEffect(() => {
    if (localStorage.getItem('is_done')) {
      const path = localStorage.getItem('path');
      return <Redirect to={`${path}`} />;
    }
    const apiBaseUrl = mod.api_url + '/user/web/login';
    localStorage.setItem('user_id', udise);
    const data = {
      udiseId: udise,
      password: password,
    };
    axios
      .post(apiBaseUrl, data)
      .then(async function (response) {
        if (response.data.data) {
          localStorage.setItem(
            'u_code',
            encodeURIComponent(JSON.stringify(response.data.data.accessToken))
          );
          localStorage.setItem('udise_id', response.data.data.id);
          localStorage.setItem('state', response.data.data.state);
          localStorage.setItem('district', response.data.data.district);
          localStorage.setItem('school_name', response.data.data.school_name);
          localStorage.setItem('role', response.data.data.role);
          localStorage.setItem('is_done', true);

          const payload = {
            udiseCode: `${udise}`,
            authToken: `${response.data.data.accessToken}`,
          };
          const authResult = await getMonitoringAuthData(payload).then(
            (res) => {
              if (response.data.data) {
                localStorage.setItem(
                  'm_auth_token',
                  res.data.data.access_token
                );
                return res.data.data;
              }
            }
          );
          if (authResult && response.data.data) {
            if (response.data.data.role === 'Admin') {
              localStorage.setItem('path', `/statedashboardnew/`);
              localStorage.setItem('navActive', `/statedashboardnew/`);
              history.push(`/statedashboardnew/`);
            } else {
              localStorage.setItem('path', `/SchoolHomePage`);
              history.push(`/SchoolHomePage`);
            }
          } else {
            alert('Invalid credentials');
          }
        }
      })
      .catch(function () {
        setPasswordError(
          'Your user id or password is incorrect. Please enter your school udise code and account password'
        );
      });
  }, [history, password, udise]);

  if (localStorage.getItem('is_done')) {
    const path = localStorage.getItem('path');
    return <Redirect to={`${path}`} />;
  }

  return (
    <div>
      <LoaderSpinner />
    </div>
  );
}

function getCredential(inputString) {
  const regex = /u=([^&]+)&p=([^&]+)/;
  const match = inputString.match(regex);

  if (match) {
    const uValue = match[1];
    const pValue = match[2];
    return { u: uValue, p: pValue };
  } else {
    return null;
  }
}

export default StaticLogin;
