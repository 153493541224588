import React from 'react';
import { Button, Card } from 'react-bootstrap';
import Carousel from 'react-elastic-carousel';
import ReactPlayer from 'react-player';
import { BrowserRouter } from 'react-router-dom';
import './styles/DocumentsReports.css';

const DocumentsReports = ({ DocuReport }) => {
  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2 },
    { width: 768, itemsToShow: 2 },
    { width: 1200, itemsToShow: 2 },
  ];

  const renderCard = (card, index) => {
    return (
      <Card key={index}>
        <div className='video-wrapper'>
          <ReactPlayer
            width='100%'
            height='100%'
            url={card.embed_url}
            controls
            muted
            config={{
              youtube: {
                playerVars: { showinfo: 1 },
              },
            }}
          />
        </div>
        {/* <Card.Img variant="top" src={card.embed_url} /> */}
        <Card.Body className='text-dark text-start'>
          <Card.Title>{card.title}</Card.Title>
          <Card.Text>{card.desc} </Card.Text>
          <BrowserRouter>
            <Button
              href={card.doc_url}
              target='blank'
              variant='btn btn-outline-secondary rounded-pill'
            >
              {card.nes}
            </Button>
          </BrowserRouter>
        </Card.Body>
      </Card>
    );
  };

  return (
    <Carousel
      itemPadding={[0, 8]}
      itemsToScroll={1}
      showArrows={false}
      breakPoints={breakPoints}
      className='blacknav'
    >
      {DocuReport?.map(renderCard)}
    </Carousel>
  );
};

export default DocumentsReports;
