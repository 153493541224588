import React from 'react';

import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ExamPrepDocumentsAndReports = () => {
  const linkStyle = {
    color: 'green',
    cursor: 'pointer',
    textDecoration: 'none',
    fontSize: '25px',
    fontWeight: '700',
  };

  return (
    <div>
      <h2>Exam Prep Documents and Reports</h2>
      <p>
        Downloadable reports and documents related to Board Exam Prep allow
        tracking down individual students performance as well as many other
        important details related to the programme.
      </p>
      <br />
      <a
        href='https://drive.google.com/drive/folders/10ZgmPSE8R5cuegpyrIAnrYZW3IBheb_6?usp=sharing'
        download='raw_data_student_wise.xlsx'
        style={linkStyle}
        target='_blank'
        rel='noreferrer'
      >
        Excel of raw data (school wise) <FontAwesomeIcon icon={faDownload} />
      </a>
      <br />
      <a
        href='https://drive.google.com/drive/folders/1JjzP8KsM5Fq1F0zaS3fi67rVIqfFucxr?usp=sharing'
        download='sample_paper.pdf'
        style={linkStyle}
        target='_blank'
        rel='noreferrer'
      >
        Sample Paper <FontAwesomeIcon icon={faDownload} />
      </a>
      <br />
      <a
        href='https://drive.google.com/drive/folders/1kbmrgkT3UZFzHAhw7RoA23UFICqj05Yz'
        download='sample_paper.pdf'
        style={linkStyle}
        target='_blank'
        rel='noreferrer'
      >
        PM SHRI <FontAwesomeIcon icon={faDownload} />
      </a>
      <br />
    </div>
  );
};

export default ExamPrepDocumentsAndReports;
