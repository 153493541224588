import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import * as _ from 'lodash';
import { useEffect, useState } from 'react';
import { Redirect } from 'react-router';
import { api_url } from '../resources/url';
import Header from './header-signedin';
import LeftMenu from './left-menu';
import StudentHeader from './StudentHeader';

function Resource() {
  const [classId, setClassId] = useState('');
  const [searchStudentname, setSearchStudentname] = useState('');
  const [students, setStudents] = useState([]);
  const [oriStudents, setOriStudents] = useState([]);

  useEffect(() => {
    let udise_id = localStorage.getItem('udise_id');

    let Params = `/user/school/users?${'schoolId=' + udise_id}`;
    let cls = classId;
    if (!cls) {
      cls = '5';
    }
    if (cls) {
      Params = Params + `${'&class=' + cls}`;
    }

    axios.get(api_url + Params).then(
      (res) => {
        if (res) {
          setStudents(res.data.data);
          setOriStudents(res.data.data);
        }
      },
      (error) => {}
    );
  }, [classId]);

  const showIndividuial = (id) => {
    window.location.href = `performance-student-individual?sid=` + id;
  };
  useEffect(() => {
    console.log('called Search', searchStudentname);
    console.log(oriStudents);
    if (searchStudentname.length > 0) {
      console.log(oriStudents);
      console.log(searchStudentname);
      const filteredData = _.filter(oriStudents, (item) => {
        const regex = new RegExp(searchStudentname, 'i');
        return regex.test(item.name?.trim());
      });
      if (filteredData) {
        setStudents(filteredData);
      }
    } else {
      setStudents(oriStudents);
    }
  }, [oriStudents, searchStudentname]);

  const HeaderForm = () => {
    return (
      <>
        <div
          className='row'
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div className='col md-4'>
            <select
              className='custom-select custom-select-lg mb-3'
              style={{
                width: '100%',
                padding: 8,
                borderRadius: 5,
                marginTop: 15,
              }}
              onChange={(e) => setClassId(e.target.value)}
              value={classId}
            >
              <option value='5'>Class 5</option>
              <option value='6'>Class 6</option>
              <option value='7'>Class 7</option>
              <option value='8'>Class 8</option>
              <option value='9'>Class 9</option>
              <option value='10'>Class 10</option>
            </select>
          </div>
          <div className='col md-4'>
            <div className='input-group'>
              <input
                id='txtSearch'
                type='text'
                autoFocus
                value={searchStudentname}
                onChange={(e) => {
                  setSearchStudentname(e.target.value);
                }}
                className='form-control serachtb'
                placeholder='Search by student name'
              />
              <div className='input-group-append'>
                <span className='btn btnSearch' type='button'>
                  <FontAwesomeIcon icon={faSearch} />
                </span>
              </div>
            </div>
          </div>
          <div className='col md-4 vl' style={{ width: '100%' }}>
            <span
              className='boldspan'
              style={{
                display: 'flex',
                justifyContent: 'left',
                alignItems: 'left',
                paddingLeft: 10,
              }}
            >
              Score Reference Table
            </span>
            <ul>
              <li>
                <span style={{ color: '#f1a253' }}>0-40% Aspiring</span>
              </li>
              <li>
                <span style={{ color: '#ef1b1b' }}>41-80% Scholar</span>
              </li>
              <li>
                <span style={{ color: '#82a1e0' }}>81-100% Master</span>
              </li>
            </ul>
          </div>
        </div>

        <div
          className='row'
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div className='col md-4'>
            <span id='txtSearchDesc'> </span>
          </div>
        </div>
      </>
    );
  };

  if (!localStorage.getItem('is_done')) {
    return <Redirect to={'/login'} />;
  }

  return (
    <div>
      <Header />
      <div className='bg-grey'>
        <div className='container-fluid p-10'>
          <div className='row' style={{ padding: 20 }}>
            <div className='col-2 left-menu'>
              <br />
              <LeftMenu />
            </div>
            <div className='col-9'>
              <StudentHeader
                level={'List Of Student'}
                desc='Click on View link for individual student to see detailed performance report.'
              />
              <div className='right-menu'>
                <HeaderForm />

                {students && students.length > 0 ? (
                  <div className='row panel' id='tblStud'>
                    <table>
                      <tr>
                        <th>NAME</th>
                        <th>CLASS</th>
                        {/* <th>PHONE NUMBER</th> */}
                        <th>DOB</th>
                        <th>School</th>
                        <th>PERFORMANCE</th>
                      </tr>

                      {_.map(students, (item, index) => (
                        <tr>
                          <td>{item?.name}</td>
                          <td>Class {item?.class}</td>
                          {/* <td>{item?.school_name}</td> */}
                          <td>{item?.dob}</td>
                          <td>{item?.school_name}</td>
                          <td
                            style={{ color: '#ef1b1b', cursor: 'pointer' }}
                            onClick={() => showIndividuial(item.user_id)}
                          >
                            View
                          </td>
                        </tr>
                      ))}
                    </table>
                  </div>
                ) : (
                  <div className='row panel' id='tblStud'>
                    <table>
                      <tr>
                        <th>NAME</th>
                        <th>CLASS</th>
                        <th>DOB</th>
                        <th>School</th>
                        <th>PERFORMANCE</th>
                      </tr>

                      <tr>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                      </tr>
                    </table>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Resource;
