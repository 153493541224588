import React from 'react';
import Chart from 'react-google-charts';
import './styles/PieChart.css';

const pieOptionsEnglish = {
  pieHole: 0.5,
  is3D: false,
  alignItems: 'center',
  title: 'English',
  backgroundColor: 'white',
  legend: { position: 'bottom', alignment: 'start' },
  titleTextStyle: {
    fontSize: '30',
    color: '#000',
    textAlign: 'center',
  },
  colors: ['#0390F2', '#0073C2', '#004677'],
};

const pieOptionsSci = {
  title: 'Science',
  pieHole: 0.5,
  is3D: false,
  display: 'block',
  legend: { position: 'bottom' },
  textAlign: 'start',
  titleTextStyle: {
    fontSize: '30',
    color: '#000',
    textAlign: 'center',
  },
  colors: ['#FFC371', '#FFA934', '#F38D00'],
};

const pieOptionsMaths = {
  title: 'Maths',
  pieHole: 0.5,
  display: 'block',
  marginBottom: '10px',
  legend: { position: 'bottom', maxLines: 1 },
  fontSize: '12',
  fontWeight: 'bold',
  titleTextStyle: {
    fontSize: '30',
    color: '#000',
    textAlign: 'center',
  },
  colors: ['#F47979', '#A82A2A', '#760C0C'],
};

const PieChart = ({ pieDataEnglish, pieDataMaths, pieDataScience }) => {
  const AspiringEn = pieDataEnglish?.filter(
    (item) => item.level === 'Aspiring'
  )[0]?.pct;

  const ScholarEn = pieDataEnglish?.filter(
    (item) => item.level === 'Scholar'
  )[0]?.pct;

  const MasterEn = pieDataEnglish?.filter((item) => item.level === 'Master')[0]
    ?.pct;

  const AspiringMath = pieDataMaths?.filter(
    (item) => item.level === 'Aspiring'
  )[0]?.pct;

  const ScholarMath = pieDataMaths?.filter(
    (item) => item.level === 'Scholar'
  )[0]?.pct;

  const MasterMath = pieDataMaths?.filter((item) => item.level === 'Master')[0]
    ?.pct;

  const AspiringSc = pieDataScience?.filter(
    (item) => item.level === 'Aspiring'
  )[0]?.pct;

  const ScholarSc = pieDataScience?.filter(
    (item) => item.level === 'Scholar'
  )[0]?.pct;

  const MasterSc = pieDataScience?.filter((item) => item.level === 'Master')[0]
    ?.pct;

  const pieDataEn = [
    ['English', 'Performance'],
    ['Aspiring', AspiringEn],
    ['Scholar', ScholarEn],
    ['Master', MasterEn],
  ];

  const pieDataSci = [
    ['Subject', 'Performance'],
    ['Aspiring', AspiringSc],
    ['Scholar', ScholarSc],
    ['Master', MasterSc],
  ];

  const pieDataMath = [
    ['Subject', 'Performance'],
    ['Aspiring', AspiringMath],
    ['Scholar', ScholarMath],
    ['Master', MasterMath],
  ];

  return (
    <div>
      <div className='scrollchart'>
        <Chart
          width={'340px'}
          height={'340px'}
          chartType='PieChart'
          loader={<div>Loading Chart</div>}
          options={pieOptionsEnglish}
          data={pieDataEn}
          rootProps={{ 'data-testid': '1' }}
        />

        <Chart
          width={'340px'}
          height={'340px'}
          chartType='PieChart'
          loader={<div>Loading Chart</div>}
          options={pieOptionsMaths}
          data={pieDataMath}
          rootProps={{ 'data-testid': '2' }}
        />

        <Chart
          width={'340px'}
          height={'340px'}
          chartType='PieChart'
          loader={<div>Loading Chart</div>}
          data={pieDataSci}
          options={pieOptionsSci}
          rootProps={{ 'data-testid': '3' }}
        />
      </div>
    </div>
  );
};
export default PieChart;
