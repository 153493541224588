import React from 'react';
import BarChartNew from './BarChart';

const barChartColors = [
  ['#c0e3fb', '#0390f2', '#004677'],
  ['#fcdcdc', '#f47979', '#750b0b'],
  ['#ffddaf', '#ffa834', '#f38d00'],
];
const ExamPrepBaselineEndLineComparison = ({ data, title, description }) => {
  return (
    <div>
      <h2>{title}</h2>
      <p>{description}</p>
      <div className='endlineComparison' style={{ marginTop: '-70px' }}>
        <div className='chartCon'>
          {data.length > 0
            ? data.map((item, index) => {
                return (
                  <BarChartNew
                    data={processBarChartData(item)}
                    name={item.subject}
                    colors={barChartColors[index]}
                    key={`${Math.round(Math.random() * 1000000)}`}
                  />
                );
              })
            : ''}
        </div>
      </div>
    </div>
  );
};

export default ExamPrepBaselineEndLineComparison;

const processBarChartData = (data) => {
  const baselineTotal =
    Number(data.baseline.aspiring) +
    Number(data.baseline.scholar) +
    Number(data.baseline.master);
  const endlineTotal =
    Number(data.endline.aspiring) +
    Number(data.endline.scholar) +
    Number(data.endline.master);

  const result = Object.keys(data.baseline).reduce((acc, key) => {
    const range = {
      aspiring: '0-40',
      scholar: '41-80',
      master: '81-100',
    };

    const baselineValue = Number(data.baseline[key]);
    const endlineValue = Number(data.endline[key]);
    const obj = {
      name: range[key],
      Baseline: Math.ceil((baselineValue / baselineTotal) * 100),
      End_line:
        endlineTotal !== 0 ? Math.ceil((endlineValue / endlineTotal) * 100) : 0,
    };

    acc.push(obj);
    return acc;
  }, []);

  return result;
};
