import {
  faBook,
  faChalkboardTeacher,
  faChartLine,
  faPencilAlt,
  faThLarge,
  faUserGraduate,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import logo from './../../assets/NeeveLogo.svg';
import './../styles/Sidebar.css';

const TrainingSideBar = () => {
  const token = localStorage.getItem('m_auth_token');
  const udise_id = localStorage.getItem('user_id');
  const query = `?udise=${udise_id}&auth_token=${token}`;
  // eslint-disable-next-line no-unused-vars
  const [activeNavBar, setActiveNavBar] = useState('');

  useEffect(() => {
    const active = localStorage.getItem('navActive');
    setActiveNavBar(active);
  }, []);

  return (
    <div className='sidebar'>
      <img
        src={logo}
        alt=''
        style={{ marginTop: '40px', marginBottom: '40px', height: '150px' }}
      />
      <NavLink to={'/'} className='state__main__bar'>
        <FontAwesomeIcon icon={faThLarge} color='#fff' />
        <div className='state__main__bar__title'>{`School Homepage`}</div>
      </NavLink>
      <NavLink
        to={'/training-home'}
        className='main__bar__nav'
        activeClassName='main-link-active'
      >
        <FontAwesomeIcon icon={faPencilAlt} color='#111' />
        <div className='main__title'>{`Training home`}</div>
      </NavLink>
      <NavLink
        to={'/trending-courses'}
        className='main__bar__nav'
        activeClassName='main-link-active'
      >
        <FontAwesomeIcon icon={faChartLine} color='#111' />
        <div className='main__title'>{`Trending course`}</div>
      </NavLink>
      <NavLink
        to={'/ongoing-training'}
        className='main__bar__nav'
        activeClassName='main-link-active'
      >
        <FontAwesomeIcon icon={faUserGraduate} color='#111' />
        <div className='main__title'>{`Ongoing Training`}</div>
      </NavLink>
      <NavLink
        to={'/resource/student-readiness'}
        className='main__bar__nav'
        activeClassName='main-link-active'
      >
        <FontAwesomeIcon icon={faBook} color='#111' />
        <div className='main__title'>{`Go to Learning`}</div>
      </NavLink>
      <a
        href={`https://monitoring.neeve.io/${query}`}
        role='button'
        className='main__bar__nav'
        data-bs-toggle='button'
        target='_blank'
        rel='noreferrer'
      >
        <FontAwesomeIcon icon={faChalkboardTeacher} color='#111' />
        <div className='main__title'>Go to Monitoring</div>
      </a>
    </div>
  );
};

export default TrainingSideBar;
