/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
// eslint-disable-next-line no-unused-vars
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { getDistDashboardData } from '../services/api';
import EducationSikkim from './EducationSikkim';
import HeadingWhite from './HeadingWhite';
import LoaderSpinner from './LoaderSpinner';
import { exportPDF } from './pdfDownloader';
import PerformanceSchoolTab from './PerformanceSchoolTab';
import './styles/DistrictPerformance.css';

function DistrictPerformance() {
  const { state, dist } = useParams();
  const history = useHistory();
  const [distData, setDistData] = useState();
  const [newDist, setNewDist] = useState(dist);

  useEffect(() => {
    getDistDashboardData(state, newDist).then((res) => {
      history.push(newDist);
      setDistData(res.data.data);
    });
  }, [newDist, state]);

  return (
    <>
      <div className='' id='districtPerformance'>
        {distData && (
          <div className=''>
            <div className='' id={'districtPerformance'}>
              <div className='row'>
                <div className=''>
                  <div className=''>
                    <div className='col-12'>
                      <div>
                        <h2 className='text-center text-indigo fw-bolder pt-3 '>
                          Supplementary Learning - District Level Performance
                        </h2>
                      </div>
                      <div className=' d-flex  justify-content-center pt-0 '>
                        <div className='d-inline-flex  w-90 mr-auto ml-auto flex-column   '>
                          <p className='text-center pt-0'>
                            This dashboard captures district level performance
                            of students. Select District in the panel below to
                            view its performance. Select Overall to see
                            performance or School list to see list of schools
                            and performance in a district.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <button
                    className='dropdownCus'
                    style={{ width: '250px', height: '42px', margin: '10px' }}
                    onClick={() =>
                      exportPDF(
                        'districtPerformance',
                        'DistrictPerformance Analysis'
                      )
                    }
                    id='downloadButton'
                  >
                    Download Report
                  </button>

                  <div className='row'>
                    <div className='col-12 '>
                      <div className='sky-curve-both-sm'>
                        <div className=' mb-5 text-center '>
                          <div className='pad-top text-white'>
                            <HeadingWhite
                              className='text-white'
                              text='Real-time CCE Report - For Districts'
                            />
                          </div>
                          <div className='d-flex justify-content-center  '>
                            <div className=' w-95  '>
                              <div className='row'>
                                <div className='col-12'>
                                  <EducationSikkim
                                    setDistData={setDistData}
                                    state={state}
                                    dist={newDist}
                                    setNewDist={setNewDist}
                                    summery={distData.district_summary[0]}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className=' mb-5'>
                          <div className=' mb-5 text-center '>
                            <div className='d-flex justify-content-center  '>
                              <div className=' w-95  '>
                                <PerformanceSchoolTab
                                  state={state}
                                  dist={newDist}
                                  slData={distData.district_schools_details}
                                  opData={distData.district_cce_report}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {!distData && <LoaderSpinner />}
      </div>
    </>
  );
}

export default DistrictPerformance;
